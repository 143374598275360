import * as Yup from 'yup';

// UTILS
import {
	limparMascara,
	calcularIdade,
	unformatDate,
	validaCpf,
} from 'utils/genericFunctions';
import { validateName, validateEmail } from 'utils/yupValidations';

export interface IFormCadastroRg {
	// Principall
	argumento: string;
	numeroRg: string;
	pidt: string;
	nome: string;
	nomeSocial: string;
	nomePai: string;
	nomeMae: string;
	sexo: string;
	dataNascimento: string;
	codigoNaturalidade: string;
	municipioNaturalidade: string;
	multifiliacao: boolean;
	filiacaoTres: string;
	filiacaoQuatro: string;
	filiacaoCinco: string;
	filiacaoSeis: string;

	// Documento apresentado
	dbas: string;
	comarca: string;
	cartorio: string;
	cartorioCertidao?: string;
	livro: string;
	folha: string;
	numero: string;
	dataDocumento: string;
	naturalidadeEstrangeira: boolean;
	pMinLei1: string;
	pMinLei2: string;

	// Dados adicionais - Cidadão
	estadoCivil: string;
	cutis: string;
	cabelos: string;
	olhos: string;
	altura: string;
	grauInstrucao: string;
	profissao: string;
	cepResidencia: string;
	enderecoResidencia: string;
	numeroResidencia: string;
	complementoResidencia: string;
	bairroResidencia: string;
	codigoMunicipioResidencia: string;
	descMunicipioResidencia: string;
	ufResidencia: string;

	// Dados adicionais - Trabalho
	cepTrabalho: string;
	enderecoTrabalho: string;
	numeroTrabalho: string;
	complementoTrabalho: string;
	bairroTrabalho: string;
	codigoMunicipioTrabalho: string;
	descMunicipioTrabalho: string;

	// Dados adicionais - Contato
	solicitacao: string;
	foneResidencia: string;
	foneComercial: string;
	celular: string;
	operadora: string;
	email: string;
	naoPossuiEmail: boolean;

	// Informações adicionais
	identificadoAssina: boolean | string;
	motivoFaltaAssinatura: string;
	carimboMaior65: boolean;
	tipoSanguineo: string;
	cid1: string;
	cid2: string;
	cid3: string;
	cid4: string;
	dataValidade?: string;

	// Dados adicionais - RG
	inscricaoCpf?: string | boolean;
	naoPossuiCpf?: boolean;
	cpf: string;
	pis: string;
	pasep: string;
	dni: string | null;
	tituloEleitor: string;
	ctpsNum: string;
	ctpsSerie: string;
	ctpsSerieUf: string;
	certidaoMilitar: string;
	cnh: string;
	categoriaCnh: string;
	doador: boolean;
	nascSaude: string;
	identProf1: string;
	orgaoEmissor1: string;
	identProf2: string;
	orgaoEmissor2: string;
	identProf3: string;
	orgaoEmissor3: string;

	// Entrega RG
	entrega: string | number;
	qtdKitFamilia: string | number;
	data: string;
	hora: string;
	sedex: string;

	// Estatísticas SGU
	eventoEstatisticasSGU?: {
		token: string;
		idCidadao: string;
		descricaoSistemOrigem: string;
		tipoDocumento: string;
		numeroDocumento: string;
		codigoLocalidade: number;
		idOrgao: number;
		cpf: string;
		descricaoServico: string;
		flagConfronto: string;
		descricaoDedosEnviados: string;
		idMotivo: number;
		observacao: string;
		numeroVia: number;
		numeroProcesso: string;
		totalizador: string;
	};
}

export const initialValues: IFormCadastroRg = {
	// Principal
	argumento: '',
	numeroRg: '',
	pidt: '',
	nome: '',
	nomeSocial: '',
	nomePai: '',
	nomeMae: '',
	dataNascimento: '',
	codigoNaturalidade: '',
	municipioNaturalidade: '',
	sexo: '',
	multifiliacao: false,
	filiacaoTres: '',
	filiacaoQuatro: '',
	filiacaoCinco: '',
	filiacaoSeis: '',

	// Documento apresentado
	// dbas: 'CC',
	// comarca: 'SAO PAULO SP',
	// cartorio: 'SAO PAULO',
	// livro: 'B020',
	// folha: '0122',
	// numero: '000170',
	// dataDocumento: '01/01/2022',
	// naturalidadeEstrangeira: false,
	// pMinLei1: '',
	// pMinLei2: '',

	dbas: '',
	comarca: '',
	cartorio: '',
	livro: '',
	folha: '',
	numero: '',
	dataDocumento: '',
	naturalidadeEstrangeira: false,
	pMinLei1: '',
	pMinLei2: '',

	// Dados adicionais - Cidadão
	// estadoCivil: 'C',
	// cutis: 'B',
	// cabelos: 'P',
	// olhos: 'P',
	// altura: '1,50',
	// grauInstrucao: 'U',
	// profissao: '',
	// cepResidencia: '01528-020',
	// enderecoResidencia: 'RUA TENENTE AZEVEDO',
	// numeroResidencia: '00129',
	// complementoResidencia: '',
	// bairroResidencia: 'ACLIMAÇÃO',
	// codigoMunicipioResidencia: '27486-0',
	// descMunicipioResidencia: 'S.PAULO -SP',
	// ufResidencia: 'SP',

	estadoCivil: '',
	cutis: '',
	cabelos: '',
	olhos: '',
	altura: '',
	grauInstrucao: '',
	profissao: '',
	cepResidencia: '',
	enderecoResidencia: '',
	numeroResidencia: '',
	complementoResidencia: '',
	bairroResidencia: '',
	codigoMunicipioResidencia: '',
	descMunicipioResidencia: '',
	ufResidencia: '',

	// Dados adicionais - Trabalho
	cepTrabalho: '',
	enderecoTrabalho: '',
	numeroTrabalho: '',
	complementoTrabalho: '',
	bairroTrabalho: '',
	codigoMunicipioTrabalho: '',
	descMunicipioTrabalho: '',

	// Dados adicionais - Contato
	solicitacao: '1',
	foneResidencia: '',
	foneComercial: '',
	celular: '',
	operadora: '',
	naoPossuiEmail: false,
	email: '',
	// email: 'EMAIL@EMAIL.COM',

	// Informações adicionais
	identificadoAssina: true,
	motivoFaltaAssinatura: '1',
	carimboMaior65: false,
	tipoSanguineo: '',
	cid1: '',
	cid2: '',
	cid3: '',
	cid4: '',
	dataValidade: '',

	// Dados adicionais - RG
	inscricaoCpf: '',
	naoPossuiCpf: false,
	cpf: '',
	pis: '',
	pasep: '',
	dni: '10977',
	tituloEleitor: '',
	ctpsNum: '',
	ctpsSerie: '',
	ctpsSerieUf: '',
	certidaoMilitar: '',
	cnh: '',
	categoriaCnh: '',
	doador: false,
	nascSaude: '',
	identProf1: '',
	orgaoEmissor1: '',
	identProf2: '',
	orgaoEmissor2: '',
	identProf3: '',
	orgaoEmissor3: '',

	// Entrega RG
	entrega: '',
	qtdKitFamilia: '',
	data: '',
	hora: '',
	sedex: '',
};

export const schema = Yup.object<IFormCadastroRg>().shape(
	{
		// Principal
		nome: Yup.string()
			.required('Campo Obrigatório: Nome.')
			.max(100, 'Campo Nome: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			}),
		// .when(
		// 	['nomeSocial', 'nomeMae', 'nomePai'],
		// 	(
		// 		nomeSocial: string,
		// 		nomeMae: string,
		// 		nomePai: string,
		// 		schemaTest: any,
		// 	) => {
		// 		return schemaTest.test({
		// 			test: (nome: string) =>
		// 				!(
		// 					nome !== '' &&
		// 					nome === nomeMae &&
		// 					nome === nomeSocial &&
		// 					nome === nomePai
		// 				),
		// 			message:
		// 				'O Nome Social, Nome do Pai e Nome da Mãe não podem ser idêntico ao Nome do Registro Civil.',
		// 		});
		// 	},
		// ),
		nomeSocial: Yup.string()
			.max(100, 'Campo Nome Social: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome Social: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			})
			.when(['nome'], (nome: string, schemaTest: any) => {
				return schemaTest.test({
					test: (nomeSocial: string) =>
						!(nome !== '' && nomeSocial !== '' && nome === nomeSocial),
					message:
						'O Nome Social não pode ser idêntico ao Nome do Registro Civil.',
				});
			})
			.nullable(),
		nomePai: Yup.string()
			.required('Campo Obrigatório: Nome do Pai.')
			.max(100, 'Campo Nome do Pai: Tamanho máximo de 60 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome do Pai: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			}),
		// .when(['nome'], (nome: string, schemaTest: any) => {
		// 	return schemaTest.test({
		// 		test: (nomePai: string) =>
		// 			!(nome !== '' && nomePai !== '' && nome === nomePai),
		// 		message:
		// 			'O Nome do Pai não pode ser idêntico ao Nome do Registro Civil.',
		// 	});
		// }),
		nomeMae: Yup.string()
			.required('Campo Obrigatório: Nome da Mãe.')
			.max(100, 'Campo Nome da Mãe: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome da Mãe: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			}),
		// .when(['nome'], (nome: string, schemaTest: any) => {
		// 	return schemaTest.test({
		// 		test: (nomeMae: string) =>
		// 			!(nome !== '' && nomeMae !== '' && nome === nomeMae),
		// 		message:
		// 			'O Nome da Mãe não pode ser idêntico ao Nome do Registro Civil.',
		// 	});
		// })
		// .when(['nomePai'], (nomePai: string, schemaTest: any) => {
		// 	return schemaTest.test({
		// 		test: (nomeMae: string) =>
		// 			!(
		// 				nomePai !== '' &&
		// 				nomeMae !== '' &&
		// 				nomeMae.toLocaleLowerCase() !== 'nc' &&
		// 				nomePai.toLocaleLowerCase() !== 'nc' &&
		// 				nomePai === nomeMae
		// 			),
		// 		mess1age: 'O Nome do Pai não pode ser idêntico ao Nome da Mãe.',
		// 	});
		// }),
		filiacaoTres: Yup.string()
			.max(100, 'Campo Filiação Três: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome Social: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			})
			.when(['multifiliacao'], {
				is: multifiliacao => multifiliacao,
				then: Yup.string().required('Campo Obrigatório: Filiação Três.'),
			})
			.nullable(),
		filiacaoQuatro: Yup.string()
			.max(100, 'Campo Filiação Quatro: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome Social: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			})
			.nullable(),
		filiacaoCinco: Yup.string()
			.max(100, 'Campo Filiação Cinco: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome Social: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			})
			.nullable(),
		filiacaoSeis: Yup.string()
			.max(100, 'Campo Filiação Seis: Tamanho máximo de 100 caracteres.')
			.test({
				name: 'validateName',
				exclusive: true,
				message: 'Campo Nome Social: Números e símbolos não são permitidos.',
				test: value => validateName(value),
			})
			.nullable(),
		sexo: Yup.string().required('Campo Obrigatório: Sexo.'),
		dataNascimento: Yup.string().required(
			'Campo Obrigatório: Data de Nascimento.',
		),
		codigoNaturalidade: Yup.string().required(
			'Campo Obrigatório: Código do Município.',
		),
		municipioNaturalidade: Yup.string().required(
			'Campo Obrigatório: Nome do Município.',
		),
		cpf: Yup.string().test({
			name: 'cpfValidation',
			exclusive: true,
			message: 'CPF digitado inválido.',
			test: value => validaCpf(value),
		}),

		// Documento apresentado
		dbas: Yup.string().required('Campo Obrigatório: Tipo de documento.'),
		dni: Yup.string().required('Campo Obrigatório: Tipo de Docto. a Emitir'),
		comarca: Yup.string()
			.when('dbas', {
				is: dbas => dbas === 'CN' || dbas === 'CC',
				then: Yup.string().required('Campo Obrigatório: Comarca.'),
			})
			.max(25, 'Campo Comarca: Tamanho máximo de 25 caracteres.')
			.nullable(),
		cartorio: Yup.string()
			.when('dbas', {
				is: dbas => dbas === 'CN' || dbas === 'CC',
				then: Yup.string().required('Campo Obrigatório: Cartório.'),
			})
			.max(25, 'Campo Cartório: Tamanho máximo de 25 caracteres.')
			.nullable(),
		livro: Yup.string()
			.when('dbas', {
				is: dbas => dbas === 'CN' || dbas === 'CC',
				then: Yup.string().required('Campo Obrigatório: Livro.'),
			})
			.max(4, 'Campo Livro: Tamanho máximo de 4 caracteres.')
			.nullable(),
		folha: Yup.string()
			.when('dbas', {
				is: dbas => dbas === 'CN' || dbas === 'CC',
				then: Yup.string().required('Campo Obrigatório: Folha.'),
			})
			.max(4, 'Campo Folha: Tamanho máximo de 4 caracteres.')
			.nullable(),
		numero: Yup.string()
			.when('dbas', {
				is: dbas => dbas === 'CN' || dbas === 'CC',
				then: Yup.string().required('Campo Obrigatório: Número (Documento).'),
			})
			.max(6, 'Campo Número: Tamanho máximo de 6 caracteres.')
			.nullable(),
		dataDocto: Yup.string().when(['dataDocumento', 'dataNascimento'], {
			is: (dataDocumento, dataNascimento) => {
				return !(
					new Date(unformatDate(dataDocumento)).getTime() >=
					new Date(unformatDate(dataNascimento)).getTime()
				);
			},
			then: Yup.string().required(
				'Campo Inválido: Data do documento deve ser superior a data de nascimento.',
			),
		}),
		pMinLei: Yup.string()
			.when(['dbas', 'pMinLei1', 'pMinLei2'], {
				is: (dbas, pMinLei1, pMinLei2) =>
					dbas !== 'CN' &&
					dbas !== 'CC' &&
					(pMinLei1 === '' || pMinLei2 === ''),
				then: Yup.string().required('Campo Obrigatório: Port Min-Lei.'),
			})
			.nullable(),

		// Dados adicionais - Cidadão
		estadoCivil: Yup.string().required('Campo Obrigatório: Estado Cívil.'),
		cutis: Yup.string().required('Campo Obrigatório: Cutis.'),
		cabelos: Yup.string().required('Campo Obrigatório: Cabelos.'),
		olhos: Yup.string().required('Campo Obrigatório: Olhos.'),
		altura: Yup.string().required('Campo Obrigatório: Altura.'),
		grauInstrucao: Yup.string().required(
			'Campo Obrigatório: Grau de instrução.',
		),
		motivoFaltaAssinatura: Yup.string().when('grauInstrucao', {
			is: grauInstrucao => grauInstrucao === 'N',
			then: Yup.string().required(
				'Campo Obrigatório: Motivo da falta de assinatura',
			),
		}),
		dataValidade: Yup.string().when('motivoFaltaAssinatura', {
			is: motivoFaltaAssinatura => motivoFaltaAssinatura === '3',
			then: Yup.string().required('Campo Obrigatório: Data de validade'),
		}),
		profissao: Yup.string()
			.max(12, 'Campo Profissão: Tamanho máximo de 12 caracteres.')
			.nullable(),
		cepResidencia: Yup.string()
			.test({
				name: 'cepValido',
				exclusive: true,
				message: 'Campo Inválido: CEP (Cidadão).',
				test: value => {
					const cep = limparMascara(value);

					if (cep.length < 8) return false;

					return true;
				},
			})
			.required('Campo Obrigatório: CEP (Cidadão).'),
		enderecoResidencia: Yup.string()
			.required('Campo Obrigatório: Logradouro (Cidadão).')
			.max(22, 'Campo Logradouro: Tamanho máximo de 22 caracteres (Cidadão).'),
		numeroResidencia: Yup.string().required(
			'Campo Obrigatório: Número (Cidadão).',
		),
		complementoResidencia: Yup.string()
			.max(8, 'Campo Complemento: Tamanho máximo de 6 caracteres (Cidadão).')
			.nullable(),
		bairroResidencia: Yup.string()
			.max(14, 'Campo Bairro: Tamanho máximo de 14 caracteres (Cidadão).')
			.nullable(),
		codigoMunicipioResidencia: Yup.string().required(
			'Campo Obrigatório: Código do Município (Cidadão).',
		),
		descMunicipioResidencia: Yup.string().required(
			'Campo Obrigatório: Nome do Município (Cidadão).',
		),

		// Dados adicionais - Trabalho
		cepTrabalho: Yup.string()
			.when(
				['entrega', 'bairroTrabalho', 'numeroTrabalho', 'enderecoTrabalho'],
				{
					is: (entrega, bairroTrabalho, numeroTrabalho, enderecoTrabalho) =>
						entrega === 'T' ||
						bairroTrabalho !== '' ||
						numeroTrabalho !== '' ||
						enderecoTrabalho !== '',
					then: Yup.string().required('Campo Obrigatório: CEP (Trabalho).'),
				},
			)
			.test({
				name: 'cepValido',
				exclusive: true,
				message: 'Campo Inválido: CEP (Trabalho).',
				test: value => {
					const cep = limparMascara(value);

					if (cep.length > 0 && cep.length < 8) return false;

					return true;
				},
			})
			.nullable(),
		enderecoTrabalho: Yup.string()
			.when(['entrega', 'bairroTrabalho'], {
				is: (entrega, bairroTrabalho) =>
					entrega === 'T' || bairroTrabalho !== '',
				then: Yup.string().required(
					'Campo Obrigatório: Logradouro (Trabalho).',
				),
			})
			.max(22, 'Campo Logradouro: Tamanho máximo de 22 caracteres (Trabalho).')
			.nullable(),
		numeroTrabalho: Yup.string()
			.when(['entrega', 'bairroTrabalho', 'enderecoTrabalho'], {
				is: (entrega, bairroTrabalho, enderecoTrabalho) =>
					entrega === 'T' || bairroTrabalho !== '' || enderecoTrabalho !== '',
				then: Yup.string().required('Campo Obrigatório: Número (Trabalho).'),
			})
			.max(22, 'Campo Logradouro: Tamanho máximo de 22 caracteres (Trabalho).')
			.nullable(),
		complementoTrabalho: Yup.string()
			.max(8, 'Campo Complemento: Tamanho máximo de 6 caracteres (Trabalho).')
			.nullable(),
		bairroTrabalho: Yup.string()
			.when(['entrega'], {
				is: entrega => entrega === 'T',
				then: Yup.string().required('Campo Obrigatório: Bairro (Trabalho).'),
			})
			.max(14, 'Campo Bairro: Tamanho máximo de 14 caracteres (Trabalho).')
			.nullable(),
		codigoMunicipioTrabalho: Yup.string()
			.when('entrega', {
				is: entrega => entrega === 'T',
				then: Yup.string().required(
					'Campo Obrigatório: Código do Município (Trabalho).',
				),
			})
			.nullable(),
		descMunicipioTrabalho: Yup.string()
			.when('entrega', {
				is: entrega => entrega === 'T',
				then: Yup.string().required(
					'Campo Obrigatório: Nome do Município (Trabalho).',
				),
			})
			.nullable(),
		// Dados adicionais - Contato
		email: Yup.string().when(
			['naoPossuiEmail'],
			(naoPossuiEmail: string, schemaTest: any) => {
				return schemaTest.test({
					test: (value: string) => {
						if (naoPossuiEmail === 'false') {
							if (value === '') {
								return true;
							}
							if (validateEmail(value) === false) {
								return false;
							}
							return true;
						}
						return true;
					},
					message: 'Campo E-mail: E-mail inválido.',
				});
			},
		),
		celular: Yup.string().when(['solicitacao'], {
			is: solicitacao => solicitacao === 'SMS' || solicitacao === '2',
			then: Yup.string().required(
				'Campo Obrigatório: Quando o tipo de aviso for SMS celular é obrigatório.',
			),
		}),
		naoPossuiEmail: Yup.string().when(
			['email'],
			(email: string, schemaTest: any) => {
				return schemaTest.test({
					test: (value: string) => {
						if (email === '' && value && value?.toString() === 'false') {
							return false;
						}
						return true;
					},
					message:
						'O e-mail não foi informado. Caso o cidadão realmente não saiba informar o e-mail, selecione o checkbox "Cidadão não possui e-mail".',
				});
			},
		),

		// Informações adicionais
		tipoSanguineo: Yup.string()
			.when('dataNascimento', {
				is: dataNascimento => calcularIdade(dataNascimento) < 5,
				then: Yup.string().test({
					name: 'maiorCincoAnos',
					exclusive: true,
					message:
						'Campo Tipo Sanguíneo: Permitido apenas para maiores de 5 anos.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		cid1: Yup.string()
			.test({
				name: 'hasUnderline',
				exclusive: true,
				message: 'Campo CID (1): Tamanho mínimo de 3 caracteres.',
				test: value => {
					if (
						value.replace(/_/g, '').length > 0 &&
						value.replace(/_/g, '').length < 3
					)
						return false;

					return true;
				},
			})
			.nullable(),
		cid2: Yup.string()
			.test({
				name: 'hasUnderline',
				exclusive: true,
				message: 'Campo CID (2): Tamanho mínimo de 3 caracteres.',
				test: value => {
					if (
						value.replace(/_/g, '').length > 0 &&
						value.replace(/_/g, '').length < 3
					)
						return false;

					return true;
				},
			})
			.nullable(),
		cid3: Yup.string()
			.test({
				name: 'hasUnderline',
				exclusive: true,
				message: 'Campo CID (3): Tamanho mínimo de 3 caracteres.',
				test: value => {
					if (
						value.replace(/_/g, '').length > 0 &&
						value.replace(/_/g, '').length < 3
					)
						return false;

					return true;
				},
			})
			.nullable(),
		cid4: Yup.string()
			.test({
				name: 'hasUnderline',
				exclusive: true,
				message: 'Campo CID (4): Tamanho mínimo de 3 caracteres.',
				test: value => {
					if (
						value.replace(/_/g, '').length > 0 &&
						value.replace(/_/g, '').length < 3
					)
						return false;

					return true;
				},
			})
			.nullable(),

		// Dados adicionais - RG
		pasep: Yup.string()
			.when('dataNascimento', {
				is: dataNascimento => calcularIdade(dataNascimento) < 14,
				then: Yup.string().test({
					name: 'maiorQuatorzeAnos',
					exclusive: true,
					message: 'Campo PASEP: Permitido apenas para maiores de 14 anos.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		tituloEleitor: Yup.string()
			.when('dataNascimento', {
				is: dataNascimento => calcularIdade(dataNascimento) < 16,
				then: Yup.string().test({
					name: 'maiorDezesseisAnos',
					exclusive: true,
					message:
						'Campo Título de Eleitor: Permitido apenas para maiores de 16 anos.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		ctps: Yup.string()
			.when(['ctpsNum', 'ctpsSerie', 'ctpsSerieUf', 'dataNascimento'], {
				is: (ctpsNum, ctpsSerie, ctpsSerieUf, dataNascimento) =>
					ctpsNum === '' &&
					(ctpsSerie !== '' || ctpsSerieUf !== '') &&
					calcularIdade(dataNascimento) >= 14,
				then: Yup.string().required('Campo Obrigatório: CTPS Número.'),
			})
			.when(['ctpsNum', 'ctpsSerie', 'ctpsSerieUf', 'dataNascimento'], {
				is: (ctpsNum, ctpsSerie, ctpsSerieUf, dataNascimento) =>
					ctpsSerie === '' &&
					(ctpsNum !== '' || ctpsSerieUf !== '') &&
					calcularIdade(dataNascimento) >= 14,
				then: Yup.string().required('Campo Obrigatório: CTPS Série.'),
			})
			.when(['ctpsNum', 'ctpsSerie', 'ctpsSerieUf', 'dataNascimento'], {
				is: (ctpsNum, ctpsSerie, ctpsSerieUf, dataNascimento) =>
					ctpsSerieUf === '' &&
					(ctpsNum !== '' || ctpsSerie !== '') &&
					calcularIdade(dataNascimento) >= 14,
				then: Yup.string().required('Campo Obrigatório: CTPS UF.'),
			})
			.when(['ctpsNum', 'ctpsSerie', 'ctpsSerieUf', 'dataNascimento'], {
				is: (ctpsNum, ctpsSerie, ctpsSerieUf, dataNascimento) =>
					((ctpsSerieUf && ctpsSerieUf !== '') ||
						(ctpsNum && ctpsNum !== '') ||
						(ctpsSerie && ctpsSerie !== '')) &&
					calcularIdade(dataNascimento) < 14,
				then: Yup.string().required(
					'Campos CTPS: Permitido apenas para maiores de 14 anos.',
				),
			}),
		certidaoMilitar: Yup.string()
			.when('dataNascimento', {
				is: dataNascimento => calcularIdade(dataNascimento) < 17,
				then: Yup.string().test({
					name: 'maiorDezesseteAnos',
					exclusive: true,
					message:
						'Campo Cert. Militar: Permitido apenas para maiores de 17 anos.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		cnh: Yup.string()
			.when('dataNascimento', {
				is: dataNascimento => calcularIdade(dataNascimento) < 18,
				then: Yup.string().test({
					name: 'maiorDezoitoAnos',
					exclusive: true,
					message:
						'Preencher CNH e Categoria de CNH somente para cidadão maior de 18 anos',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.when(['categoriaCnh', 'dataNascimento'], {
				is: (categoriaCnh, dataNascimento) =>
					categoriaCnh !== '' && calcularIdade(dataNascimento) >= 18,
				then: Yup.string().required('Campo Obrigatório: CNH'),
			})
			.nullable(),
		categoriaCnh: Yup.string()
			.when(['cnh', 'dataNascimento'], {
				is: (cnh, dataNascimento) =>
					calcularIdade(dataNascimento) < 18 && cnh === '',
				then: Yup.string().test({
					name: 'maiorDezoitoAnos',
					exclusive: true,
					message:
						'Preencher CNH e Categoria de CNH somente para cidadão maior de 18 anos',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.when(['cnh', 'dataNascimento'], {
				is: (cnh, dataNascimento) =>
					cnh !== '' && calcularIdade(dataNascimento) >= 18,
				then: Yup.string().required('Campo Obrigatório: Categoria CNH'),
			})
			.nullable(),
		identProf_menor: Yup.string().when(
			[
				'dataNascimento',
				'identProf1',
				'orgaoEmissor1',
				'identProf2',
				'orgaoEmissor2',
				'identProf3',
				'orgaoEmissor3',
			],
			{
				is: (
					dataNascimento,
					identProf1,
					orgaoEmissor1,
					identProf2,
					orgaoEmissor2,
					identProf3,
					orgaoEmissor3,
				) =>
					calcularIdade(dataNascimento) < 14 &&
					(identProf1 !== '' ||
						orgaoEmissor1 !== '' ||
						identProf2 !== '' ||
						orgaoEmissor2 !== '' ||
						identProf3 !== '' ||
						orgaoEmissor3 !== ''),
				then: Yup.string().required(
					'Campo Identidade Profissional: Permitido apenas para maiores de 14 anos.',
				),
			},
		),
		identProf_1: Yup.string()
			.when(
				[
					'identProf1',
					'orgaoEmissor1',
					'identProf2',
					'orgaoEmissor2',
					'identProf3',
					'orgaoEmissor3',
					'dataNascimento',
				],
				{
					is: (
						identProf1,
						orgaoEmissor1,
						identProf2,
						orgaoEmissor2,
						identProf3,
						orgaoEmissor3,
						dataNascimento,
					) =>
						identProf1 === '' &&
						orgaoEmissor1 === '' &&
						(identProf2 !== '' ||
							orgaoEmissor2 !== '' ||
							identProf3 !== '' ||
							orgaoEmissor3 !== '') &&
						calcularIdade(dataNascimento) > 14,
					then: Yup.string().required(
						'Preencher Número Ident. Profissional e Órgão na ocorrência 1.',
					),
				},
			)
			.when(['identProf1', 'orgaoEmissor1', 'dataNascimento'], {
				is: (identProf1, orgaoEmissor1, dataNascimento) =>
					((identProf1 === '' && orgaoEmissor1 !== '') ||
						(identProf1 !== '' && orgaoEmissor1 === '')) &&
					calcularIdade(dataNascimento) > 14,
				then: Yup.string().test({
					name: 'dados-id-prof-1',
					exclusive: true,
					message:
						'Necessário informar todos os campos referentes a Identidade Profissional 1.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		identProf_2: Yup.string()
			.when(
				[
					'identProf2',
					'orgaoEmissor2',
					'identProf3',
					'orgaoEmissor3',
					'dataNascimento',
				],
				{
					is: (
						identProf2,
						orgaoEmissor2,
						identProf3,
						orgaoEmissor3,
						dataNascimento,
					) =>
						identProf2 === '' &&
						orgaoEmissor2 === '' &&
						(identProf3 !== '' || orgaoEmissor3 !== '') &&
						calcularIdade(dataNascimento) > 14,
					then: Yup.string().required(
						'Preencher Número Ident. Profissional e Órgão na ocorrência 2.',
					),
				},
			)
			.when(['identProf2', 'orgaoEmissor2', 'dataNascimento'], {
				is: (identProf2, orgaoEmissor2, dataNascimento) =>
					((identProf2 === '' && orgaoEmissor2 !== '') ||
						(identProf2 !== '' && orgaoEmissor2 === '')) &&
					calcularIdade(dataNascimento) > 14,
				then: Yup.string().test({
					name: 'dados-id-prof-2',
					exclusive: true,
					message:
						'Necessário informar todos os campos referentes a Identidade Profissional 2.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),
		identProf_3: Yup.string()
			.when(['identProf3', 'orgaoEmissor3', 'dataNascimento'], {
				is: (identProf3, orgaoEmissor3, dataNascimento) =>
					((identProf3 === '' && orgaoEmissor3 !== '') ||
						(identProf3 !== '' && orgaoEmissor3 === '')) &&
					calcularIdade(dataNascimento) > 14,
				then: Yup.string().test({
					name: 'dados-id-prof-3',
					exclusive: true,
					message:
						'Necessário informar todos os campos referentes a Identidade Profissional 3.',
					test: value => {
						if (value !== '') return false;

						return true;
					},
				}),
			})
			.nullable(),

		// Entrega RG
		entrega: Yup.string().required('Campo Obrigatório: Como será a entrega?'),
		qtdKitFamilia: Yup.string().when('entrega', {
			is: entrega => entrega === 'KC',
			then: Yup.string().required(
				'Campo Obrigatório: Kit família? Para quantas Carteiras de Identidades?',
			),
		}),
		// data: Yup.string().when('entrega', {
		// 	is: entrega => entrega === 'P',
		// 	then: Yup.string().required('Campo Obrigatório: Previsão para retirada.'),
		// }),
		// hora: Yup.string().when('entrega', {
		// 	is: entrega => entrega === 'P',
		// 	then: Yup.string().required('Campo Obrigatório: Horário para retirada.'),
		// }),
	},
	[
		['nome', 'nomeSocial'],
		['nome', 'nomeMae'],
		['nome', 'nomePai'],
		['filiacaoTres', 'multifiliacao'],
		['naoPossuiEmail', 'email'],
		['email', 'naoPossuiEmail'],
		['categoriaCnh', 'cnh'],
	],
);
