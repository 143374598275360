import { AtualizarPortalSolicitacaoRequest, Types } from './types';

export function atualizarSolicitacaoPortalRequest(
	token: string,
	payload: AtualizarPortalSolicitacaoRequest,
	idSolicitacao: number,
) {
	return {
		type: Types.ATUALIZAR_SOLICITACAO_PORTAL_REQUEST,
		token,
		payload,
		idSolicitacao,
	};
}
export function atualizarSolicitacaoPortalSuccess(payload: object) {
	return {
		type: Types.ATUALIZAR_SOLICITACAO_PORTAL_SUCCESS,
		payload,
	};
}
export function atualizarSolicitacaoPortalFailure(payload: object): any {
	return {
		type: Types.ATUALIZAR_SOLICITACAO_PORTAL_FAILURE,
		payload,
	};
}

export function limparAtualizaSolicitacaoPortal(): any {
	return {
		type: Types.LIMPAR_ATUALIZAR_SOLICITACAO_PORTAL,
	};
}
