import React, {
	ChangeEvent,
	Suspense,
	useCallback,
	useState,
	useEffect,
	useMemo,
} from 'react';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_RESULTADO } from 'pages/DetranCrv/routes/paths';

// ANTD
import { Col, Row, Skeleton } from 'antd';

// REDUX
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { IConsultaResumoExtratoForm } from 'store/modules/api/detranCrv/ConsultarResumoExtrato/types';
import {
	consultarPEBERequest,
	consultarPEBEClear,
} from 'store/modules/api/detranCrv/ConsultarResumoExtrato/pebe/actions';
import {
	consultarPTRESRequest,
	consultarPTRESClear,
} from 'store/modules/api/detranCrv/ConsultarResumoExtrato/ptres/actions';
import {
	ICadastroEventoRequest,
	IDadosRequest,
} from 'store/modules/api/contabilizacao/cadastroEvento/types';
import { cadastroEventoRequest } from 'store/modules/api/contabilizacao/cadastroEvento/actions';
import EnumCRV from 'store/modules/api/detranCrv/enum';

// COMPONENTS COMMON
import ButtonImage from 'components/Common/ButtonImage';
import Input from 'components/Common/Form/Input/Input';
import Section from 'components/Common/Section';
import Radio from 'components/Common/Form/Radio';
import Select from 'components/Common/Form/Select';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import { limparMascara, onlyNumbersLettersSpace } from 'utils/genericFunctions';
import { getTimestamp } from 'utils/dateFunctions';
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// FORM
import { Field, Form, Formik, FormikProps } from 'formik';
import { initialValues, schema, treatValues } from './form';

type TypeAccounting = {
	outras_bases: {
		id_servico: string | number;
		desc_servico: string;
		tipo: string;
	};
};

const ConsultarResumoExtratoOutrasBases: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const { pebe, ptres } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarResumoExtrato,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const Options = [
		{
			value: 'placa',
			label: 'Placa',
		},
		{
			value: 'chassi',
			label: 'Chassi',
		},
	];

	const [lenght, setLenght] = useState<number>(7);

	const [validator, setValidator] = useState<string>('');

	const handleTipoContabilizacao = useMemo(() => {
		return {
			outras_bases: {
				id_orgao: 338,
				id_servico: 194,
				desc_servico: 'consulta resumo extrato outras bases',
				tipo: 'detran_crv_resumo_extrato_outras_bases',
			},
		};
	}, []);

	const handleContabilizar = useCallback(() => {
		const tipoContabilizacao: TypeAccounting['outras_bases'] =
			handleTipoContabilizacao.outras_bases;

		const identificacao =
			(Number(ptres.data?.cpfCnpj) && limparMascara(ptres.data.cpfCnpj)) ||
			(Number(pebe.data?.cpfCnpj) && limparMascara(pebe.data.cpfCnpj)) ||
			'0';

		const dados: IDadosRequest = {
			data_hora: getTimestamp().toString() || '',
			id_servico: tipoContabilizacao.id_servico || '',
			desc_servico: tipoContabilizacao.desc_servico || '',
			identificacao,
			cpf: limparMascara(loginUnico.login.user.cpf) || '',
			ip: loginUnico.login.user.ip || '',
			id_orgao: 338,
			desc_orgao: 'DETRAN',
			id_localidade: loginUnico.login.user.idLocal,
			desc_localidade: loginUnico.login.user.nomePosto || '',
		};

		const contabilizacao: ICadastroEventoRequest = {
			id_atendimento: dadosAtendimento?.idAtendimento || '',
			tipo: tipoContabilizacao.tipo || '',
			id_cidadao: dadosAtendimento?.idCidadao || '',
			data: getTimestamp() || 0,
			dados: JSON.stringify(dados) || '',
			canal: {
				id: loginUnico.login.user.idCanal.toString() || '',
				descricao: loginUnico.login.user.descCanal || '',
			},
		};

		dispatch(cadastroEventoRequest(contabilizacao));
	}, [
		handleTipoContabilizacao,
		ptres.data,
		pebe.data,
		loginUnico,
		dadosAtendimento,
		dispatch,
	]);

	const handleChange = useCallback(
		(value: string) => {
			dispatch(clearNotifications());

			switch (value) {
				case 'placa':
					setLenght(7);

					setValidator('validatePlaca');

					break;

				case 'chassi':
					setLenght(21);

					setValidator('validateChassi');

					break;

				default:
					break;
			}
		},
		[dispatch],
	);

	const handleClear = useCallback(
		(formik: FormikProps<IConsultaResumoExtratoForm>) => {
			formik.handleReset();

			dispatch(consultarPEBEClear());

			dispatch(consultarPTRESClear());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: IConsultaResumoExtratoForm) => {
			const formattedValues = treatValues(values);

			dispatch(clearNotifications());

			dispatch(consultarPEBERequest(formattedValues.pebe));

			dispatch(consultarPTRESRequest(formattedValues.ptres));
		},
		[dispatch],
	);

	// Verifica se o status é de sucesso (200) ou se há alguma mensagem de validação a ser exibida nos paineis (406) e o tipo de filtro permitido (placa/chassi)
	const handleVerifyStatusAndTypeSearch = useCallback(() => {
		const pebeStatusSuccess = pebe.status === 200 || pebe.status === 406;

		const ptresStatusSuccess = ptres.status === 200 || ptres.status === 406;

		const statusSuccess = pebeStatusSuccess && ptresStatusSuccess;

		return statusSuccess;
	}, [pebe.status, ptres.status]);

	useEffect(() => {
		if (handleVerifyStatusAndTypeSearch()) {
			dispatch(clearNotifications());

			handleContabilizar();

			history.push(ROUTE_DETRAN_CONSULTA_RESUMO_EXTRATO_OUTRAS_BASES_RESULTADO);
		}
	}, [history, handleVerifyStatusAndTypeSearch, handleContabilizar, dispatch]);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={values => handleSubmit(values)}
				>
					{(formik: FormikProps<IConsultaResumoExtratoForm>) => {
						return (
							<Form>
								<Row justify="center">
									<Col>
										<Row gutter={[0, 20]} justify="center">
											<Col style={{ marginLeft: '140px' }}>
												<Field
													name="selecao"
													as={Radio}
													options={Options}
													defaultValue="placa"
													error={hasError(formik.errors, 'selecao')}
													onChange={(event: ChangeEvent<HTMLInputElement>) => {
														const { value } = event.target;

														formik.handleReset();

														formik.setFieldValue('selecao', value);

														handleChange(value);
													}}
												/>
											</Col>
										</Row>

										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Field
													title="Documento"
													maxLength={lenght}
													titleSize="lg"
													name={formik.values.selecao}
													as={Input}
													size={96}
													required
													onChange={(e: { target: { value: string } }) => {
														if (
															e.target.value.length === 7 ||
															e.target.value.length >= 17
														) {
															dispatch(clearNotifications());
														}

														formik.setFieldValue(
															`${formik.values.selecao}`,
															onlyNumbersLettersSpace(
																e.target.value.toUpperCase(),
															),
														);
													}}
													error={hasError(formik.errors, validator)}
												/>
											</Col>
										</Row>

										{/* <Row>
											<Col span={24}>
												<Field
													required
													titleSize="lg"
													maxLength={14}
													name="documento"
													title="CPF/CNPJ Proprietário"
													size={96}
													as={Input}
													onChange={(e: { target: { value: string } }) => {
														formik.setFieldValue(
															'documento',
															onlyNumbersLettersSpace(
																e.target.value.toLocaleUpperCase(),
															),
														);
													}}
													error={hasError(formik.errors, 'documento')}
												/>
											</Col>
										</Row> */}

										{/* <Row>
											<Col span={24}>
												<Field
													required
													titleSize="lg"
													maxLength={11}
													name="renavam"
													title="Renavam"
													size={96}
													as={Input}
													onChange={(e: { target: { value: string } }) => {
														formik.setFieldValue(
															'renavam',
															onlyNumbers(e.target.value.toLocaleUpperCase()),
														);
													}}
													error={hasError(formik.errors, 'renavam')}
												/>
											</Col>
										</Row> */}

										<Row>
											<Col span={24}>
												<Field
													required
													titleSize="lg"
													as={Select}
													name="ufVeiculo"
													title="UF"
													size={96}
													options={EnumCRV.uf}
													onChange={(v: string) => {
														formik.setFieldValue('ufVeiculo', v.toUpperCase());
													}}
													error={hasError(formik.errors, 'ufVeiculo')}
												/>
											</Col>
										</Row>
									</Col>

									<Col span={4} />
								</Row>

								<Row
									style={{ marginTop: '20px' }}
									justify="center"
									align="top"
									gutter={[20, 10]}
								>
									<Col>
										<ButtonImage
											type="button"
											src="limpar"
											onClick={() => handleClear(formik)}
										/>
									</Col>

									<Col>
										<ButtonImage type="submit" src="pesquisar" />
									</Col>
								</Row>
							</Form>
						);
					}}
				</Formik>
			</Section>

			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default ConsultarResumoExtratoOutrasBases;
