import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, AtualizarSolicitacaoPortal } from './types';

export const INITIAL_STATE: AtualizarSolicitacaoPortal = {
	status: 0,
	data: null,
	dataRequest: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): AtualizarSolicitacaoPortal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.ATUALIZAR_SOLICITACAO_PORTAL_REQUEST: {
				draft.status = 100;
				draft.dataRequest = action.payload;
				break;
			}

			case Types.ATUALIZAR_SOLICITACAO_PORTAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.ATUALIZAR_SOLICITACAO_PORTAL_FAILURE: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.LIMPAR_ATUALIZAR_SOLICITACAO_PORTAL: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataRequest = INITIAL_STATE.dataRequest;
				break;
			}

			default:
		}
		return draft;
	});
}
