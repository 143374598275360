import { ListarSolicitacaoPortalData } from 'store/modules/api/mp/listarSolicitacaoPortal/types';
import { formatDateISOWithOutUTC, unformatDate } from 'utils/genericFunctions';
import * as Yup from 'yup';
import { ICadastroSolicitante } from '../NovoCadastro/Forms/form';

interface BuscaSolicitaoPaternidade {
	cpf: string;
}

export const initialValues = {
	cpf: '',
};

export const treatPreCadastroRequest = (
	dados: ListarSolicitacaoPortalData,
	solicitante?: number,
): ICadastroSolicitante => {
	const dataRegister = {
		...dados,
		idSolicitacao: dados.id || 0,
		destinatarioSolicitacao:
			solicitante?.toString() || dados.destinatarioSolicitacao || '',
		RGempty: false,
		tipoSolicitante: solicitante || Number(dados.destinatarioSolicitacao) || 0,
		anexos: [],
		protocolo: dados.protocolo || '',
		dataCriacao: dados.dataCriacao || '',
		dataModificacao: dados.dataModificacao || '',
		idCidadao: dados.idCidadao || '',
		idAtendimento: dados.idAtendimento || '',
		emailContato: dados.emailContato || '',
		filho: {
			...dados.filho,
			nome: dados.filho?.nome || '',
			dtNascimento:
				(dados.filho?.dataNascimento &&
					formatDateISOWithOutUTC(unformatDate(dados.filho.dataNascimento))) ||
				'',
			sexo: dados.filho?.sexo || '',
			cpf: dados.filho?.cpf || '',
			rg: {
				numero: dados.filho?.rg?.numero || '',
				digito: dados.filho?.rg?.digito || '',
				uf: dados.filho?.rg?.uf || '',
			},
			endereco: {
				logradouro: dados.filho?.endereco?.logradouro || '',
				numero: '',
				complemEndereco: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
			email: '',
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			genitor: {
				...dados.filho.genitor,
				outrasInformacoes: '',
				nome: dados.filho?.genitor?.nome || '',
				sexo: dados.filho?.genitor?.sexo || '',
				cpf: '',
				dtNascimento:
					(dados.filho?.genitor?.dataNascimento &&
						formatDateISOWithOutUTC(
							unformatDate(dados.filho.genitor.dataNascimento),
						)) ||
					'',
				telefones: {
					fixo: '',
					celular: '',
					comercial: '',
				},
				rg: {
					numero: '',
					digito: '',
					uf: '',
				},
				endereco: {
					logradouro: '',
					complemEndereco: '',
					numero: '',
					bairro: '',
					cep: '',
					cidade: '',
					uf: '',
				},
				email: '',
			},
		},
		responsavel: {
			...dados.responsavel,
			nome: '',
			dtNascimento:
				(dados.responsavel?.dataNascimento &&
					formatDateISOWithOutUTC(
						unformatDate(dados.responsavel.dataNascimento),
					)) ||
				'',
			sexo: '',
			cpf: '',
			rg: {
				numero: '',
				digito: '',
				uf: '',
			},
			telefones: {
				fixo: '',
				celular: '',
				comercial: '',
			},
			endereco: {
				logradouro: '',
				complemEndereco: '',
				numero: '',
				bairro: '',
				cep: '',
				cidade: '',
				uf: '',
			},
			email: '',
		},
		aceiteTermo: dados.aceiteTermo || false,
		aceiteAcrescNomePai: dados.aceiteAcrescNomePai || false,
		aceiteTermoDeclaracaoPobreza: dados.aceiteTermoDeclaracaoPobreza || false,
		aceiteAcrescimoNome: false,
		informacoesAdicionais: '',
	};

	dataRegister.filho.nome = dados.filho?.nome || '';
	dataRegister.filho.sexo = dados.filho?.sexo || '';
	dataRegister.filho.email = dados.filho?.email || '';
	dataRegister.filho.rg.numero = dados.filho?.rg?.numero || '';
	dataRegister.filho.rg.digito = dados.filho?.rg?.digito || '';
	dataRegister.filho.rg.uf = dados.filho?.rg?.uf || '';
	dataRegister.filho.cpf = dados.filho?.cpf || '';
	dataRegister.filho.endereco.cep = dados.filho?.endereco?.cep || '';
	dataRegister.filho.endereco.logradouro =
		dados.filho?.endereco?.logradouro || '';
	dataRegister.filho.endereco.numero = dados.filho?.endereco?.numero || '';
	dataRegister.filho.endereco.bairro = dados.filho?.endereco?.bairro || '';
	dataRegister.filho.endereco.uf = dados.filho?.endereco?.uf || '';
	dataRegister.filho.endereco.cidade = dados.filho?.endereco?.cidade || '';
	dataRegister.filho.endereco.complemEndereco =
		dados.filho?.endereco?.complementoEndereco || '';

	dados.filho?.telefones?.length > 0 &&
		dados.filho.telefones.forEach(tel => {
			if (Number(tel.tipo) === 2) {
				dataRegister.filho.telefones.celular = tel.ddd + tel.numero;
			} else if (Number(tel.tipo) === 1) {
				dataRegister.filho.telefones.fixo = tel.ddd + tel.numero;
			} else {
				dataRegister.filho.telefones.comercial = tel.ddd + tel.numero;
			}
		});

	dataRegister.responsavel.nome = dados.responsavel?.nome || '';
	dataRegister.responsavel.dtNascimento =
		(dados.responsavel?.dataNascimento &&
			formatDateISOWithOutUTC(
				unformatDate(dados.responsavel.dataNascimento),
			)) ||
		'';
	dataRegister.responsavel.sexo = dados.responsavel?.sexo || '';
	dataRegister.responsavel.rg.numero = dados.responsavel?.rg?.numero || '';
	dataRegister.responsavel.rg.digito = dados.responsavel?.rg?.digito || '';
	dataRegister.responsavel.rg.uf = dados.responsavel?.rg?.uf || '';
	dataRegister.responsavel.cpf = dados.responsavel?.cpf || '';
	dataRegister.responsavel.endereco.cep =
		dados.responsavel?.endereco?.cep || '';
	dataRegister.responsavel.endereco.logradouro =
		dados.responsavel?.endereco?.logradouro || '';
	dataRegister.responsavel.endereco.numero =
		dados.responsavel?.endereco?.numero || '';
	dataRegister.responsavel.endereco.bairro =
		dados.responsavel?.endereco?.bairro || '';
	dataRegister.responsavel.endereco.uf = dados.responsavel?.endereco?.uf || '';
	dataRegister.responsavel.endereco.cidade =
		dados.responsavel?.endereco?.cidade || '';
	dataRegister.responsavel.endereco.complemEndereco =
		dados.responsavel?.endereco?.complementoEndereco || '';
	dataRegister.responsavel.email = dados.responsavel?.email || '';

	dados.responsavel?.telefones?.length > 0 &&
		dados.responsavel.telefones.forEach(tel => {
			if (Number(tel.tipo) === 2) {
				dataRegister.responsavel.telefones.celular = tel.ddd + tel.numero;
			} else if (Number(tel.tipo) === 1) {
				dataRegister.responsavel.telefones.fixo = tel.ddd + tel.numero;
			} else {
				dataRegister.responsavel.telefones.comercial = tel.ddd + tel.numero;
			}
		});

	dataRegister.filho.genitor.nome = dados.filho?.genitor?.nome || '';
	dataRegister.filho.genitor.dtNascimento =
		(dados.filho?.genitor?.dataNascimento &&
			formatDateISOWithOutUTC(
				unformatDate(dados.filho.genitor.dataNascimento),
			)) ||
		'';
	dataRegister.filho.genitor.sexo = dados.filho?.genitor?.sexo || '';
	dataRegister.filho.genitor.rg.numero = dados.filho?.genitor?.rg?.numero || '';
	dataRegister.filho.genitor.rg.digito = dados.filho?.genitor?.rg?.digito || '';
	dataRegister.filho.genitor.rg.uf = dados.filho?.genitor?.rg?.uf || '';
	dataRegister.filho.genitor.cpf = dados.filho?.genitor?.cpf || '';
	dataRegister.filho.genitor.endereco.cep =
		dados.filho?.genitor?.endereco?.cep || '';
	dataRegister.filho.genitor.endereco.logradouro =
		dados.filho?.genitor?.endereco?.logradouro || '';
	dataRegister.filho.genitor.endereco.numero =
		dados.filho?.genitor?.endereco?.numero || '';
	dataRegister.filho.genitor.endereco.bairro =
		dados.filho?.genitor?.endereco?.bairro || '';
	dataRegister.filho.genitor.endereco.uf =
		dados.filho?.genitor?.endereco?.uf || '';
	dataRegister.filho.genitor.endereco.cidade =
		dados.filho?.genitor?.endereco?.cidade || '';
	dataRegister.filho.genitor.endereco.complemEndereco =
		dados.filho?.genitor?.endereco?.complementoEndereco || '';
	dataRegister.filho.genitor.email = dados.filho?.genitor?.email;
	dados.filho?.genitor?.telefones?.forEach(tel => {
		if (Number(tel.tipo) === 2) {
			dataRegister.filho.genitor.telefones.celular = tel.ddd + tel.numero;
		} else if (Number(tel.tipo) === 1) {
			dataRegister.filho.genitor.telefones.fixo = tel.ddd + tel.numero;
		} else {
			dataRegister.filho.genitor.telefones.comercial = tel.ddd + tel.numero;
		}
	});

	return dataRegister;
};

export const schema = Yup.object<BuscaSolicitaoPaternidade>().shape({
	cpf: Yup.string().min(11, `Necessário preencher o CPF para pesquisar.`),
});
