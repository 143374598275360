import React, { useEffect } from 'react';

// ROUTES
import { ROUTE_IIRGD } from 'routes/paths';

// REDUX
import { useDispatch } from 'react-redux';
import { aacClear } from 'store/modules/api/aac/actions';

//	COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// STYLED
import { Content, Icon, ContentText } from './styled';

const Apresentacao: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(aacClear());
	}, [dispatch]);

	return (
		<>
			<Section>
				<Content>
					<Icon />

					<h2>Apresentação do Serviço</h2>
				</Content>

				<ContentText>
					<p>Selecione uma das opções acima.</p>

					<span>
						Este serviço efetua consulta automática nos cadastros informatizados
						do IIRGD.
					</span>

					<span>
						Na solicitação numeral são feitas consultas com RG/CIN e dados de
						qualificação, nos Sistemas de identificação Civil e Criminal.
					</span>

					<span>
						Na solicitação nominal será feita consulta de nome e filtração no
						Sistema Criminal.
					</span>
				</ContentText>
			</Section>

			<ButtonVoltar route={ROUTE_IIRGD} />
		</>
	);
};

export default Apresentacao;
