import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, GerarOcorrencia } from './types';

export const INITIAL_STATE: GerarOcorrencia = {
	status: 0,
	data: null,
	form: null,
	calculoBoletoAgrupado: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): GerarOcorrencia {
	return produce(state, draft => {
		switch (action.type) {
			case Types.GERAR_OCORRENCIA_REQUEST: {
				break;
			}
			case Types.GERAR_OCORRENCIA_SUCCESS: {
				const { data, form } = action.payload;
				draft.status = 200;
				draft.data = data;
				draft.form = form;
				break;
			}
			case Types.GERAR_OCORRENCIA_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.GERAR_OCORRENCIA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				break;
			}

			case Types.CALCULO_BOLETO_AGRUPADO_REQUEST: {
				draft.calculoBoletoAgrupado = action.payload;
				break;
			}
			case Types.CALCULO_BOLETO_AGRUPADO_CLEAR: {
				draft.calculoBoletoAgrupado = INITIAL_STATE.calculoBoletoAgrupado;
				break;
			}

			default:
		}

		return draft;
	});
}
