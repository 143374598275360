export enum Types {
	EVENTOS_PPT_CONTABILIZACAO_REQUEST = '@utils/EVENTOS_PPT_CONTABILIZACAO_REQUEST',
	EVENTOS_PPT_CONTABILIZACAO_SUCCESS = '@utils/EVENTOS_PPT_CONTABILIZACAO_SUCCESS',
	EVENTOS_PPT_CONTABILIZACAO_FAILURE = '@utils/EVENTOS_PPT_CONTABILIZACAO_FAILURE',
	EVENTOS_PPT_CONTABILIZACAO_CLEAR = '@utils/EVENTOS_PPT_CONTABILIZACAO_CLEAR',
}

export interface EventosPPTContabilizacao {
	status: number;
	data: null;
	dataFailure: null | EventosPPTContabilizacaoDataFailure;
	detailsEventRequest: EventosPPTDetailsEventRequest;
	detailsStartSection: EventosPPTDetailsStartSection | null;
}

interface EventosPPTDetailsEventRequest {
	historyExecutedEvents: string[];
}
interface EventosPPTDetailsStartSection {
	dataInicio: number;
	cpf: string;
}
export interface EventosPPTContabilizacaoRequest {
	id_atendimento: string;
	tipo: string;
	data: number;
	id_cidadao?: string;
	cpf?: string;
	dados: {
		tipo_isencao?: string;
		identificacao?: string;
		ip: string;
		reuso?: {
			erro_reuso?: string;
			dedos?: string;
			confronto_realizado?: string;
		};
		naturalizado?: string;
		entrega_correio?: string;
		inclusao_nome_social?: string;
		criminal?: string;
		menor_idade?: string;
		cili_simplificada?: string;
		kit_familia?: string;
		entrega_posto?: string;
		desc_orgao?: string;
		id_orgao_censo?: number;
		desc_estacao?: string;
		atendente?: {
			nome?: string;
			id_atendente?: string;
			desc_posto?: string;
			id_posto?: string;
		};
		id_servico?: number;
		desc_servico?: string;
		id_orgao: number;
		id_posto?: number;
		desc_servico_censo?: string;
		desc_localidade: string;
		id_servico_censo?: string;
		cpf?: string;
		cpf_operador?: string;
		data_hora?: string;
		id_localidade: number;
		servico?: {
			data_inicio?: number;
			nome?: string;
			duracao?: number;
			data_termino?: number;
		};
		desc_orgao_censo?: string;
		id_estacao?: number;
		dare_vinculada?: string;
		desc_posto?: string;
		inclusao_cpf?: string;
		dataOcorrencia?: string;
	};
	canal: {
		id: number;
		desc: string;
	};
	client_id?: string;
}

export interface EventosPPTContabilizacaoDataFailure {
	message: number;
	trace: string;
	messages: string[];
}
