import { ApiResponse } from 'services/_api';
import { ReducerActionRotaSP } from 'store/modules/types';
import { IRequestEnviarSolicitacaoMP, Types } from './types';

export function enviarSolicitacaoMPRequest(
	token: string,
	payload: IRequestEnviarSolicitacaoMP,
): ReducerActionRotaSP {
	return {
		type: Types.ENVIAR_SOLICITACAO_MP_REQUEST,
		token,
		payload,
	};
}
export function enviarSolicitacaoMPSuccess(payload: ApiResponse) {
	return {
		type: Types.ENVIAR_SOLICITACAO_MP_SUCCESS,
		payload,
	};
}
export function enviarSolicitacaoMPFailure(payload: []): any {
	return {
		type: Types.ENVIAR_SOLICITACAO_MP_FAILURE,
		payload,
	};
}
export function LimparSolicitacaoMP() {
	return {
		type: Types.LIMPAR_SOLICITACAO_MP,
	};
}
