// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { RecuperarAtendimentoRequest, Types } from './types';

export function recuperarAtendimentoRequest(
	token: string,
	payload: RecuperarAtendimentoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_REQUEST,
		payload,
		token,
	};
}
export function recuperarAtendimentoSuccess(
	payload: string,
): ReducerActionRotaSP {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_SUCCESS,
		payload,
	};
}
export function recuperarAtendimentoFailure(
	payload: ApiResponse,
): ReducerActionRotaSP {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_FAILURE,
		payload,
	};
}
export function recuperarAtendimentoClear(): ReducerActionRotaSP {
	return {
		type: Types.RECUPERAR_ATENDIMENTO_CLEAR,
		payload: null,
	};
}
