import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ReducerAction } from 'store/modules/types';
import handleFailed from 'utils/getValidations';
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_FICHAS_API } from 'services/rotasp/path';
import { FichaAlunoEnvio, Types } from './types';

import {
	cadastrarFichaAlunoSuccess,
	cadastrarFichaAlunoFailure,
} from './actions';

function* cadastrarFichaAlunoRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: FichaAlunoEnvio } =
		request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_EDUCACAO_SEDUC_FICHAS_API,
		`fichas`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(cadastrarFichaAlunoSuccess({ request: payload, response }));
	} else if (response.error) {
		handleFailed({
			mensagem_erro: response.data?.data?.messages,
		});
		yield put(cadastrarFichaAlunoFailure(response.message));
	}
}

export default all([
	takeLatest(Types.CADASTRAR_FICHA_ALUNO_REQUEST, cadastrarFichaAlunoRequest),
]);
