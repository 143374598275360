// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import {
	ObterFichaFinanceiraData,
	ObterFichaFinanceiraRequest,
	Types,
} from './types';

export function obterFichaFinanceiraRequest(
	payload: ObterFichaFinanceiraRequest,
): ReducerActionRotaSP {
	return {
		type: Types.OBTER_FICHA_FINANCEIRA_REQUEST,
		payload,
	};
}
export function obterFichaFinanceiraSuccess(
	data: ObterFichaFinanceiraData,
): ReducerActionRotaSP {
	return {
		type: Types.OBTER_FICHA_FINANCEIRA_SUCCESS,
		payload: data
	};
}
export function obterFichaFinanceiraFailure(): ReducerActionRotaSP {
	return {
		type: Types.OBTER_FICHA_FINANCEIRA_FAILURE,
		payload: null,
	};
}
export function obterFichaFinanceiraClear(): ReducerActionRotaSP {
	return {
		type: Types.OBTER_FICHA_FINANCEIRA_CLEAR,
		payload: null,
	};
}
