import { RemoverInscricaoData, Types } from './types';

export function removerInscricaoRequest(
	token: string,
	payload: RemoverInscricaoData,
) {
	return {
		type: Types.REMOVER_INSCRICAO_REQUEST,
		token,
		payload,
	};
}

export function removerInscricaoSuccess(payload: object) {
	return {
		type: Types.REMOVER_INSCRICAO_SUCCESS,
		payload,
	};
}

export function removerInscricaoFailure(payload: boolean) {
	return {
		type: Types.REMOVER_INSCRICAO_FAILURE,
		payload,
	};
}
