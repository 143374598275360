export enum Types {
	ALTERAR_FICHA_ALUNO_REQUEST = '@EDUCACAO/ALTERAR_FICHA_ALUNO_REQUEST',
	ALTERAR_FICHA_ALUNO_SUCCESS = '@EDUCACAO/ALTERAR_FICHA_ALUNO_SUCCESS',
	ALTERAR_FICHA_ALUNO_FAILURE = '@EDUCACAO/ALTERAR_FICHA_ALUNO_FAILURE',
	ALTERAR_FICHA_ALUNO_CLEAR = '@EDUCACAO/ALTERAR_FICHA_ALUNO_CLEAR',
}

export interface AlterarFichaAluno {
	status: number;
	data: AlterarFichaAlunoData | null;
	dataRequest: AlterarFichaAlunoRequestData | null;
}

interface AlterarFichaAlunoData {
	sucesso: string;
	processoId: string;
}

export interface AlterarFichaAlunoRequestData {
	aluno: Aluno;
	dadosPessoais: DadosPessoais;
	documentos: Documentos;
	enderecoResidencial: EnderecoResidencial;
	deficiencia: DeficienciaAlterarFichaAluno;
	recursoAvaliacao: RecursoAlterarFichaAluno;
	certidaoAntiga: CertidaoAntiga;
	certidaoNova: CertidaoNova;
	rastreio: Rastreio;
	telefones: AlterarFichaAlunoTelefone[];
	latLngSelecionada?: LatLngSelecionada | null;
}

export interface DadosPessoais {
	nomeAluno: string;
	dataNascimento: string;
	codCorRaca: string;
	codSexo: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	nomeAfetivo: string;
	email: string;
	codNacionalidade: string;
	nacionalidade?: string;
	codPaisOrigem: string;
	paisOrigem: string;
	dataEntradaPais: string;
	bolsaFamilia: boolean;
	quilombola: boolean;
	possuiInternet: boolean;
	possuiNotebookSmartphoneTablet: boolean;
	nomeMunicipioNascimento: string;
	ufMunicipioNascimento: string;
	codMunicipioNascimentoDNE: string;
	doadorOrgaos: boolean;
	tipoSanguineo: string;
}

export interface Documentos {
	codigoINEP: string;
	cpf: string;
	numDocumentoCivil: string;
	digitoDocumentoCivil: string;
	ufDocumentoCivil: string;
	dataEmissaoDocumentoCivil: string;
	numeroNis: string;
	numeroCns: string;
	justificativaDocumentos: string;
}

export interface EnderecoResidencial {
	logradouro: string;
	numero: string;
	areaLogradouro?: string;
	codAreaLogradouro: string;
	complemento: string;
	bairro: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	cep: string;
	codMunicipioDNE: string;
	codLocalizacaoDiferenciada: string;
}

export interface CertidaoAntiga {
	numCertidao: string;
	livro: string;
	folha: string;
	municipioComarca: string;
	ufComarca: string;
	distritoCertidao: string;
	dataEmissaoCertidao: string;
	codMunicComarcaDNE: string;
}

export interface CertidaoNova {
	certMatr01: string;
	certMatr02: string;
	certMatr03: string;
	certMatr04: string;
	certMatr05: string;
	certMatr06: string;
	certMatr07: string;
	certMatr08: string;
	certMatr09: string;
	dataEmissaoCertidao: string;
}

export interface Rastreio {
	usuarioRemoto: string;
	nomeUsuario: string;
	numCPF: string;
	localPerfilAcesso: string;
}

export interface RecursoAlterarFichaAluno {
	guiaInterprete: boolean;
	interpreteLibras: boolean;
	leituraLabial: boolean;
	nenhum: boolean;
	provaAmpliada: boolean;
	fonteProva: string;
	fonteProva18: boolean;
	fonteProva20: boolean;
	provaBraile: boolean;
	auxilioTranscricao: boolean;
	auxilioLeitor: boolean;
	provaVideoLibras: boolean;
	cdAudioDefVisual: boolean;
	provaLinguaPortuguesa: boolean;
}

export interface DeficienciaAlterarFichaAluno {
	codigoNecessidade: string;
	mobilidadeReduzida: string | boolean;
	tipoMobilidadeReduzida: string;
	dataInicioMobilidadeReduzida: string;
	dataFimMobilidadeReduzida: string;
	codigoNivelSuporte: string | number;
	profissionalApoioEscolarVO: ProfissionalApoioEscolar;
}

export interface ProfissionalApoioEscolar {
	profissionalApoioEscolar: boolean;
	atividadeDiaria: boolean;
	atividadeEscolar: boolean;
	apoioHigiene: boolean;
	apoioLocomocao: boolean;
	apoioAlimentacao: boolean;
	apoioBanheiro: boolean;
	apoioMedicamento: boolean;
	dataInicioVigenciaAtividadeDiaria: string;
	dataFimVigenciaAtividadeDiaria: string;
	dataInicioVigenciaAtividadeEscolar: string;
	dataFimVigenciaAtividadeEscolar: string;
}

interface Aluno {
	numRA: string;
	digitoRA: string;
	siglaUFRA: string;
}

export interface AlterarFichaAlunoTelefone {
	dddNumero: string;
	numero: string;
	tipoTelefone: string;
	complemento: string;
	sms: boolean;
}

interface LatLngSelecionada {
	lat: number;
	lng: number;
}
