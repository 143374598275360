// TYPES
import { ReducerAction } from 'store/modules/types';
import {
	ObterTokenCdhuData,
	Types,
} from './types';

export function obterTokenCdhuRequest() {
	return {
		type: Types.OBTER_TOKEN_CDHU_REQUEST,
	};
}
export function obterTokenCdhuSuccess(
	data: ObterTokenCdhuData,
): ReducerAction {
	return {
		type: Types.OBTER_TOKEN_CDHU_SUCCESS,
		payload: data
	};
}
export function obterTokenCdhuFailure(): ReducerAction {
	return {
		type: Types.OBTER_TOKEN_CDHU_FAILURE,
		payload: null,
	};
}
export function obterTokenCdhuClear(): ReducerAction {
	return {
		type: Types.OBTER_TOKEN_CDHU_CLEAR,
		payload: null,
	};
}
