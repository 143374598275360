import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_POUPA_FILA } from 'services/rotasp/path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { RecuperarAtendimentoRequest, Types } from './types';

// ACTIONS
import {
	recuperarAtendimentoSuccess,
	recuperarAtendimentoFailure,
} from './actions';

function* recuperarAtendimentoRequest(request: ReducerAction) {
	const {
		token,
		payload,
	}: { payload: RecuperarAtendimentoRequest; token?: string } = request;

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_POUPA_FILA,
		`atendimentos?cpfAtendente=${payload.cpf}`,
	);

	if (response.status === 200) {
		yield put(recuperarAtendimentoSuccess(response.data));
	} else {
		yield put(recuperarAtendimentoFailure(response));
	}
}

export default all([
	takeLatest(Types.RECUPERAR_ATENDIMENTO_REQUEST, recuperarAtendimentoRequest),
]);
