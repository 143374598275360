import {
	convertDate,
	formatDateEnUs,
	limparMascara,
	removeAcentoCedilha,
} from 'utils/genericFunctions';
import { RecuperarAtendimento } from 'store/modules/api/sgu/atendimento/recuperar/types';
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import {
	ILogin,
	IParametrosLocalidade,
} from 'store/modules/api/sgu/loginUnico/types';

import { PreCadastroCidadaoRequest } from '../../../../store/modules/api/sguService/preCadastroCidadao/types';
import { ICadastroSolicitante } from './Forms/form';

function treatObjNullValues(data: object): object {
	const newData: any = {};

	Object.entries(data).forEach(([key, value]) => {
		newData[key] = value !== null && value !== undefined ? value : '';
	});
	return newData;
}

export const formataSolicitaTermo = async (
	form: ICadastroSolicitante,
	nomePosto: string,
	numeroTermo?: number | null,
) => {
	const values: any = treatObjNullValues(form);
	const body = {
		solicitacaoPaternidade: {
			nomePosto,
			protocolo: numeroTermo || '',
			informacoesAdicionais: values.filho.genitor.outrasInformacoes,
			aceiteTermoDeclaracaoPobreza: values.aceiteTermoDeclaracaoPobreza,
			aceiteTermo: values.aceiteTermo,
			incluirSobrenomeGenitor: values.aceiteAcrescNomePai,
			interessado: {
				nome: values.filho.nome,
				dataNascimento:
					(values?.filho?.dtNascimento &&
						convertDate(values.filho.dtNascimento)) ||
					'',
				rg: values.filho.rg?.numero ? values.filho.rg?.numero : '',

				cpf: values.filho.cpf,
				endereco: {
					logradouro: values.filho.endereco?.logradouro,
					numero: values.filho.endereco?.numero,
					complemento: values.filho.endereco?.complemEndereco,
					bairro: values.filho.endereco?.bairro,
					cep: values?.filho?.endereco?.cep || '',
					localidade: values.filho.endereco?.cidade,
					uf: values.filho.endereco?.uf,
				},
				contato: {
					telefone: values.filho.telefones?.fixo,
					celular: values.filho.telefones?.celular,
					telefoneComercial: values.filho.telefones?.comercial,
					email: values.filho.email,
				},
			},
			genitor: {
				nome: removeAcentoCedilha(
					values.filho.genitor.nome?.toUpperCase() || '',
				),
				dataNascimento:
					(values?.filho?.genitor?.dtNascimento &&
						convertDate(values.filho.genitor.dtNascimento)) ||
					'',
				sexo: values.filho.genitor.sexo,
				nomeMae: values.filho.genitor.nomeMae,
				localNascimento: '',
				ufNascimento: '',
				rg: values.filho.genitor.rg?.numero
					? values.filho.genitor.rg?.numero
					: '',

				cpf: values.filho.genitor.cpf,
				endereco: {
					logradouro: removeAcentoCedilha(
						values.filho.genitor.endereco?.logradouro.toUpperCase() || '',
					).trim(),
					numero: removeAcentoCedilha(
						values.filho.genitor.endereco?.numero.toUpperCase() || '',
					).trim(),
					complemento: removeAcentoCedilha(
						values.filho.genitor.endereco?.complemEndereco.toUpperCase() || '',
					).trim(),
					bairro: removeAcentoCedilha(
						values.filho.genitor.endereco?.bairro.toUpperCase() || '',
					).trim(),
					cep: values?.filho?.genitor?.endereco?.cep || '',
					localidade: removeAcentoCedilha(
						values.filho.genitor.endereco?.cidade.toUpperCase() || '',
					).trim(),
					uf: values.filho.genitor.endereco?.uf,
				},
				contato: {
					telefone: values.filho.genitor.telefones?.fixo,
					celular: values.filho.genitor.telefones?.celular,
					telefoneComercial: values.filho.genitor.telefones?.comercial,
					email: values.filho.genitor.email,
				},
			},
			responsavel: {
				nome: values.responsavel?.nome,
				rg: values.responsavel.rg?.numero ? values.responsavel.rg?.numero : '',
				cpf: values.responsavel?.cpf,
				endereco: {
					logradouro: values.responsavel?.endereco?.logradouro,
					numero: values.responsavel?.endereco?.numero,
					complemento: values.responsavel?.endereco?.complemEndereco,
					bairro: values.responsavel?.endereco?.bairro,
					cep: values?.responsavel?.endereco?.cep || '',
					localidade: values.responsavel?.endereco?.cidade,
					uf: values.responsavel?.endereco?.uf,
				},
				contato: {
					telefone: values.responsavel?.telefones?.fixo,
					celular: values.responsavel?.telefones?.celular,
					telefoneComercial: values.responsavel?.telefones?.comercial,
					email: values.responsavel?.email,
				},
			},
		},
	};
	return body;
};

export interface IGeraSenha {
	nome: string;
	numRg: string;
	digRg: string;
	cpf: string;
	cpfDig: string;
	senha: string;
}

export const formataSolicitaSenha = async (
	form: ICadastroSolicitante,
	senha: string,
) => {
	let payload: IGeraSenha = {
		nome: '',
		numRg: '',
		digRg: '',
		cpf: '',
		cpfDig: '',
		senha: '',
	};

	if (form.tipoSolicitante === 1) {
		const newCPF1 = form.filho.cpf ? form.filho.cpf.split('-') : '';
		payload = {
			nome: form.filho.nome,
			numRg: form.filho.rg?.numero ? '' : (form.filho.rg?.numero as string),
			digRg: form.filho.rg?.digito ? '' : (form.filho.rg?.digito as string),
			cpf: newCPF1.length > 0 ? limparMascara(newCPF1[0]) : '',
			cpfDig: newCPF1.length > 1 ? newCPF1[1] : '',
			senha,
		};
	} else if (form.tipoSolicitante === 3) {
		const newCPF2 = form.filho.genitor.cpf
			? form.filho.genitor.cpf.split('-')
			: '';
		payload = {
			nome: form.filho.genitor.nome,
			numRg: form.filho.genitor.rg?.numero
				? ''
				: (form.filho.genitor.rg?.numero as string),
			digRg: form.filho.genitor.rg?.digito
				? ''
				: (form.filho.genitor.rg?.digito as string),
			cpf: newCPF2.length > 0 ? limparMascara(newCPF2[0]) : '',
			cpfDig: newCPF2.length > 1 ? newCPF2[1] : '',
			senha,
		};
	} else if (form.tipoSolicitante === 2) {
		const newCPF3 = form.responsavel?.cpf
			? form.responsavel?.cpf.split('-')
			: '';
		payload = {
			nome: form.responsavel?.nome ? form.responsavel?.nome : '',
			numRg: form.responsavel?.rg?.numero
				? ''
				: (form.responsavel?.rg?.numero as string),
			digRg: form.responsavel?.rg?.digito
				? ''
				: (form.responsavel?.rg?.digito as string),
			cpf: newCPF3.length > 0 ? limparMascara(newCPF3[0]) : '',
			cpfDig: newCPF3.length > 1 ? newCPF3[1] : '',
			senha,
		};
	}
	return payload;
};

export interface EventoRequestData {
	id_atendimento: string;
	id_cidadao: string;
	cpf: string;
	identificacao: string;
	ip: string;
	canal: {
		id: number;
		desc: string;
		localidade: {
			id: number;
			desc: string;
		};
	};
	atendente: {
		nome: string;
		id_atendente: string;
		id_posto: string;
		desc_posto: string;
	};
}

export interface SguData {
	recuperarAtendimento: RecuperarAtendimento;
	salvarAtendimento: SalvarAtendimento;
	login: ILogin;
	user: {
		cpf: string;
		nome: string;
		orgaos: any;
		login?: boolean;
		parametrosSistema: IParametrosLocalidade[];
		perfil: any;
	};
}

function hasValue(obj: any, key: string) {
	if (!obj) return false;
	return (
		Object.prototype.hasOwnProperty.call(obj, key) &&
		obj[key] !== '00000000-0000-0000-0000-000000000000'
	);
}

export const getIdData = (data: SguData) => {
	const { salvarAtendimento, recuperarAtendimento } = data;
	const idData: any = {
		idCidadao: '',
		idAtendimento: '',
	};
	if (hasValue(recuperarAtendimento.data, 'idCidadao')) {
		idData.idCidadao = recuperarAtendimento.data?.idCidadao;
	}
	// if (
	// 	idData.idCidadao === '' &&
	// 	hasValue(recuperarAtendimento.data, 'id_cidadao')
	// ) {
	// 	idData.idCidadao = recuperarAtendimento.data?.id_cidadao;
	// }
	if (hasValue(recuperarAtendimento.data, 'idAtendimento')) {
		idData.idAtendimento = recuperarAtendimento.data?.idAtendimento;
	}
	// if (
	// 	idData.idAtendimento === '' &&
	// 	hasValue(recuperarAtendimento.data, 'id_atendimento')
	// ) {
	// 	idData.idAtendimento = recuperarAtendimento.data?.id_atendimento;
	// }
	if (
		idData.idCidadao === '' &&
		hasValue(salvarAtendimento.data, 'idCidadao')
	) {
		idData.idCidadao = salvarAtendimento.data?.idCidadao;
	}
	if (
		idData.idAtendimento === '' &&
		hasValue(salvarAtendimento.data, 'idAtendimento')
	) {
		idData.idAtendimento = salvarAtendimento.data?.idAtendimento;
	}
	return idData;
};

export const threatDataPreCadastroCidadaoRequest = (
	values: ICadastroSolicitante,
	valuesEvento: EventoRequestData,
): PreCadastroCidadaoRequest => {
	return {
		evento: {
			id_atendimento: valuesEvento.id_atendimento,
			id_cidadao: valuesEvento.id_cidadao,
			cpf: valuesEvento.cpf,
			identificacao: valuesEvento.identificacao,
			ip: valuesEvento.ip,
			canal: {
				id: valuesEvento.canal.id,
				desc: valuesEvento.canal.desc,
				localidade: {
					id: valuesEvento.canal.localidade.id,
					desc: valuesEvento.canal.localidade.desc,
				},
			},
			atendente: {
				nome: valuesEvento.atendente.nome,
				id_atendente: valuesEvento.atendente.id_atendente,
				id_posto: valuesEvento.atendente.id_posto,
				desc_posto: valuesEvento.atendente.desc_posto,
			},
		},
		rg:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.rg &&
				`${
					(values?.responsavel?.rg?.numero &&
						limparMascara(values.responsavel.rg.numero)) ||
					''
				}${
					(values?.responsavel?.rg?.digito &&
						limparMascara(values.responsavel.rg.digito)) ||
					''
				}`) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.rg &&
				`${
					(values?.filho?.rg?.numero &&
						limparMascara(values.filho.rg.numero)) ||
					''
				}${
					(values?.filho?.rg?.digito &&
						limparMascara(values.filho.rg.digito)) ||
					''
				}`) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor.rg &&
				`${
					(values?.filho?.genitor?.rg?.numero &&
						limparMascara(values.filho.genitor.rg.numero)) ||
					''
				}${
					(values?.filho?.genitor?.rg?.digito &&
						limparMascara(values.filho.genitor.rg.digito)) ||
					''
				}`) ||
			'',
		// ufRg:
		// 	(values?.tipoSolicitante === 2 && values?.responsavel?.rg?.uf) ||
		// 	(values?.tipoSolicitante === 1 && values?.filho?.rg?.uf) ||
		// 	(values?.tipoSolicitante === 3 && values?.filho?.genitor?.rg?.uf) ||
		// 	'',
		ufRg: null,
		cpf:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.cpf &&
				limparMascara(values.responsavel.cpf)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.cpf &&
				limparMascara(values.filho.cpf)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.cpf &&
				limparMascara(values.filho.genitor.cpf)) ||
			'',
		cns: '',
		nome:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.nome &&
				removeAcentoCedilha(values?.responsavel?.nome.toUpperCase() || '')) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.nome &&
				removeAcentoCedilha(values?.filho?.nome?.toUpperCase() || '')) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.nome &&
				removeAcentoCedilha(
					values?.filho?.genitor?.nome.toUpperCase() || '',
				)) ||
			'',
		firstName:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.nome &&
				removeAcentoCedilha(
					values.responsavel.nome.split(' ')[0].toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.nome &&
				removeAcentoCedilha(
					values.filho.nome.split(' ')[0].toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.nome &&
				removeAcentoCedilha(
					values.filho.genitor.nome.split(' ')[0].toUpperCase() || '',
				)) ||
			'',
		dataNascimento:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.dtNascimento &&
				formatDateEnUs(values.responsavel.dtNascimento).getTime().toString()) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.dtNascimento &&
				formatDateEnUs(values.filho.dtNascimento).getTime().toString()) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.dtNascimento &&
				formatDateEnUs(values.filho.genitor.dtNascimento)
					.getTime()
					.toString()) ||
			'',
		nomeMae: '',
		foneResidencialDdd:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.fixo &&
				limparMascara(values.responsavel.telefones.fixo).substring(0, 2)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.fixo &&
				limparMascara(values.filho.telefones.fixo).substring(0, 2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.fixo &&
				limparMascara(values.filho.genitor.telefones.fixo).substring(0, 2)) ||
			'',
		foneResidencial:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.fixo &&
				limparMascara(values.responsavel.telefones.fixo).substring(2)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.fixo &&
				limparMascara(values.filho.telefones.fixo).substring(2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.fixo &&
				limparMascara(values.filho.genitor.telefones.fixo).substring(2)) ||
			'',
		foneComercialDdd:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.comercial &&
				limparMascara(values.responsavel.telefones.comercial).substring(
					0,
					2,
				)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.comercial &&
				limparMascara(values.filho.telefones.comercial).substring(0, 2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.comercial &&
				limparMascara(values.filho.genitor.telefones.comercial).substring(
					0,
					2,
				)) ||
			'',
		foneComercial:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.comercial &&
				limparMascara(values.responsavel.telefones.comercial).substring(2)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.comercial &&
				limparMascara(values.filho.telefones.comercial).substring(2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.comercial &&
				limparMascara(values.filho.genitor.telefones.comercial).substring(2)) ||
			'',
		foneCelularDdd:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.celular &&
				limparMascara(values.responsavel.telefones.celular).substring(0, 2)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.celular &&
				limparMascara(values.filho.telefones.celular).substring(0, 2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.celular &&
				limparMascara(values.filho.genitor.telefones.celular).substring(
					0,
					2,
				)) ||
			'11',
		foneCelular:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.telefones?.celular &&
				limparMascara(values.responsavel.telefones.celular).substring(2)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.telefones?.celular &&
				limparMascara(values.filho.telefones.celular).substring(2)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.telefones?.celular &&
				limparMascara(values.filho.genitor.telefones.celular).substring(2)) ||
			'999999999',
		email:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.email?.toUpperCase()) ||
			(values?.tipoSolicitante === 1 && values?.filho?.email?.toUpperCase()) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.email?.toUpperCase()) ||
			'',
		autorizaEmail: true,
		autorizaSMS: false,
		origem: 'P',
		statusUpdateSenha: '',
		senha: null,
		businessCategory: '',
		presencial: 100,
		sexo:
			(values?.tipoSolicitante === 2 && values?.responsavel?.sexo) ||
			(values?.tipoSolicitante === 1 && values?.filho?.sexo) ||
			(values?.tipoSolicitante === 3 && values?.filho?.genitor?.sexo) ||
			'',
		orgao: 400,
		idCidadao: valuesEvento.id_cidadao,
		idAtendimento: valuesEvento.id_atendimento,
		endereco:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.endereco?.logradouro &&
				removeAcentoCedilha(
					values?.responsavel?.endereco?.logradouro.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.endereco?.logradouro &&
				removeAcentoCedilha(
					values?.filho?.endereco?.logradouro.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.logradouro &&
				removeAcentoCedilha(
					values?.filho?.genitor?.endereco?.logradouro.toUpperCase() || '',
				)) ||
			'',
		cep:
			(values?.tipoSolicitante === 2 && values?.responsavel?.endereco?.cep) ||
			(values?.tipoSolicitante === 1 && values?.filho?.endereco?.cep) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.cep) ||
			'',
		enderecoUf:
			(values?.tipoSolicitante === 2 && values?.responsavel?.endereco?.uf) ||
			(values?.tipoSolicitante === 1 && values?.filho?.endereco?.uf) ||
			(values?.tipoSolicitante === 3 && values?.filho?.genitor?.endereco?.uf) ||
			'',
		enderecoBairro:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.endereco?.bairro &&
				removeAcentoCedilha(
					values?.responsavel?.endereco?.bairro.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.endereco?.bairro &&
				removeAcentoCedilha(
					values?.filho?.endereco?.bairro.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.bairro &&
				removeAcentoCedilha(
					values?.filho?.genitor?.endereco?.bairro.toUpperCase() || '',
				)) ||
			'',
		enderecoCidade:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.endereco?.cidade &&
				removeAcentoCedilha(
					values?.responsavel?.endereco?.cidade.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.endereco?.cidade &&
				removeAcentoCedilha(
					values?.filho?.endereco?.cidade.toUpperCase() || '',
				)) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.cidade &&
				removeAcentoCedilha(
					values?.filho?.genitor?.endereco?.cidade.toUpperCase() || '',
				)) ||
			'',
		enderecoNum:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.endereco?.numero) ||
			(values?.tipoSolicitante === 1 && values?.filho?.endereco?.numero) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.numero) ||
			'',
		enderecoCompl:
			(values?.tipoSolicitante === 2 &&
				values?.responsavel?.endereco?.complemEndereco) ||
			(values?.tipoSolicitante === 1 &&
				values?.filho?.endereco?.complemEndereco) ||
			(values?.tipoSolicitante === 3 &&
				values?.filho?.genitor?.endereco?.complemEndereco) ||
			'',
	};
};
