export const ID_ORGAO_EDUCACAO = 569;
export const CADASTRO_INSCRICAO_ALUNO = 'educacao_inscricao_rede_publica';
export const CADASTRO_INSCRICAO_ALUNO_ID_SERVICO = '0005';
export const CADASTRO_INSCRICAO_ALUNO_DESCRICAO = 'inscricao rede publica';
export const ALTERA_INSCRICAO_ALUNO = 'educacao_altera_inscricao_rede_publica';
export const ALTERA_INSCRICAO_ALUNO_ID_SERVICO = '0008';
export const ALTERA_INSCRICAO_ALUNO_DESCRICAO = 'altera inscricao rede publica';
export const CANCELA_INSCRICAO_ALUNO =
	'educacao_cancela_inscricao_rede_publica';
export const CANCELA_INSCRICAO_ALUNO_ID_SERVICO = '0009';
export const CANCELA_INSCRICAO_ALUNO_DESCRICAO =
	'cancela inscricao rede publica';
export const CADASTRO_RESPONSAVEL_SOLICITACAO =
	'educacao_cadastro_responsavel_solicitacao';
export const CADASTRO_RESPONSAVEL_SOLICITACAO_ID_SERVICO = '0015';
export const CADASTRO_RESPONSAVEL_SOLICITACAO_DESCRICAO =
	'cadastro responsavel solicitacao';
export const EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO =
	'educacao_cadastro_responsavel_excluir_conclusao';
export const EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO = '0016';
export const EXCLUIR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO =
	'cadastro responsavel excluir conclusao';
export const EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO =
	'educacao_cadastro_responsavel_editar_conclusao';
export const EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO = '0017';
export const EDITAR_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO =
	'cadastro responsavel editar conclusao';
export const CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO =
	'educacao_cadastro_responsavel_conclusao';
export const CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_ID_SERVICO = '0018';
export const CADASTRO_RESPONSAVEL_SOLICITACAO_CONCLUSAO_DESCRICAO =
	'cadastro responsavel conclusao';
