import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_BCADASTRO_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { IRequestWithHeader, Types } from './types';

// ACTIONS
import {
	consultarBCadastroSuccess,
	consultarBCadastroFailure,
} from './actions';

function* consultarBCadastroRequest(request: ReducerActionRotaSP) {
	const { payload, token }: { payload: IRequestWithHeader; token?: string } =
		request;
	const { headers } = payload;

	const statusToValidate = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_BCADASTRO_API,
		`fisica/cpf/${payload.body.cpfidentificacao}`,
		{},
		undefined,
		undefined,
		undefined,
		headers,
		statusToValidate,
	);

	if (response.status === 200) {
		yield put(consultarBCadastroSuccess(response.data));
	} else {
		yield put(consultarBCadastroFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_BCADASTRO_REQUEST, consultarBCadastroRequest),
]);
