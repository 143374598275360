import React, { ChangeEvent, useCallback, useEffect, useContext } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
	calculoBoletoAgrupadoRequest,
	calculoBoletoAgrupadoClear,
} from 'store/modules/api/cdhu/gerarOcorrencia/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import CheckBox from 'components/Common/Form/Checkbox';
import InputMask from 'components/Common/Form/Input/InputMask';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';

// FORM
import { ApplicationState } from 'store';
import { addNotifications } from 'store/modules/app/notifications/actions';
import { IFormCalculo, initialValues, schema } from './form';

const Calculo: React.FC = () => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { consultarPrestacoesAtrasadas } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	useEffect(() => {
		dispatch(calculoBoletoAgrupadoClear());
	}, [dispatch]);

	const handleValidate = useCallback(
		(values: IFormCalculo) => {
			dispatch(calculoBoletoAgrupadoClear());

			return getValidationsErrors(values, schema);
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: IFormCalculo) => {
			if (consultarPrestacoesAtrasadas.data) {
				const { prestacoesAtField } = consultarPrestacoesAtrasadas.data;

				if (prestacoesAtField.length <= 0) {
					addNotifications({ errors: 'Não há parcelas atrasadas pendentes' });
				} else {
					dispatch(calculoBoletoAgrupadoRequest(token, values));
				}
			}
		},
		[dispatch, consultarPrestacoesAtrasadas, token],
	);

	return (
		<>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={initialValues}
				validate={handleValidate}
				onSubmit={handleSubmit}
			>
				{formik => (
					<>
						<Form autoComplete="off">
							<Row gutter={[0, 10]}>
								<Col span={24}>
									<Section size="sm" title="Cálculo das parcelas">
										<Row align="middle">
											<Col span={6}>
												<Row gutter={[0, 10]}>
													<Col span={24}>
														<Field
															as={CheckBox}
															name="prds"
															checked={formik.values.prds}
															subtitle="PRD's"
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																formik.setFieldValue('prds', e.target.checked);
															}}
															error={hasError(formik.errors, 'tipoParcela')}
														/>
													</Col>
												</Row>

												<Row>
													<Col span={24}>
														<Field
															as={CheckBox}
															name="parcelasEmAtraso"
															checked={formik.values.parcelasEmAtraso}
															subtitle="Parcelas em atraso"
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																formik.setFieldValue(
																	'parcelasEmAtraso',
																	e.target.checked,
																);
															}}
															error={hasError(formik.errors, 'tipoParcela')}
														/>
													</Col>
												</Row>
											</Col>

											<Col span={9}>
												<Field
													as={InputMask}
													title="Informe a data de vencimento"
													titleSize={190}
													name="dataVencimento"
													mask="99/99/9999"
													size={80}
													error={!!formik.errors.dataVencimento}
												/>
											</Col>

											<Col span={9}>
												<Row justify="center">
													<Col>
														<ButtonImage type="submit" src="calcular" />
													</Col>
												</Row>
											</Col>
										</Row>
									</Section>
								</Col>
							</Row>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default Calculo;
