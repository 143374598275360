// TYPES
import { SalvarAtendimento } from 'store/modules/api/sgu/atendimento/salvar/types';
import { IUser } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { enviarSolicitacaoMPData } from 'store/modules/api/mp/enviarSolicitacaoMP/types';
import { PreCadastroRequest } from 'store/modules/api/mp/preCadastro/types';
import { limparMascara } from 'utils/genericFunctions';

const incluirRequestPayloadSelectedFieldsAndGeneratedSolitacaoMP = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	preCadastro: PreCadastroRequest,
	enviaSolicitacaoMP: enviarSolicitacaoMPData,
) => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento:
			(preCadastro.data?.tipoSolicitante === 2 &&
				preCadastro.data?.responsavel?.cpf &&
				limparMascara(preCadastro.data.responsavel.cpf)) ||
			(preCadastro.data?.tipoSolicitante === 1 &&
				preCadastro.data?.filho?.cpf &&
				limparMascara(preCadastro.data.filho.cpf)) ||
			(preCadastro.data?.tipoSolicitante === 3 &&
				preCadastro.data?.filho?.genitor?.cpf &&
				limparMascara(preCadastro.data.filho.genitor.cpf)) ||
			'',
		numeroProcesso:
			dadosAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade: (user?.posto && Number(user.posto)) || 0,
		idOrgao: 3, // Órgão MP
		cpf: user.cpf, // cpf do atendente
		descricaoServico: 'SOLICITAÇÃO DE RECONHECIMENTO DE PATERNIDADE',
		idMotivo: 16,
		observacao: `PROTOCOLO GERADO: ${
			enviaSolicitacaoMP.protocolo || '00000000-0000-0000-0000-000000000000'
		}`,
	};

	return payload;
};

export const treatRequestSguStatisticsEnviaSolicitacaoMP = (
	user: IUser,
	dadosAtendimento: SalvarAtendimento,
	preCadastro: PreCadastroRequest,
	enviaSolicitacaoMP: enviarSolicitacaoMPData,
): IncluirRequest => {
	const getInfoPayload =
		incluirRequestPayloadSelectedFieldsAndGeneratedSolitacaoMP(
			user,
			dadosAtendimento,
			preCadastro,
			enviaSolicitacaoMP,
		);

	const payload: IncluirRequest = {
		...getInfoPayload,
	};

	return payload;
};
