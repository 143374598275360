export const errorCodesAllowedsConsultarRestricaoVeiculo = {
	code199: {
		statusResponse: 406,
		code: 199,
	},
	code463: {
		code: 463,
	},
};

export const idConsultaCategoria = {
	idOficialCategory: 3,
};
