import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';
import { EventosPPTContabilizacaoRequest } from 'store/modules/api/utils/eventosPPT/contabilizacao/types';
import { limparMascara } from 'utils/genericFunctions';

export const treatRequestEventsContabilizacaoPPT = (
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	cpfAtendimento: string,
	descOrgao: string,
	idOrgao: number,
	descServico: string,
	idServico?: number,
	tipoProcessoSessao?: string,
): EventosPPTContabilizacaoRequest => {
	const newValues: EventosPPTContabilizacaoRequest = {
		id_atendimento:
			atendimento?.salvarAtendimento?.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000',
		id_cidadao:
			atendimento?.salvarAtendimento?.data?.idCidadao ||
			'00000000-0000-0000-0000-000000000000',
		tipo: tipoProcessoSessao || '',
		data: new Date().getTime() || 0,
		cpf: (cpfAtendimento && limparMascara(cpfAtendimento)) || '',
		dados: {
			identificacao: loginUnico.user.cpf || '',
			id_orgao: idOrgao || 0,
			ip: loginUnico.login.user.ip || '',
			desc_localidade: loginUnico?.login?.user?.nomePosto || '',
			id_localidade: loginUnico.login.user.idLocalidade || 0,
			id_servico: idServico || 0,
			desc_servico: descServico || '',
			desc_orgao: descOrgao || '',
			cpf: (cpfAtendimento && limparMascara(cpfAtendimento)) || '',
		},
		canal: {
			id: loginUnico?.login?.user?.idCanal,
			desc: loginUnico?.login?.user?.descCanal,
		},
	};

	if (!cpfAtendimento) {
		delete newValues.cpf;
		delete newValues.dados.cpf;
	}

	return newValues;
};

export function treatRequestStartSessionEventsContabilizacaoPPT(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	cpfAtendimento: string,
	descOrgao: string,
	idOrgao: number,
	descServico: string,
	tipoProcessoSessao: string,
): EventosPPTContabilizacaoRequest {
	const defaultData = treatRequestEventsContabilizacaoPPT(
		atendimento,
		loginUnico,
		cpfAtendimento,
		descOrgao,
		idOrgao,
		descServico,
	);
	const { dados } = defaultData;

	const newValues = {
		...defaultData,
		tipo: tipoProcessoSessao,
		dados: {
			...dados,
			servicos: {
				data_inicio: new Date().getTime() || 0,
			},
		},
	};

	return newValues;
}

export function treatRequestEndSessionEventsContabilizacaoPPT(
	atendimento: AtendimentoState,
	loginUnico: ILoginUnicoState,
	cpfAtendimento: string,
	descOrgao: string,
	idOrgao: number,
	descServico: string,
	tipoProcessoSessao: string,
	dataInicioSessao?: number,
): EventosPPTContabilizacaoRequest {
	const defaultData = treatRequestEventsContabilizacaoPPT(
		atendimento,
		loginUnico,
		cpfAtendimento,
		descOrgao,
		idOrgao,
		descServico,
		dataInicioSessao,
	);
	const { dados } = defaultData;

	const newValues = {
		...defaultData,
		tipo: tipoProcessoSessao,
		dados: {
			...dados,
			servicos: {
				data_inicio: dataInicioSessao || 0,
				data_termino: new Date().getTime() || 0,
			},
		},
	};

	return newValues;
}
