import { all } from 'redux-saga/effects';

import listarSolicitacaoPortal from './listarSolicitacaoPortal/saga';
import enviarSolicitacoesPortal from './enviarSolicitacoesPortal/saga';
import atualizarSolicitacaoPortal from './atualizarSolicitacaoPortal/saga';
import enviarSolicitacaoMP from './enviarSolicitacaoMP/saga';
import impressaoTermoPaternidade from './impressaoTermoPaternidade/saga';
import impressaoGeraSenha from './impressaoGeraSenha/saga';
import eventoInicio from './eventoInicio/saga';
import eventoTermino from './eventoTermino/saga';

export default all([
	listarSolicitacaoPortal,
	enviarSolicitacoesPortal,
	atualizarSolicitacaoPortal,
	enviarSolicitacaoMP,
	impressaoTermoPaternidade,
	impressaoGeraSenha,
	eventoInicio,
	eventoTermino,
]);
