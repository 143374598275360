import React, {
	useCallback,
	useEffect,
	useState,
	useMemo,
	useContext,
} from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

import ColorBox from 'components/Common/ColorBox';
import Input from 'components/Common/Form/Input/Input';
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { enviarSolicitacaoMPRequest } from 'store/modules/api/mp/enviarSolicitacaoMP/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	impressaoTermoPaternidadeClear,
	impressaoTermoPaternidadeRequest,
} from 'store/modules/api/mp/impressaoTermoPaternidade/actions';
import { atualizarSolicitacaoPortalRequest } from 'store/modules/api/mp/atualizarSolicitacaoPortal/actions';
import { eventosPPTContabilizacaoRequest } from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { AtualizarPortalSolicitacaoRequest } from 'store/modules/api/mp/atualizarSolicitacaoPortal/types';

import { treatRequestSguStatisticsEnviaSolicitacaoMP } from 'pages/mp/utils/sguStatistics/treatServiceRequest';
import {
	treatRequestEndSessionEventsContabilizacaoPPT,
	treatRequestEventsContabilizacaoPPT,
} from 'utils/functions/treatEventosPPTRequest';

import { Container } from './styled';
import { treatSendMPRequest, SguData } from '../Forms/form';
import { formataSolicitaTermo } from '../utils';
import { treatDataUpdateRequestWithProtocol } from './form';

const Confirmacao = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { token } = useContext<IAuthContext>(AuthContext);

	const {
		enviarSolicitacoesPortal,
		enviarSolicitacaoMP,
		atualizarSolicitacaoPortal,
		preCadastro,
		impressaoTermoPaternidade,
		eventoInicio,
	} = useSelector((state: ApplicationState) => state.api.ministerioPublico);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { recuperarAtendimento, salvarAtendimento } = atendimento;
	const { login, user } = loginUnico;

	const sguData: SguData = useMemo(() => {
		return {
			recuperarAtendimento,
			salvarAtendimento,
			login,
			user,
		};
	}, [login, recuperarAtendimento, salvarAtendimento, user]);

	const cpfSolicitante =
		(preCadastro.data.tipoSolicitante === 1 && preCadastro.data.filho.cpf) ||
		(preCadastro.data.tipoSolicitante === 3 &&
			preCadastro.data.filho.genitor.cpf) ||
		(preCadastro.data.tipoSolicitante === 2 &&
			preCadastro.data.responsavel?.cpf) ||
		'';

	const [title, setTitle] = useState<string>('');
	const [solicitante, setSolicitante] = useState<string>('');
	const [solicitacaoId, setSolicitacaoId] = useState('');

	useEffect(() => {
		dispatch(impressaoTermoPaternidadeClear());
		dispatch(estatisticaIncluirClear());

		return () => {
			dispatch(impressaoTermoPaternidadeClear());
		};
	}, [dispatch]);

	useEffect(() => {
		if (
			(enviarSolicitacaoMP.status === 200 ||
				enviarSolicitacaoMP.status === 201) &&
			!eventosPPTContabilizacao.detailsEventRequest.historyExecutedEvents.includes(
				'protocolo_MP',
			)
		) {
			const treatedEventRequest = treatRequestEventsContabilizacaoPPT(
				atendimento,
				loginUnico,
				cpfSolicitante,
				'MPSP',
				549,
				'protocolo_MP',
				0,
				'protocolo_MP',
			);
			dispatch(eventosPPTContabilizacaoRequest(token, treatedEventRequest));
		}
	}, [
		atendimento,
		cpfSolicitante,
		dispatch,
		enviarSolicitacaoMP.status,
		eventosPPTContabilizacao.detailsEventRequest.historyExecutedEvents,
		loginUnico,
		token,
	]);

	useEffect(() => {
		if (
			enviarSolicitacaoMP.status === 200 ||
			enviarSolicitacaoMP.status === 201
		) {
			if (
				estatisticasIncluir.status !== 200 &&
				estatisticasIncluir.status !== 100
			) {
				const payload = treatRequestSguStatisticsEnviaSolicitacaoMP(
					login.user,
					dadosAtendimento,
					preCadastro,
					enviarSolicitacaoMP.data,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
			setTitle('Formulário enviado com sucesso.');
		} else if (
			atualizarSolicitacaoPortal.status === 200 ||
			atualizarSolicitacaoPortal.status === 201
		) {
			setTitle('Informações salvas com sucesso');
			setSolicitacaoId(atualizarSolicitacaoPortal.data?.id?.toString() || '');
		} else if (
			enviarSolicitacoesPortal.status === 200 ||
			enviarSolicitacoesPortal.status === 201
		) {
			setTitle('Informações salvas com sucesso');
			setSolicitacaoId(enviarSolicitacoesPortal.data?.idSolicitacao || '');
		}
	}, [
		enviarSolicitacaoMP,
		enviarSolicitacoesPortal,
		atualizarSolicitacaoPortal,
		sguData,
		estatisticasIncluir.status,
		dispatch,
		eventoInicio.detailsStartSection,
		login.user,
		dadosAtendimento,
		preCadastro,
	]);

	useEffect(() => {
		if (
			(atualizarSolicitacaoPortal.status === 200 ||
				atualizarSolicitacaoPortal.status === 201) &&
			atualizarSolicitacaoPortal.dataRequest?.protocolo
		) {
			if (
				!eventosPPTContabilizacao.detailsEventRequest.historyExecutedEvents.includes(
					'mpsp_solicitacao_reconhecimento_paternidade',
				)
			) {
				const treatedEventRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					cpfSolicitante,
					'MPSP',
					549,
					'solicitacao reconhecimento paternidade',
					1,
					'mpsp_solicitacao_reconhecimento_paternidade',
				);
				dispatch(eventosPPTContabilizacaoRequest(token, treatedEventRequest));
			}

			if (
				!eventosPPTContabilizacao.detailsEventRequest.historyExecutedEvents.includes(
					'termino_MP',
				)
			) {
				const treatedEndSessionRequest =
					treatRequestEndSessionEventsContabilizacaoPPT(
						atendimento,
						loginUnico,
						cpfSolicitante,
						'MPSP',
						549,
						'termino_MP',
						'termino_MP',
					);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEndSessionRequest),
				);
			}
		}
	}, [
		atendimento,
		atualizarSolicitacaoPortal.dataRequest,
		atualizarSolicitacaoPortal.status,
		cpfSolicitante,
		dispatch,
		eventosPPTContabilizacao.detailsEventRequest.historyExecutedEvents,
		loginUnico,
		token,
	]);

	useEffect(() => {
		if (preCadastro.data.tipoSolicitante === 1) {
			setSolicitante(preCadastro.data.filho.nome);
		} else if (preCadastro.data.tipoSolicitante === 3) {
			setSolicitante(preCadastro.data.filho.genitor.nome);
		} else if (
			preCadastro.data.tipoSolicitante === 2 &&
			preCadastro.data.responsavel &&
			preCadastro.data.responsavel.nome
		) {
			setSolicitante(preCadastro.data.responsavel.nome);
		}
	}, [
		preCadastro.data.filho.genitor.nome,
		preCadastro.data.filho.nome,
		preCadastro.data.responsavel,
		preCadastro.data.tipoSolicitante,
	]);

	useEffect(() => {
		if (
			enviarSolicitacaoMP.status === 200 ||
			enviarSolicitacaoMP.status === 201
		) {
			if (
				enviarSolicitacaoMP.data?.protocolo &&
				atualizarSolicitacaoPortal.dataRequest &&
				atualizarSolicitacaoPortal.dataRequest.idSolicitacao &&
				atualizarSolicitacaoPortal.dataRequest.protocolo === ''
			) {
				const updatedDataRequest: AtualizarPortalSolicitacaoRequest = {
					...atualizarSolicitacaoPortal.dataRequest,
					protocolo: enviarSolicitacaoMP.data?.protocolo,
					status: '2',
				};

				dispatch(
					atualizarSolicitacaoPortalRequest(
						token,
						updatedDataRequest,
						Number(updatedDataRequest.idSolicitacao),
					),
				);
			} else if (
				enviarSolicitacaoMP.data?.protocolo &&
				enviarSolicitacoesPortal.data?.idSolicitacao &&
				!atualizarSolicitacaoPortal.dataRequest
			) {
				const { idSolicitacao } = enviarSolicitacoesPortal.data;
				const { protocolo } = enviarSolicitacaoMP.data;

				const dataRequest = treatDataUpdateRequestWithProtocol(
					preCadastro.data,
					sguData,
					protocolo,
					idSolicitacao,
				);

				dispatch(
					atualizarSolicitacaoPortalRequest(
						token,
						dataRequest,
						Number(dataRequest.idSolicitacao),
					),
				);
			}
		}
	}, [
		preCadastro.data,
		atualizarSolicitacaoPortal.dataRequest,
		enviarSolicitacoesPortal.data,
		dispatch,
		enviarSolicitacaoMP,
		token,
		sguData,
	]);

	const handleEnviaMP = useCallback(async () => {
		const newBody = treatSendMPRequest(
			preCadastro.data,
			preCadastro.data.tipoSolicitante,
			sguData,
			solicitacaoId,
		);

		dispatch(enviarSolicitacaoMPRequest(token, newBody));
	}, [dispatch, preCadastro.data, sguData, solicitacaoId, token]);

	return (
		<Container>
			<Row gutter={[15, 0]} align="middle" justify="center">
				<Col span={14}>
					<ColorBox bgColor="verde" title successTitle={title}>
						<Row>
							<Col span={24} style={{ marginBottom: '20px' }}>
								<Row align="middle" justify="start">
									<Col>
										<Input
											name="nrSolicitacao"
											readOnly
											value={
												enviarSolicitacaoMP.data?.protocolo
													? enviarSolicitacaoMP.data.protocolo
													: ''
											}
											title="Nº Solicitação"
											titleAlign="start"
											titleSize="sm"
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<Input
											name="solicitante"
											readOnly
											value={solicitante}
											title="Solicitante"
											titleAlign="start"
											titleSize="sm"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row justify="center">
							<Col style={{ textAlign: 'center' }}>
								<ButtonImage
									src="enviar-mp"
									onClick={() => handleEnviaMP()}
									disabled={
										enviarSolicitacaoMP.status === 200 ||
										enviarSolicitacaoMP.status === 201
									}
								/>
							</Col>
							<Col offset={1}>
								<ButtonImage
									src="imprimir"
									onClick={async () => {
										const numeroTermo: string | null = enviarSolicitacaoMP.data
											.protocolo
											? enviarSolicitacaoMP.data.protocolo
											: null;
										const payload = await formataSolicitaTermo(
											preCadastro.data,
											login.user.nomePosto,
											Number(numeroTermo),
										);
										dispatch(impressaoTermoPaternidadeRequest(payload));
									}}
								/>
							</Col>
							<Col offset={1}>
								<ButtonImage
									type="button"
									src="finalizar-processo"
									onClick={() => history.push('/ministerio-publico')}
								/>
							</Col>
						</Row>
					</ColorBox>
				</Col>
			</Row>
			{impressaoTermoPaternidade.status === 200 &&
				impressaoTermoPaternidade.data?.arquivoPDF &&
				impressaoTermoPaternidade.data.arquivoPDF !== '' && (
					<PDFViewer
						title="Termo de Paternidade"
						source={impressaoTermoPaternidade.data.arquivoPDF}
						renderMode="popup"
						onClose={() => dispatch(impressaoTermoPaternidadeClear())}
					/>
				)}
		</Container>
	);
};

export default Confirmacao;
