import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, Form, Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { gerarOcorrenciaRequest } from 'store/modules/api/cdhu/gerarOcorrencia/actions';
import data from 'store/modules/api/cdhu/enum';
import { simularEfetuarAcordoRequest } from 'store/modules/api/cdhu/simularEfetuarAcordo/actions';
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import {
	eventosPPTContabilizacaoClear,
	eventosPPTContabilizacaoRequest,
} from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// PATHS
import {
	ROUTE_CDHU_SERVICOS_ACORDO,
	ROUTE_CDHU_SERVICOS_BOLETO_AGRUPADO,
	ROUTE_CDHU_SERVICOS_SUCESSO,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import Select, { OptionProps } from 'components/Common/Form/Select';
import TextArea from 'components/Common/Form/Input/TextArea';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import hasError from 'utils/getFormErrors';
import { FormikObserver } from 'utils/formikObserver';
import { DESC_ORGAO, ID_ORGAO_CDHU } from '../utils/tiposEventos';
import { treatRequestSguResponseEventsCDHU } from '../utils/functions/sguStatistics/treatServiceRequest';

// COMPONENTS
import DadosMutuario from './components/DadosMutuario';
import SituacaoContrato from './components/SituacaoContrato';

// FORM
import {
	getEnumInfoEventsPPT,
	IFormServicosMutuario,
	initialValues,
	schema,
	treatValues,
} from './form';

import { IFormDadosSolicitante } from '../form';

const Servicos: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const historyState = history.location.state as IFormDadosSolicitante;

	const {
		consultarMutuario,
		consultarSolicitante,
		gerarOcorrencia,
		simularEfetuarAcordo,
	} = useSelector((state: ApplicationState) => state.api.cdhu);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const [hasSubmit, setHasSubmit] = useState<boolean>(false);
	const [optionsSubservico, setOptionsSubservico] = useState<OptionProps[]>([]);

	useEffect(() => {
		if (
			hasSubmit &&
			gerarOcorrencia.status === 200 &&
			gerarOcorrencia.data &&
			gerarOcorrencia.form &&
			consultarMutuario.data
		) {
			const { ocorrenciaId } = gerarOcorrencia.data;
			const { servicoEnum } = gerarOcorrencia.form;
			const { numeroContrato } = consultarMutuario.data;

			if (servicoEnum === 'ACORDO') {
				setHasSubmit(false);
				dispatch(
					simularEfetuarAcordoRequest(token, {
						servicoEnum: 'SIMULAR_ACORDO',
						ocorrenciaId,
						contratoCDHU: Number(numeroContrato),
						tipoSimulacao: 1,
					}),
				);
				return;
			}

			if (servicoEnum === 'BOLETO_AGRUPADO') {
				dispatch(eventosPPTContabilizacaoClear());
				history.push(ROUTE_CDHU_SERVICOS_BOLETO_AGRUPADO);
			}
		}
	}, [hasSubmit, gerarOcorrencia, consultarMutuario, dispatch, history, token]);

	useEffect(() => {
		if (simularEfetuarAcordo.status === 200) {
			dispatch(eventosPPTContabilizacaoClear());
			history.push(ROUTE_CDHU_SERVICOS_ACORDO);
		}
	}, [simularEfetuarAcordo, history, dispatch]);

	useEffect(() => {
		if (
			gerarOcorrencia.status === 200 &&
			gerarOcorrencia.form?.servicoEnum &&
			gerarOcorrencia.form.servicoEnum !== 'ACORDO' &&
			gerarOcorrencia.form.servicoEnum !== 'BOLETO_AGRUPADO'
		) {
			const dataEventServicoEnum = getEnumInfoEventsPPT(
				gerarOcorrencia.form.servicoEnum,
			);

			if (eventosPPTContabilizacao.status === 0 && dataEventServicoEnum) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					consultarMutuario.data?.cpf || '',
					DESC_ORGAO,
					ID_ORGAO_CDHU,
					dataEventServicoEnum.DESC,
					dataEventServicoEnum.ID_SERVICO,
					dataEventServicoEnum.TIPO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		}
	}, [
		gerarOcorrencia.status,
		gerarOcorrencia.form,
		eventosPPTContabilizacao.status,
		atendimento,
		loginUnico,
		consultarMutuario.data,
		dispatch,
		token,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status === 0 &&
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100 &&
			gerarOcorrencia.form?.servicoEnum &&
			gerarOcorrencia.form.servicoEnum !== 'ACORDO' &&
			gerarOcorrencia.form.servicoEnum !== 'BOLETO_AGRUPADO'
		) {
			const dataEventServicoEnum = getEnumInfoEventsPPT(
				gerarOcorrencia.form.servicoEnum,
			);

			if (dataEventServicoEnum) {
				const payload = treatRequestSguResponseEventsCDHU(
					loginUnico,
					atendimento,
					consultarMutuario.data?.cpf || '',
					eventosPPTContabilizacao.status === 200,
					dataEventServicoEnum.DESC,
					dataEventServicoEnum.TIPO,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		dispatch,
		atendimento,
		loginUnico,
		gerarOcorrencia.form,
		consultarMutuario.data,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100
		) {
			history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
		}
	}, [estatisticasIncluir.status, history]);

	const handleFormChange = useCallback((values: IFormServicosMutuario) => {
		const { servico } = values;

		if (servico === 'transfTitularidade') {
			setOptionsSubservico(data.subservicos.transferenciaTitularidade);
			return;
		}

		if (servico === 'seguroHabitacional') {
			setOptionsSubservico(data.subservicos.seguroHabitacional);
			return;
		}

		if (servico === 'inscricoesProgHabit') {
			setOptionsSubservico(data.subservicos.inscricoesProgHabit);
			return;
		}

		if (servico === 'situacaoFinanceira') {
			setOptionsSubservico(data.subservicos.situacaoFinanceira);
			return;
		}

		if (servico === 'saldoDevedor') {
			setOptionsSubservico(data.subservicos.saldoDevedor);
		}
	}, []);

	const handleValidate = useCallback((formValues: IFormServicosMutuario) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleSubmit = useCallback(
		(formValues: IFormServicosMutuario) => {
			if (consultarMutuario.data && consultarSolicitante.tipoVinculo) {
				const values = treatValues(
					formValues,
					historyState,
					consultarMutuario.data,
					consultarSolicitante.data,
					consultarSolicitante.tipoVinculo,
				);

				dispatch(gerarOcorrenciaRequest(token, values));
				setHasSubmit(true);
			}
		},
		[
			consultarMutuario.data,
			consultarSolicitante.data,
			consultarSolicitante.tipoVinculo,
			dispatch,
			historyState,
			token,
		],
	);

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	return (
		<>
			{consultarMutuario.data && (
				<>
					<DadosMutuario />

					<SituacaoContrato />

					<Section size="lg" title="Serviços disponíveis" titleSize="sm">
						<Row>
							<Col span={24}>
								<Formik
									validateOnChange={false}
									validateOnBlur={false}
									initialValues={initialValues}
									validate={handleValidate}
									onSubmit={handleSubmit}
								>
									{formik => (
										<>
											<Form autoComplete="off">
												<Row align="middle">
													<Col span={12}>
														<Row
															gutter={
																formik.values.servico === '' ||
																formik.values.servico === 'acordo'
																	? undefined
																	: [0, 10]
															}
														>
															<Col span={24}>
																<Field
																	as={Select}
																	title="Serviço"
																	titleSize="sm"
																	name="servico"
																	options={data.servicos}
																	onChange={(v: string) => {
																		formik.setFieldValue('servico', v);
																		formik.setFieldValue(
																			'motivoBoletoAgrupado',
																			'',
																		);
																		formik.setFieldValue('subservico', '');
																		formik.setFieldValue('motivo', '');
																	}}
																	required
																	error={!!formik.errors.servico}
																/>
															</Col>
														</Row>

														{formik.values.servico === 'boletoAgrupado' && (
															<Row>
																<Col span={24}>
																	<Field
																		as={Select}
																		title="Motivo"
																		titleSize="sm"
																		name="motivoBoletoAgrupado"
																		options={data.motivosBoletoAgrupado}
																		onChange={(v: string) => {
																			formik.setFieldValue(
																				'motivoBoletoAgrupado',
																				v,
																			);
																		}}
																		required
																		error={hasError(
																			formik.errors,
																			'boletoAgrupado',
																		)}
																	/>
																</Col>
															</Row>
														)}

														{(formik.values.servico === 'transfTitularidade' ||
															formik.values.servico === 'seguroHabitacional' ||
															formik.values.servico === 'inscricoesProgHabit' ||
															formik.values.servico === 'situacaoFinanceira' ||
															formik.values.servico === 'saldoDevedor') && (
															<Row gutter={[0, 10]}>
																<Col span={24}>
																	<Field
																		as={Select}
																		title="Subserviço"
																		titleSize="sm"
																		name="subservico"
																		options={optionsSubservico}
																		onChange={(v: string) => {
																			formik.setFieldValue('subservico', v);
																		}}
																		required
																		error={!!formik.errors.subservico}
																	/>
																</Col>
															</Row>
														)}
													</Col>

													<Col offset={5}>
														<ButtonImage type="submit" src="prosseguir" />
													</Col>
												</Row>

												{(formik.values.servico === 'transfTitularidade' ||
													formik.values.servico === 'seguroHabitacional' ||
													formik.values.servico === 'inscricoesProgHabit' ||
													formik.values.servico === 'situacaoFinanceira' ||
													formik.values.servico === 'saldoDevedor') && (
													<Row>
														<Col span={24}>
															<Field
																as={TextArea}
																title="Motivo"
																titleSize="sm"
																name="motivo"
																required
																error={!!formik.errors.motivo}
															/>
														</Col>
													</Row>
												)}
											</Form>

											<FormikObserver
												dirty={formik.dirty}
												values={formik.values}
												onChange={handleFormChange}
											/>
										</>
									)}
								</Formik>
							</Col>
						</Row>
					</Section>

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</>
			)}
		</>
	);
};

export default Servicos;
