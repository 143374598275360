export enum Types {
	LISTAR_SOLICITACAO_PORTAL_REQUEST = '@MP/LISTAR_SOLICITACAO_PORTAL_REQUEST',
	LISTAR_SOLICITACAO_PORTAL_SUCCESS = '@MP/LISTAR_SOLICITACAO_PORTAL_SUCCESS',
	LISTAR_SOLICITACAO_PORTAL_FAILURE = '@MP/LISTAR_SOLICITACAO_PORTAL_FAILURE',
	LISTAR_SOLICITACAO_PORTAL_CLEAR = '@MP/LISTAR_SOLICITACAO_PORTAL_CLEAR',
}

export interface listarSolicitacaoPortal {
	status: number;
	data: ListarSolicitacaoPortalData[];
}

export interface ListarSolicitacaoPortalRequest {
	cpf: string;
}

export interface ListarSolicitacaoPortalData {
	id: number;
	filho: {
		nome: string;
		dataNascimento: string;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
		genitor: {
			nome: string;
			dataNascimento: string;
			cpf: string;
			email: string;
			sexo: string;
			endereco: {
				bairro: string;
				cep: string;
				cidade: string;
				complementoEndereco: string;
				logradouro: string;
				numero: string;
				uf: string;
			};
			rg: {
				numero: string;
				digito: string;
				uf: string;
			};
			telefones: [
				{
					ddd: string;
					numero: string;
					tipo: string;
				},
			];
			nomeMae: string;
			cidadeNascimento: string;
			ufNascimento: string;
			outrasInformacoes: string;
		};
	};
	responsavel: {
		nome: string;
		dataNascimento: string;
		cpf: string;
		email: string;
		sexo: string;
		endereco: {
			bairro: string;
			cep: string;
			cidade: string;
			complementoEndereco: string;
			logradouro: string;
			numero: string;
			uf: string;
		};
		rg: {
			numero: string;
			digito: string;
			uf: string;
		};
		telefones: [
			{
				ddd: string;
				numero: string;
				tipo: number;
			},
		];
	};
	destinatarioSolicitacao: string;
	aceiteTermo: boolean;
	aceiteTermoDeclaracaoPobreza: boolean;
	aceiteAcrescNomePai: boolean;
	idAtendente: string;
	dataCriacao: string;
	dataModificacao: string;
	status: string;
	origem: string;
	protocolo: string;
	idCidadao: string;
	idAtendimento: string;
	emailContato: string;
	idPosto: number;
	nomePosto: string;
}
