import React, { useState, useEffect, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { Col, Row } from 'antd';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUCER
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';

// COMBOS
import { escolasProximasRequest } from 'store/modules/api/educacaoUpdate/combos/escolasProximas/actions';

// REQUESTS
import {
	cadastrarInscricaoRequest,
	cadastrarInscricaoClear,
} from 'store/modules/api/educacaoUpdate/inscricao/cadastrarInscricao/actions';
import { comprovanteInscricaoRequest } from 'store/modules/api/educacaoUpdate/inscricao/comprovanteInscricao/actions';

import { cadastrarFichaAlunoClear } from 'store/modules/api/educacaoUpdate/fichaAluno/cadastrarFichaAluno/actions';
import { alterarFichaAlunoClear } from 'store/modules/api/educacaoUpdate/fichaAluno/alterarFichaAluno/actions';
import {
	baixarArquivosClear,
	baixarArquivosRequest,
} from 'store/modules/api/utils/arquivos/baixarArquivos/actions';
import {
	eventosPPTContabilizacaoClear,
	eventosPPTContabilizacaoRequest,
} from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';

// ROUTES
import { useHistory } from 'react-router-dom';
import { ROUTE_SECRETARIA_EDUCACAO } from 'routes/paths';
import { ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO } from 'pages/EducacaoUpdate/routes/paths';

// COMPONENTS
import Alert from 'components/Common/Notifications/Alert';
import Button from 'components/Common/Button';
import ButtonImage from 'components/Common/ButtonImage';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import CheckBox from 'components/Common/Form/Checkbox';
import InputMask from 'components/Common/Form/Input/InputMask';
import hasError from 'utils/getFormErrors';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';
import { blobFileToLinkClick } from 'utils/functions/treatResponse';
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import { treatRequestSguEducacaoResponseEvents } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';

// FORM
import {
	CADASTRO_INSCRICAO_ALUNO,
	CADASTRO_INSCRICAO_ALUNO_ID_SERVICO,
	CADASTRO_INSCRICAO_ALUNO_DESCRICAO,
	ID_ORGAO_EDUCACAO,
} from 'pages/EducacaoUpdate/tiposEventos';
import {
	formatValuesUpdate,
	initialValues,
	InscricaoAlunoForm,
	schema,
} from './form';

// STYLES
import {
	ButtonDownloadComprovante,
	ContainerFichaCadastral,
	ContainerSelect,
} from './styled';

interface DataReqImpressaoComprovante {
	numeroRA: string;
	digitoRA: string;
	uf: string;
	anoLetivo: number;
	codigoTipoEnsino: string | number;
}

const InscricaoAluno: React.FC = () => {
	const { token } = useContext<IAuthContext>(AuthContext);
	const [dataReqImpComprovante, setDataReqImpComprovante] =
		useState<DataReqImpressaoComprovante>();
	const [dataFasesInscricao, setDataFasesInscricao] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const [dataRedesEnsino, setDataRedesEnsino] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const [dataOpcoesNoturno, setDataOpcoesNoturno] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const [dataSeriesAnos, setDataSeriesAnos] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const [dataCodigoEscolaRequest, setDataCodigoEscolaRequest] =
		useState<string>('');

	const [dataCodigoUnidadeRequest, setDataCodigoUnidadeRequest] =
		useState<string>('');

	const [dataEnsinoTecProfissionalizante, setDataEnsinoTecProfissionalizante] =
		useState<Array<{ value: string; label: string }>>([]);

	const [dataTipoEnsino, setDataTipoEnsino] = useState<
		Array<{
			value: string;
			label: string;
			index: number;
			seriesAnos: Array<{ value: string; label: string }>;
		}>
	>([]);

	const [inscricaoEfetuada, setInscricaoEfetuada] = useState(false);

	const tiposEnsino = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.tiposEnsino,
	);

	const eixo = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.eixo,
	);

	const { cadastrarInscricao, comprovanteInscricao } = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.inscricao,
	);

	const { consultaFichaAluno, cadastrarFichaAluno, alterarFichaAluno } =
		useSelector(
			(state: ApplicationState) => state.api.educacaoUpdate.fichaAluno,
		);

	const { latlng } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.cadastrarFichaAluno,
	);

	const dadosBasicos = useSelector(
		(state: ApplicationState) => state.api.educacaoUpdate.combos.dadosBasicos,
	);

	const escolas = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.combos.escolasProximas.data,
	);

	const { baixarArquivos } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { data: dataSalvarAtendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (latlng) {
			dispatch(
				escolasProximasRequest(
					{
						latitude: latlng.lat?.toString()?.substring(0, 15) || '',
						longitude: latlng.lng?.toString()?.substring(0, 15) || '',
					},
					token,
				),
			);
		}
	}, [dispatch, latlng, token]);

	useEffect(() => {
		if (escolas?.codigoEscola) {
			const codigoEscolaEnvio = String(escolas.codigoEscola);
			// const unidadeEscolaEnvio = escolas.codigoUnidade;
			const unidadeEscolaEnvio = '0';
			setDataCodigoUnidadeRequest(unidadeEscolaEnvio);
			setDataCodigoEscolaRequest(codigoEscolaEnvio);
		}
	}, [escolas]);

	useEffect(() => {
		const listaFasesInscricao: Array<{ value: string; label: string }> = [];
		dadosBasicos?.data?.fasesInscricao?.map(
			(item: { codigo: string; descricao: string }) => {
				return listaFasesInscricao.push({
					value: String(item.codigo),
					label: item.descricao,
				});
			},
		);
		setDataFasesInscricao(listaFasesInscricao);
	}, [dadosBasicos]);

	useEffect(() => {
		const listaRedesEnsino: Array<{ value: string; label: string }> = [];
		dadosBasicos.data?.redesEnsino?.map(
			(item: { codigo: string; descricao: string }) => {
				return listaRedesEnsino.push({
					value: String(item.codigo),
					label: item.descricao,
				});
			},
		);
		setDataRedesEnsino(listaRedesEnsino);
	}, [dadosBasicos]);

	useEffect(() => {
		const listaOpcoesNoturno: Array<{ value: string; label: string }> = [];
		dadosBasicos.data?.opcoesNoturno?.map(
			(item: { codigo: string; descricao: string }) => {
				return listaOpcoesNoturno.push({
					value: String(item.codigo),
					label: item.descricao,
				});
			},
		);
		setDataOpcoesNoturno(listaOpcoesNoturno);
	}, [dadosBasicos]);

	useEffect(() => {
		const listaTipoEnsino: Array<{
			value: string;
			label: string;
			index: number;
			seriesAnos: Array<{
				value: string;
				label: string;
			}>;
		}> = [];
		tiposEnsino.data?.tipoEnsino?.map((item: any, index: number) => {
			return listaTipoEnsino.push({
				value: item.codigo,
				label: item.descricao,
				index,
				seriesAnos: item.serieAno,
			});
		});
		setDataTipoEnsino(listaTipoEnsino);
	}, [tiposEnsino]);

	useEffect(() => {
		const listaEducacaoTecProfissionalizante: Array<{
			value: string;
			label: string;
		}> = [];
		eixo.data?.eixos?.map((item: { codigo: string; descricao: string }) => {
			return listaEducacaoTecProfissionalizante.push({
				value: String(item.codigo),
				label: item.descricao,
			});
		});
		setDataEnsinoTecProfissionalizante(listaEducacaoTecProfissionalizante);
	}, [eixo]);

	const handleSubmit = (values: InscricaoAlunoForm) => {
		const reformataDataAlunoPesquisa = consultaFichaAluno.data?.dadosPessoais;
		const dataFichaAluno = cadastrarFichaAluno.data?.aluno;
		const aluno = {
			digitoRA:
				reformataDataAlunoPesquisa?.digitoRA || dataFichaAluno?.digitoRA || '',
			numeroRA:
				reformataDataAlunoPesquisa?.numRA || dataFichaAluno?.numRA || '',
			siglaUFRA:
				reformataDataAlunoPesquisa?.siglaUFRA ||
				dataFichaAluno?.siglaUFRA ||
				'',
		};

		const inscricao = {
			codigoEscola: dataCodigoEscolaRequest,
			codigoUnidade: dataCodigoUnidadeRequest,
		};

		const valuesFormatadosparaEnvio = formatValuesUpdate(
			values,
			aluno,
			inscricao,
		);

		dispatch(cadastrarInscricaoRequest(token, valuesFormatadosparaEnvio));

		if (
			valuesFormatadosparaEnvio.aluno.numeroRA &&
			valuesFormatadosparaEnvio.aluno.digitoRA &&
			valuesFormatadosparaEnvio.aluno.siglaUFRA
		) {
			setDataReqImpComprovante({
				numeroRA: valuesFormatadosparaEnvio.aluno.numeroRA,
				digitoRA: valuesFormatadosparaEnvio.aluno.digitoRA,
				uf: valuesFormatadosparaEnvio.aluno.siglaUFRA,
				anoLetivo: Number(valuesFormatadosparaEnvio.inscricao.anoLetivo) || 0,
				codigoTipoEnsino: valuesFormatadosparaEnvio.inscricao.fase,
			});
		}
	};

	useEffect(() => {
		if (cadastrarInscricao.status === 201) {
			if (dataReqImpComprovante && !inscricaoEfetuada) {
				const dataComprovanteRequest = {
					numeroRA: dataReqImpComprovante.numeroRA?.trim() || '',
					digitoRA: dataReqImpComprovante.digitoRA?.trim() || '',
					siglaUfRA: dataReqImpComprovante.uf?.trim() || '',
					anoLetivo: dataReqImpComprovante.anoLetivo || 0,
					tipoInscricao:
						dataReqImpComprovante.codigoTipoEnsino?.toString()?.trim() || '',
				};
				dispatch(comprovanteInscricaoRequest(token, dataComprovanteRequest));
				setInscricaoEfetuada(true);
			}

			if (eventosPPTContabilizacao.status === 0) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					cadastrarFichaAluno.dataRequest?.documentos?.cpf ||
						alterarFichaAluno.dataRequest?.documentos?.cpf ||
						'',
					'EDUCAÇÃO',
					ID_ORGAO_EDUCACAO,
					CADASTRO_INSCRICAO_ALUNO_DESCRICAO,
					Number(CADASTRO_INSCRICAO_ALUNO_ID_SERVICO),
					CADASTRO_INSCRICAO_ALUNO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		}
	}, [
		cadastrarInscricao,
		dataReqImpComprovante,
		dispatch,
		token,
		eventosPPTContabilizacao.status,
		atendimento,
		loginUnico,
		cadastrarFichaAluno.dataRequest,
		alterarFichaAluno.dataRequest,
		inscricaoEfetuada,
	]);

	useEffect(() => {
		if (
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100
		) {
			if (estatisticasIncluir.status === 0) {
				const payload = treatRequestSguEducacaoResponseEvents(
					loginUnico,
					atendimento,
					dataSalvarAtendimento?.cpf || '',
					eventosPPTContabilizacao.status === 200,
					CADASTRO_INSCRICAO_ALUNO,
					Number(CADASTRO_INSCRICAO_ALUNO_ID_SERVICO),
					CADASTRO_INSCRICAO_ALUNO_DESCRICAO,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		dataSalvarAtendimento,
		dispatch,
		atendimento,
		loginUnico,
	]);

	const handleDownloadArchive = (url: string) => {
		if (url) {
			dispatch(
				baixarArquivosRequest(token, {
					url,
				}),
			);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (
				baixarArquivos.status === 200 &&
				baixarArquivos.data?.arquivo &&
				comprovanteInscricao.dataRequest
			) {
				const archiveName = `comprovante_inscricao_${
					comprovanteInscricao.dataRequest.numeroRA?.trim() || ''
				}_${comprovanteInscricao.dataRequest.siglaUfRA?.trim() || ''}`;
				blobFileToLinkClick(baixarArquivos.data?.arquivo, archiveName, 'pdf');
			}
		}, 400);
	}, [dispatch, baixarArquivos, comprovanteInscricao]);

	const handleChangeTipoEnsino = (v: string) => {
		const listaSeriesAnos: Array<{ value: string; label: string }> = [];
		dataTipoEnsino.filter(nome => {
			if (nome.value === v) {
				nome.seriesAnos?.map((item: any) => {
					return listaSeriesAnos.push({
						value: String(item.codigo),
						label: item.descricao,
					});
				});
			}
			return null;
		});
		setDataSeriesAnos(listaSeriesAnos);
	};

	return (
		<>
			<Section title="Inscrição Aluno">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={async values => handleSubmit(values)}
				>
					{formik => (
						<ContainerFichaCadastral>
							<Form autoComplete="off">
								{inscricaoEfetuada && (
									<Row gutter={[0, 20]}>
										<Col span={24}>
											<Alert
												type="info"
												message={<p>Inscrição efetuada com sucesso</p>}
												// onClose={() => setInscricaoEfetuada(false)}
											/>
										</Col>
									</Row>
								)}
								<ContainerSelect>
									<Row gutter={[0, 10]}>
										<Col span={20}>
											<Field
												as={InputMask}
												title="Ano Letivo"
												name="inscricao.anoLetivo"
												mask="9999"
												// onChange={(v: string) => {
												// 	formik.setFieldValue('inscricao.anoLetivo', v);
												// }}
												error={hasError(formik.errors, 'inscricao.anoLetivo')}
												disabled={inscricaoEfetuada}
											/>
										</Col>
									</Row>
									<Row gutter={[0, 10]}>
										<Col span={20}>
											<Field
												as={Select}
												title="Fase"
												name="inscricao.fase"
												options={dataFasesInscricao}
												onChange={(v: string) => {
													const [result] = dataFasesInscricao.filter(codigo => {
														if (codigo.value === v) {
															return codigo.value;
														}
														return null;
													});
													formik.setFieldValue(
														'inscricao.fase',
														result?.label || '',
													);
													formik.setFieldValue(
														'inscricao.fasesInscricao',
														result?.value || '',
													);
												}}
												error={hasError(formik.errors, 'inscricao.fase')}
												disabled={inscricaoEfetuada}
											/>
										</Col>
									</Row>
									{formik.values.inscricao.fasesInscricao && (
										<Row gutter={[0, 10]}>
											<Col span={20}>
												<Field
													as={Select}
													title="Rede de Ensino"
													name="inscricao.tipoEscola"
													options={dataRedesEnsino}
													onChange={(v: string) => {
														const [result] = dataRedesEnsino.filter(codigo => {
															if (codigo.value === v) {
																return codigo.value;
															}
															return null;
														});
														formik.setFieldValue(
															'inscricao.tipoEscola',
															result?.label || '',
														);
														formik.setFieldValue(
															'inscricao.codigoEscola',
															result?.value || '',
														);
													}}
													error={hasError(
														formik.errors,
														'inscricao.tipoEscola',
													)}
													disabled={inscricaoEfetuada}
												/>
											</Col>
										</Row>
									)}
									{formik.values.inscricao.fasesInscricao &&
										formik.values.inscricao.tipoEscola && (
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={Select}
														title="Tipo de Ensino"
														name="inscricao.tipoEnsino.codigo"
														options={dataTipoEnsino}
														onChange={(v: string) => {
															handleChangeTipoEnsino(v);
															formik.setFieldValue(
																'nivelEnsino.codigoTipoEnsino',
																v,
															);
														}}
														error={hasError(
															formik.errors,
															'inscricao.tipoEnsino.codigo',
														)}
														disabled={inscricaoEfetuada}
													/>
												</Col>
											</Row>
										)}
									{formik.values.inscricao.fasesInscricao &&
										formik.values.inscricao.tipoEscola &&
										formik.values.nivelEnsino.codigoTipoEnsino && (
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={Select}
														title="Serie/Ano"
														name="inscricao.inscricao.seriesAnos.descricao"
														options={dataSeriesAnos}
														onChange={(v: string) => {
															formik.setFieldValue(
																'nivelEnsino.codigoSerieAno',
																v,
															);
														}}
														error={hasError(
															formik.errors,
															'inscricao.inscricao.seriesAnos.descricao',
														)}
														disabled={inscricaoEfetuada}
													/>
												</Col>
											</Row>
										)}
								</ContainerSelect>
								{formik.values.inscricao.fasesInscricao &&
									formik.values.inscricao.tipoEscola &&
									formik.values.nivelEnsino.codigoTipoEnsino &&
									(formik.values.nivelEnsino.codigoSerieAno ||
										formik.values.nivelEnsino.codigoSerieAno === 0) && (
										<>
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={CheckBox}
														subtitle="Turno Integral:"
														name="inscricao.interesseIntegral"
														checked={formik.values.inscricao.interesseIntegral}
														error={hasError(
															formik.errors,
															'inscricao.interesseIntegral',
														)}
														onChange={(v: any) => {
															if (v.target.checked) {
																formik.setFieldValue(
																	'inscricao.espanhol',
																	false,
																);
																formik.setFieldValue(
																	'inscricao.atendimentoNoturno',
																	false,
																);
															}

															formik.setFieldValue(
																'inscricao.interesseIntegral',
																v.target.checked,
															);
														}}
														disabled={inscricaoEfetuada}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={CheckBox}
														subtitle="Ensino Técnico Profissionalizante:"
														name="inscricao.ensinoTecProfissionalizante"
														checked={
															formik.values.inscricao
																.ensinoTecProfissionalizante
														}
														error={hasError(
															formik.errors,
															'inscricao.ensinoTecProfissionalizante',
														)}
														disabled={inscricaoEfetuada}
													/>
												</Col>
											</Row>
											{formik.values.inscricao.ensinoTecProfissionalizante && (
												<>
													<Row gutter={[0, 10]}>
														<Col span={20}>
															<Field
																as={Select}
																title="Ensino Técnico Profissionalizante (Opção 1)"
																name="inscricao.ensinoTecProfissionalizante1"
																options={dataEnsinoTecProfissionalizante}
																onChange={(v: string) => {
																	// handleChangeEnsinoTec1(v);
																	formik.setFieldValue(
																		'inscricao.ensinoTecProfissionalizante1',
																		v,
																	);
																}}
																error={hasError(
																	formik.errors,
																	'inscricao.ensinoTecProfissionalizante1',
																)}
																disabled={inscricaoEfetuada}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={20}>
															<Field
																as={Select}
																title="Ensino Técnico Profissionalizante (Opção 2)"
																name="inscricao.ensinoTecProfissionalizante2"
																options={dataEnsinoTecProfissionalizante}
																onChange={(v: string) => {
																	// handleChangeEnsinoTec2(v);
																	formik.setFieldValue(
																		'inscricao.ensinoTecProfissionalizante2',
																		v,
																	);
																}}
																error={hasError(
																	formik.errors,
																	'inscricao.ensinoTecProfissionalizante2',
																)}
																disabled={inscricaoEfetuada}
															/>
														</Col>
													</Row>
													<Row gutter={[0, 10]}>
														<Col span={20}>
															<Field
																as={Select}
																title="Ensino Técnico Profissionalizante (Opção 3)"
																name="inscricao.ensinoTecProfissionalizante3"
																options={dataEnsinoTecProfissionalizante}
																onChange={(v: string) => {
																	// handleChangeEnsinoTec3(v);
																	formik.setFieldValue(
																		'inscricao.ensinoTecProfissionalizante3',
																		v,
																	);
																}}
																error={hasError(
																	formik.errors,
																	'inscricao.ensinoTecProfissionalizante2',
																)}
																disabled={inscricaoEfetuada}
															/>
														</Col>
													</Row>
												</>
											)}
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={CheckBox}
														subtitle="Espanhol:"
														name="inscricao.espanhol"
														checked={formik.values.inscricao.espanhol}
														error={hasError(
															formik.errors,
															'inscricao.espanhol',
														)}
														disabled={
															inscricaoEfetuada ||
															formik.values.inscricao.interesseIntegral
														}
													/>
												</Col>
											</Row>
											<Row gutter={[0, 10]}>
												<Col span={20}>
													<Field
														as={CheckBox}
														subtitle="Atendimento Noturno:"
														name="inscricao.atendimentoNoturno"
														checked={formik.values.inscricao.atendimentoNoturno}
														error={hasError(
															formik.errors,
															'inscricao.atendimentoNoturno',
														)}
														disabled={
															inscricaoEfetuada ||
															formik.values.inscricao.interesseIntegral
														}
													/>
												</Col>
											</Row>
											{formik.values.inscricao.atendimentoNoturno && (
												<Row gutter={[0, 10]}>
													<Col span={20}>
														<Field
															as={Select}
															title="Observação para a escolha do período noturno"
															name="inscricao.opcaoNoturno"
															options={dataOpcoesNoturno}
															onChange={(v: string) => {
																const [result] = dataOpcoesNoturno.filter(
																	codigo => {
																		if (codigo.value === v) {
																			return codigo.value;
																		}
																		return null;
																	},
																);
																formik.setFieldValue(
																	'inscricao.opcaoNoturno',
																	result.label,
																);
																formik.setFieldValue(
																	'inscricao.codigoObservacaoOpcaoNoturno',
																	result.value,
																);
															}}
															error={hasError(
																formik.errors,
																'inscricao.opcaoNoturno',
															)}
															disabled={inscricaoEfetuada}
														/>
													</Col>
												</Row>
											)}
										</>
									)}
								{!inscricaoEfetuada ? (
									<Row gutter={[0, 10]} justify="center">
										<Col span={4}>
											<ButtonImage
												onClick={() => {
													formik.handleReset();
												}}
												type="button"
												src="limpar"
											/>
										</Col>
										<Col span={4}>
											<ButtonImage
												type="submit"
												src="avancar"
												disabled={
													!(
														formik.values.inscricao.fasesInscricao &&
														formik.values.inscricao.tipoEscola &&
														formik.values.nivelEnsino.codigoTipoEnsino &&
														(formik.values.nivelEnsino.codigoSerieAno ||
															formik.values.nivelEnsino.codigoSerieAno === 0)
													)
												}
											/>
										</Col>
									</Row>
								) : (
									<>
										{comprovanteInscricao.data?.urlArquivoPdf && (
											<Row gutter={[0, 10]} justify="center">
												<Col span={8} flex="center">
													<ButtonDownloadComprovante
														type="button"
														onClick={() =>
															(comprovanteInscricao.data?.urlArquivoPdf &&
																handleDownloadArchive(
																	comprovanteInscricao.data.urlArquivoPdf,
																)) ||
															null
														}
													>
														DOWNLOAD DO COMPROVANTE DE INSCRIÇÃO
													</ButtonDownloadComprovante>
												</Col>
											</Row>
										)}
										<Row gutter={[0, 10]} justify="center">
											<Col span={3} flex="center">
												<Button
													onClick={() => {
														dispatch(baixarArquivosClear());
														dispatch(cadastrarInscricaoClear());
														dispatch(cadastrarFichaAlunoClear());
														dispatch(alterarFichaAlunoClear());
														dispatch(eventosPPTContabilizacaoClear());
														dispatch(estatisticaIncluirClear());
														history.push(ROUTE_SECRETARIA_EDUCACAO);
													}}
												>
													Encerrar
												</Button>
											</Col>
										</Row>
									</>
								)}
							</Form>
						</ContainerFichaCadastral>
					)}
				</Formik>
			</Section>
			<ButtonVoltar
				navigate={false}
				onClick={() => {
					dispatch(baixarArquivosClear());
					dispatch(cadastrarInscricaoClear());
					dispatch(cadastrarFichaAlunoClear());
					dispatch(alterarFichaAlunoClear());
					history.push(ROUTE_SECRETARIA_EDUCACAO_INSCRICAO_FICHA_ALUNO);
				}}
			/>
		</>
	);
};

export default InscricaoAluno;
