import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// ANT DESIGN
import { Col, Input, Row, Tooltip, Modal } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultaAtendimentosClear,
	consultaAtendimentosRequest,
} from 'store/modules/api/procon/atendimentos/consultaAtendimentos/actions';
import {
	consultaAtendimentoPfClear,
	consultaAtendimentoPfRequest,
} from 'store/modules/api/procon/atendimentoCip/consultaAtendimentoPf/actions';
import { consultaInteracoesAtendimentoClear } from 'store/modules/api/procon/interacoes/atendimentos/consultaInteracoes/actions';
import { consultaAnexosConsumidorClear } from 'store/modules/api/procon/anexosConsumidor/atendimentos/consultaAnexosConsumidor/actions';
import { consultaDocumentosProduzidosClear } from 'store/modules/api/procon/documentosProduzidos/atendimentos/consultaDocumentosProduzidos/actions';
import {
	consultaAtendimentoPjClear,
	consultaAtendimentoPjRequest,
} from 'store/modules/api/procon/atendimentoCip/consultaAtendimentoPj/actions';
import {
	consultaAtendimentosDeConsultaClear,
	consultaAtendimentosDeConsultaRequest,
} from 'store/modules/api/procon/atendimentosDeConsulta/consultaAtendimentosDeConsulta/actions';

// COMPONENTS
import LightButton from 'components/Common/Buttons/Light';
import { Field, Form, Formik, FormikProps } from 'formik';
import CPF from 'components/Common/Form/Fields/CPF';
import { UlTableIcons } from 'components/Common/Table/styled';
import TypographyCommon from 'components/Common/Typography';

// UTILS
import hasError from 'utils/getFormErrors';
import getValidationsErrors from 'utils/getValidationsErrors';
import { threatDataConsultaAtendimentosDeConsultaRequest } from 'pages/procon/utils/functions/threatDataRequest/consultaAtendimentosDeConsulta';
import { threatDataConsultaAtendimentoPfRequest } from 'pages/procon/utils/functions/threatDataRequest/consultaAtendimentoPf';
import { threatDataConsultaAtendimentoPjRequest } from 'pages/procon/utils/functions/threatDataRequest/consultaAtendimentoPj';
import { limparMascara } from 'utils/genericFunctions';

// FORM
import {
	ConsultaAtendimentosForm,
	ConsultaAtendimentosRequest,
	ConsultaAtendimentosData,
} from 'store/modules/api/procon/atendimentos/consultaAtendimentos/types';
import SimpleTable from 'components/Common/Table';
import { FcSearch } from 'react-icons/fc';
import { ROUTE_PROCON } from 'routes/paths';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	letrasAcentosNumeros,
	numerosBarra,
	tratarSituacaoAtualProcon,
	tratarTipoProcon,
} from 'utils/stringFunctions';
import { typeConsultaAtendimentoEnum } from '../../utils/constants/enum';
import { initialValues, schema } from './form';

// PATHS
import { ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO } from '../../routes/paths';

interface PesquisaAtendimentoProcon {
	tipo: string;
	protocolo: string;
	nomeFantasiaDoFornecedor: string;
	razaoDoStatus: string;
	dataDeSolicitacao: string;
	situacaoAtual: string;
}

const ConsultarAtendimentos: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();

	const stylePaddingCols = { padding: '0 8px' };

	const [isAttendanceClicked, setIAttendanceClicked] = useState<boolean>(false);
	const [cpfPesquisado, setCpfPesquisado] = useState<ConsultaAtendimentosForm>({
		cpf: initialValues.cpf,
	});

	const dadosAtendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.salvarAtendimento.data,
	);

	const loginUnico = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { consultaAtendimentos } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentos,
	);

	const { consultaAtendimentoPf } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const { consultaAtendimentoPj } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentoCip,
	);

	const { consultaAtendimentosDeConsulta } = useSelector(
		(state: ApplicationState) => state.api.procon.atendimentosDeConsulta,
	);

	useEffect(() => {
		if (
			isAttendanceClicked &&
			(consultaAtendimentoPf.status === 200 ||
				consultaAtendimentoPj.status === 200)
		) {
			history.push(ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO);
		}
	}, [
		consultaAtendimentoPf,
		isAttendanceClicked,
		history,
		consultaAtendimentoPj,
	]);

	useEffect(() => {
		if (isAttendanceClicked && consultaAtendimentosDeConsulta.status === 200) {
			history.push(ROUTE_PROCON_PESQUISA_ATENDIMENTO_MANIFESTACAO);
		}
	}, [consultaAtendimentosDeConsulta, isAttendanceClicked, history]);

	const handleBack = () => {
		history.push(ROUTE_PROCON);
	};

	const handleVerifyStatusAttendance = (status: string) => {
		switch (status.toUpperCase()) {
			case 'Aguardando Apreciação da Diretoria de Fiscalização'.toUpperCase():
				return true;
			case 'Denúncia Encaminhada'.toUpperCase():
				return true;
			default:
				return false;
		}
	};

	const ModalInfo = () => {
		return Modal.info({
			title: 'Este atendimento não pode ser visualizado!',
			content: (
				<div>
					<p>
						Os detalhes deste atendimento não podem ser visualizados, pois está
						em processo de denúncia.
					</p>
				</div>
			),
			onOk() {},
		});
	};

	const handleRowClick = (tableRow: ConsultaAtendimentosData) => {
		clearNotifications();

		if (handleVerifyStatusAttendance(tableRow.razaoDoStatus)) {
			ModalInfo();
		} else {
			setIAttendanceClicked(true);

			if (
				tableRow.tipo.toLowerCase() ===
				typeConsultaAtendimentoEnum.CONSULTA.toLowerCase()
			) {
				const payloadValues =
					threatDataConsultaAtendimentosDeConsultaRequest(tableRow);
				if (payloadValues) {
					dispatch(consultaAtendimentosDeConsultaRequest(payloadValues));
				}
			} else if (tableRow.tipoDeConsumidorValor === 472890000) {
				const payloadValues = threatDataConsultaAtendimentoPfRequest(tableRow);

				// TODO NÃO VIR NENHUMA POSSIBILIDADE ACIMA INFORMAR USUÁRIO
				if (payloadValues) {
					dispatch(consultaAtendimentoPfRequest(payloadValues));
				}
			} else {
				const payloadValues = threatDataConsultaAtendimentoPjRequest(tableRow);

				// TODO NÃO VIR NENHUMA POSSIBILIDADE ACIMA INFORMAR USUÁRIO
				if (payloadValues) {
					dispatch(consultaAtendimentoPjRequest(payloadValues));
				}
			}
		}
	};

	const [searchValue, setSearchValue] = useState<PesquisaAtendimentoProcon>({
		tipo: '',
		protocolo: '',
		nomeFantasiaDoFornecedor: '',
		razaoDoStatus: '',
		dataDeSolicitacao: '',
		situacaoAtual: '',
	});

	const handleSubmit = useCallback(
		(values: ConsultaAtendimentosForm) => {
			dispatch(consultaAtendimentosClear());

			const evento = {
				id_atendimento: dadosAtendimento?.idAtendimento || '',
				id_cidadao: dadosAtendimento?.idCidadao || '',
				cpf: limparMascara(user.cpf),
				identificacao: Number(limparMascara(values.cpf)),
				ip: user.ip,
				canal: {
					id: loginUnico.login.user.idCanal,
					desc: loginUnico.login.user.descCanal,
					estacao: {
						id: Number(user.posto),
						desc: user.nomePosto,
					},
					localidade: {
						id: Number(user.posto),
						desc: user.nomePosto,
					},
				},
			};

			const payload: ConsultaAtendimentosRequest = {
				cpf: values.cpf,
				evento,
			};
			setSearchValue({
				tipo: '',
				protocolo: '',
				nomeFantasiaDoFornecedor: '',
				razaoDoStatus: '',
				dataDeSolicitacao: '',
				situacaoAtual: '',
			});

			dispatch(consultaAtendimentosRequest(payload));
		},

		[dadosAtendimento, user, loginUnico, dispatch],
	);

	const handleClear = useCallback(
		(formik: FormikProps<ConsultaAtendimentosForm>) => {
			setSearchValue({
				tipo: '',
				protocolo: '',
				nomeFantasiaDoFornecedor: '',
				razaoDoStatus: '',
				dataDeSolicitacao: '',
				situacaoAtual: '',
			});
			formik.setFieldValue('cpf', '');
			dispatch(consultaAtendimentosClear());
		},
		[dispatch],
	);

	const [atendimentos, setAtendimentos] = useState<ConsultaAtendimentosData[]>(
		consultaAtendimentos.data ?? [],
	);
	const [searchDisabled, setSearchDisabled] = useState<boolean>(true);

	const [noResultsMessage, setNoResultsMessage] = useState<string>('');

	const verificaPesquisaVazia = useCallback(() => {
		let isEmpty = true;
		const arraySearchValue = Object.values(searchValue);
		for (let index = 0; index < arraySearchValue.length; index++) {
			const element = arraySearchValue[index];
			if (element !== '') {
				isEmpty = false;
				break;
			}
		}
		return isEmpty;
	}, [searchValue]);

	useEffect(() => {
		if (
			verificaPesquisaVazia() &&
			consultaAtendimentos.status === 200 &&
			consultaAtendimentos?.data
		) {
			setNoResultsMessage(
				'A sua pesquisa não retornou nenhum dado. Tente novamente com outros dados.',
			);
		} else if (
			consultaAtendimentos.status === 200 &&
			consultaAtendimentos?.data
		) {
			setAtendimentos(consultaAtendimentos.data);
		}
		if (consultaAtendimentos.status === null) {
			setAtendimentos([]);
			setNoResultsMessage(
				'Para realizar uma pesquisa preencha o campo CPF acima.',
			);
		} else {
			setCpfPesquisado({ cpf: consultaAtendimentos.consulta });
		}
		if (
			consultaAtendimentos.status === 200 &&
			!consultaAtendimentos?.data?.length
		) {
			setNoResultsMessage(
				'Não foi encontrado nenhum atendimento para o CPF pesquisado.',
			);
		}
		setSearchDisabled(!consultaAtendimentos?.data?.length);
	}, [consultaAtendimentos, verificaPesquisaVazia]);

	const handleUpdateData = useCallback(() => {
		if (!consultaAtendimentos.data) {
			return null;
		}

		if (verificaPesquisaVazia()) {
			return setAtendimentos(consultaAtendimentos.data);
		}

		const newData: ConsultaAtendimentosData[] | null =
			consultaAtendimentos.data.filter((item: ConsultaAtendimentosData) => {
				if (
					item?.tipo?.toLocaleUpperCase().indexOf(searchValue.tipo) !== -1 &&
					item?.nomeFantasiaDoFornecedor
						?.toLocaleUpperCase()
						.indexOf(searchValue.nomeFantasiaDoFornecedor) !== -1 &&
					item?.razaoDoStatus
						?.toLocaleUpperCase()
						.indexOf(searchValue.razaoDoStatus) !== -1 &&
					item?.situacaoAtual
						?.toLocaleUpperCase()
						.indexOf(searchValue.situacaoAtual) !== -1 &&
					item?.protocolo
						?.toLocaleUpperCase()
						.indexOf(searchValue.protocolo) !== -1 &&
					new Date(item?.dataDeSolicitacao)
						.toLocaleDateString()
						?.toLocaleUpperCase()
						.indexOf(searchValue.dataDeSolicitacao) !== -1
				) {
					return item;
				}
				return null;
			});
		return setAtendimentos(newData);
	}, [consultaAtendimentos.data, verificaPesquisaVazia, searchValue]);

	useEffect(() => {
		handleUpdateData();
	}, [handleUpdateData, searchValue]);

	useEffect(() => {
		dispatch(consultaAtendimentosDeConsultaClear());
		dispatch(consultaAtendimentoPfClear());
		dispatch(consultaAtendimentoPjClear());
		dispatch(consultaInteracoesAtendimentoClear());
		dispatch(consultaAnexosConsumidorClear());
		dispatch(consultaDocumentosProduzidosClear());
	}, [dispatch]);

	const handleAtendimentoSearch = (search: string, field: string) => {
		setSearchValue((prev: PesquisaAtendimentoProcon) => ({
			...prev,
			[field]: search,
		}));
	};

	const getNomeFornecedor = (record: ConsultaAtendimentosData) => {
		if (record.nomeFantasiaDoFornecedor) {
			return record.nomeFantasiaDoFornecedor;
		}
		if (record.nomeFornecedor) {
			return record.nomeFornecedor;
		}
		return 'Não Informado';
	};

	const headers = [
		{
			title: 'Tipo',
			dataIndex: 'tipo',
			align: 'center',
			sorter: (a: { tipo: string }, b: { tipo: string }) => a.tipo > b.tipo,
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.tipo.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									tratarTipoProcon(e.target.value.toLocaleUpperCase()),
									'tipo',
								)
							}
						/>
					),
					dataIndex: 'tipo',
				},
			],
		},
		{
			title: 'Data',
			dataIndex: 'dataDeSolicitacao',
			defaultSortOrder: 'descend',
			align: 'center',
			sorter: (
				a: { dataDeSolicitacao: string },
				b: { dataDeSolicitacao: string },
			) =>
				new Date(a.dataDeSolicitacao).getTime() -
				new Date(b.dataDeSolicitacao).getTime(),
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.dataDeSolicitacao}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									numerosBarra(e.target.value.toLocaleUpperCase()),
									'dataDeSolicitacao',
								)
							}
						/>
					),
					defaultSortOrder: 'descend',
					dataIndex: 'dataDeSolicitacao',
					render: (text: string) =>
						text ? new Date(text).toLocaleDateString() : 'Não informada',
				},
			],
		},
		{
			title: 'Protocolo',
			dataIndex: 'protocolo',
			align: 'center',
			sorter: (a: { protocolo: string }, b: { protocolo: string }) =>
				a.protocolo > b.protocolo,
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.protocolo.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									numerosBarra(e.target.value.toLocaleUpperCase()),
									'protocolo',
								)
							}
						/>
					),
					dataIndex: 'protocolo',
				},
			],
		},
		{
			title: 'Fornecedor',
			dataIndex: 'nomeFantasiaDoFornecedor',
			render: (text: string) => text || 'Não informado',
			sorter: (
				a: { nomeFantasiaDoFornecedor: string },
				b: { nomeFantasiaDoFornecedor: string },
			) => a.nomeFantasiaDoFornecedor > b.nomeFantasiaDoFornecedor,
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.nomeFantasiaDoFornecedor.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									letrasAcentosNumeros(e.target.value.toLocaleUpperCase()),
									'nomeFantasiaDoFornecedor',
								)
							}
						/>
					),
					dataIndex: 'nomeFantasiaDoFornecedor',
					render: (text: string, record: ConsultaAtendimentosData) =>
						getNomeFornecedor(record),
				},
			],
			align: 'center',
		},
		{
			title: 'Status',
			dataIndex: 'razaoDoStatus',
			sorter: (a: { razaoDoStatus: string }, b: { razaoDoStatus: string }) =>
				a.razaoDoStatus > b.razaoDoStatus,
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.razaoDoStatus.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									tratarTipoProcon(e.target.value.toLocaleUpperCase()),
									'razaoDoStatus',
								)
							}
						/>
					),
					dataIndex: 'razaoDoStatus',
				},
			],
			align: 'center',
		},
		{
			title: 'Situação Atual',
			dataIndex: 'situacaoAtual',
			sorter: (a: { situacaoAtual: string }, b: { situacaoAtual: string }) =>
				a.situacaoAtual > b.situacaoAtual,
			children: [
				{
					title: (
						<Input
							disabled={searchDisabled}
							value={searchValue.situacaoAtual.toLocaleUpperCase()}
							onChange={(e: { target: { value: string } }) =>
								handleAtendimentoSearch(
									tratarSituacaoAtualProcon(e.target.value.toLocaleUpperCase()),
									'situacaoAtual',
								)
							}
						/>
					),
					dataIndex: 'situacaoAtual',
				},
			],
			align: 'center',
		},
		{
			dataIndex: '',
			title: 'Ações',
			align: 'center',
			render: () => (
				<UlTableIcons>
					<Tooltip title="Visualizar Atendimento">
						<FcSearch />
					</Tooltip>
				</UlTableIcons>
			),
		},
	];

	return (
		<>
			<Row gutter={[0, 0]} align="middle" justify="start">
				<Col className="gutter-row" span={24} style={{ paddingBottom: '10px' }}>
					<TypographyCommon
						text="Pesquisar Atendimento"
						borderBottom
						type="h5"
						fontWeight="bold"
						uppercase
					/>
				</Col>
			</Row>

			<br />

			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				initialValues={cpfPesquisado}
				validate={values => getValidationsErrors(values, schema)}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{formik => (
					<Form autoComplete="off">
						<Row gutter={[0, 20]} align="middle" justify="start">
							<Col span={30} className="gutter-row">
								<div style={stylePaddingCols}>
									<Field
										as={CPF}
										title="CPF"
										numero="cpf"
										formik={formik}
										titleSize="auto"
										error={hasError(formik.errors, 'cpf')}
									/>
								</div>
							</Col>
							<Col className="gutter-row">
								<div style={stylePaddingCols}>
									<LightButton
										type="submit"
										buttonColor="default"
										text="Pesquisar"
										icon="search"
										buttonSize="sm"
									/>
								</div>
							</Col>
							<Col className="gutter-row">
								<div style={stylePaddingCols}>
									<LightButton
										type="button"
										buttonColor="default"
										text="Limpar"
										icon="erase"
										buttonSize="sm"
										onClick={() => {
											handleClear(formik);
											dispatch(clearNotifications());
										}}
									/>
								</div>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>

			<div
				style={{
					color: '#2e2e2e',
					borderBottom: '0.1px #c6c6c6 solid',
					marginBottom: '20px',
				}}
			/>

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<SimpleTable
						onClick={handleRowClick}
						showSizeChanger
						headers={headers}
						messageNoResults={noResultsMessage}
						body={atendimentos}
					/>
					<LightButton
						type="button"
						buttonColor="default"
						text="Voltar"
						icon="back"
						buttonSize="sm"
						buttonWidth={100}
						onClick={handleBack}
						style={{ marginTop: '20px' }}
					/>
				</Col>
			</Row>
		</>
	);
};

export default ConsultarAtendimentos;
