import React, {
	useCallback,
	ChangeEvent,
	useState,
	useEffect,
	useContext,
} from 'react';

import { FormikProps, Field } from 'formik';

// COMPONENTS
import InputMask from 'components/Common/Form/Input/InputMask';

// UTILS
import hasError from 'utils/getFormErrors';
import { treatRequestSguCorporativeZipCode } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import {
	enderecosClear,
	enderecosRequest,
} from 'store/modules/api/utils/enderecos/actions';
import { ApplicationState } from 'store';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import {
	pesquisaNaturalidadeClear,
	pesquisaNaturalidadeRequest,
} from 'store/modules/sgu/actions';
import {
	consultaCEPClear,
	consultaCEPRequest,
} from 'store/modules/api/detranCnh/consulta/cep/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

export interface IBuscarCepResultado {
	cep: string;
	endereco: string;
	bairro: string;
	numeroIBGE: number | string;
	codigoMunicipio: number | string;
	codigoNaturalidade: number | string;
	municipio: string;
	uf: string;
	codigoBairro: number | string;
	codigoUnidadeTransito: number | string;
	estado: string;
	tipoLogradouro: string;
	enderecoAbrev: string;
	codigoLocalRel?: number | string;
	codigoMunicipioProdesp?: number | string;
}

interface Props {
	title?: string;
	name: string;
	defaultValue?: string;
	required?: boolean;
	size?: number;
	formik: FormikProps<any>;
	readOnly?: boolean;
	retornoErro?: boolean;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | 'auto' | number;
	titleAlign?: 'start' | 'end';
	renderValue?: string | null;
	result: (data: IBuscarCepResultado) => void;
	maskChar?: string;
	disabled?: boolean;
	noAbrevLogradouro?: boolean;
	isCalledInService?: 'DETRAN_CNH' | 'IIRGD';
	errorName?: string;
	instanceRequest?: string;
	setBeforeLeftPosition?: number;
}

export const initialValues: IBuscarCepResultado = {
	cep: '',
	endereco: '',
	bairro: '',
	numeroIBGE: '',
	codigoMunicipio: '',
	codigoNaturalidade: '',
	municipio: '',
	uf: '',
	codigoBairro: '',
	codigoUnidadeTransito: '',
	estado: '',
	tipoLogradouro: '',
	enderecoAbrev: '',
	codigoLocalRel: '',
	codigoMunicipioProdesp: '',
};

let flagRequestNaturalidade = false;

const CEP: React.FC<Props> = ({
	title = 'CEP',
	name,
	defaultValue = '',
	required = false,
	size = 100,
	formik,
	result,
	readOnly = false,
	retornoErro = false,
	titleSize = 'md',
	titleAlign = 'end',
	renderValue = null,
	maskChar = '',
	disabled = false,
	noAbrevLogradouro = false,
	isCalledInService = '',
	errorName = '',
	instanceRequest = '',
	setBeforeLeftPosition = -4,
	// necessário utilizar o instanceRequest caso use mais de uma vez o componente na mesma tela.
}) => {
	const { errors } = formik;
	const dispatch = useDispatch();

	const { token } = useContext<IAuthContext>(AuthContext);

	const { enderecos } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { pesquisaNaturalidade } = useSelector(
		(state: ApplicationState) => state.sgu,
	);

	const { consultaCEP } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const [selectedCep, setSelectedCep] = useState<string>(defaultValue);

	const border = useState<boolean>(readOnly);

	const clearStoreData = useCallback(() => {
		dispatch(enderecosClear());
		dispatch(pesquisaNaturalidadeClear());
		dispatch(consultaCEPClear());
	}, [dispatch]);

	const handleOnChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			if (value.length >= 7) {
				clearStoreData();
			}
			setSelectedCep(value);
		},
		[clearStoreData],
	);

	const registerSGUStatistics = useCallback(
		(cepProccess: string, message: string, dataFailure: string | null) => {
			const payload = treatRequestSguCorporativeZipCode(
				user,
				dadosAtendimento,
				message,
				cepProccess,
				dataFailure,
			);

			dispatch(estatisticaIncluirRequest(payload));
		},
		[dadosAtendimento, dispatch, user],
	);

	useEffect(() => {
		setSelectedCep(defaultValue);
	}, [defaultValue]);

	const getNaturalidade = useCallback(() => {
		if (
			pesquisaNaturalidade.status === 200 &&
			pesquisaNaturalidade.naturalidades &&
			pesquisaNaturalidade.naturalidades[0]
		) {
			const naturalidade = pesquisaNaturalidade.naturalidades[0];
			const codigoNaturalidade =
				`${naturalidade.id}${naturalidade.digito}` || '';
			return codigoNaturalidade;
		}
		return '';
	}, [pesquisaNaturalidade.naturalidades, pesquisaNaturalidade.status]);

	const getCodigoUnidadeTransito = useCallback(() => {
		if (consultaCEP.status === 200 && consultaCEP.data) {
			const codigoUnidadeTransito =
				consultaCEP.data.codigoUnidadeTransito.toString() || '';
			return codigoUnidadeTransito;
		}

		return '';
	}, [consultaCEP.data, consultaCEP.status]);

	const getCodigoMunicipioDetran = useCallback(() => {
		if (consultaCEP.status === 200 && consultaCEP.data) {
			const codigoMunicipio = consultaCEP.data.codigoMunicipio.toString() || '';
			return codigoMunicipio;
		}

		return '';
	}, [consultaCEP.data, consultaCEP.status]);

	useEffect(() => {
		if (enderecos.status === 200 && enderecos.data && enderecos.data[0]?.cep) {
			const endereco = enderecos.data[0];

			if (
				pesquisaNaturalidade.status === 0 &&
				flagRequestNaturalidade === false
			) {
				flagRequestNaturalidade = true;
				dispatch(pesquisaNaturalidadeRequest({ idIbge: endereco.numeroIBGE }));
			}
		}
	}, [enderecos.data, enderecos.status, pesquisaNaturalidade.status, dispatch]);

	useEffect(() => {
		if (
			enderecos.status === 200 &&
			enderecos.data &&
			enderecos.data[0]?.cep &&
			pesquisaNaturalidade.status !== 0 &&
			pesquisaNaturalidade.status !== 100 &&
			((isCalledInService === 'DETRAN_CNH' &&
				consultaCEP.status !== 0 &&
				consultaCEP.status !== 100) ||
				isCalledInService !== 'DETRAN_CNH') &&
			instanceRequest === enderecos.instanceRequest
		) {
			flagRequestNaturalidade = false;
			const endereco = enderecos.data[0];

			const logradouro = noAbrevLogradouro
				? (endereco?.endereco && `${endereco?.endereco || ''}`) || ''
				: `${
						endereco?.tipoLogradouroAbrev
							? `${endereco?.tipoLogradouroAbrev} ${endereco?.endereco || ''}`
							: (endereco?.endereco && `${endereco?.endereco || ''}`) || ''
				  }`;

			const codigoMunicipioResponse =
				getCodigoMunicipioDetran() === '' || getCodigoMunicipioDetran()
					? getCodigoMunicipioDetran()
					: null;
			const codigoNaturalidadeResponse =
				getNaturalidade() === '' || getNaturalidade()
					? getNaturalidade()
					: null;
			const codigoUnidadeTransito =
				getCodigoUnidadeTransito() === '' || getCodigoUnidadeTransito()
					? getCodigoUnidadeTransito()
					: null;

			const addressValues = {
				cep: endereco?.cep,
				endereco: logradouro || '',
				bairro: endereco?.bairro || '',
				numeroIBGE: endereco?.numeroIBGE || '',
				codigoMunicipio:
					codigoMunicipioResponse || endereco.codigoMunicipio || '',
				municipio: endereco?.municipio || '',
				uf: endereco?.uf || '',
				codigoBairro: endereco?.codigoBairro || '',
				codigoNaturalidade: codigoNaturalidadeResponse || '',
				codigoUnidadeTransito: codigoUnidadeTransito || '',
				estado: endereco?.estado || '',
				tipoLogradouro: endereco?.tipoLogradouro || '',
				enderecoAbrev: endereco?.enderecoAbrev || '',
				codigoLocalRel: endereco?.codigoLocalRel || '',
				codigoMunicipioProdesp: endereco?.codigoMunicipio || '',
			};

			if (
				codigoMunicipioResponse !== null &&
				codigoNaturalidadeResponse !== null &&
				codigoUnidadeTransito !== null
			) {
				result(addressValues);
			}
		} else if (
			(instanceRequest === enderecos.instanceRequest &&
				enderecos.status !== 0 &&
				enderecos.status !== 100) ||
			(instanceRequest === enderecos.instanceRequest &&
				retornoErro &&
				enderecos.status !== 0 &&
				enderecos.status !== 100)
		) {
			const cep = selectedCep.replace(/[_.-]+/g, '');

			if (
				cep &&
				enderecos.status !== 200 &&
				(!enderecos.data || (enderecos.data && !enderecos.data[0].cep))
			) {
				const invalidCEPMessage =
					'Dados do CEP não foram retornados pelo serviço de CEP Corporativo';
				registerSGUStatistics(cep, invalidCEPMessage, enderecos.dataFailure);
			}

			result({ ...initialValues, cep });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		consultaCEP.status,
		enderecos.data,
		enderecos.status,
		enderecos.instanceRequest,
		getCodigoMunicipioDetran,
		getCodigoUnidadeTransito,
		getNaturalidade,
		registerSGUStatistics,
		isCalledInService,
		noAbrevLogradouro,
		retornoErro,
		instanceRequest,
		pesquisaNaturalidade.status,
	]);

	const handleOnBlur = useCallback(() => {
		const cep = selectedCep.replace(/[_.-]+/g, '');

		if (cep.length !== 8) {
			result(initialValues);
			return;
		}

		if (enderecos.status === 0) {
			dispatch(enderecosRequest(token, { instanceRequest, cep }));
		}

		if (isCalledInService === 'DETRAN_CNH' && consultaCEP.status === 0) {
			dispatch(consultaCEPRequest({ cep }));
		}
	}, [
		selectedCep,
		enderecos.status,
		isCalledInService,
		consultaCEP.status,
		result,
		dispatch,
		token,
		instanceRequest,
	]);

	return (
		<Field
			as={InputMask}
			title={title}
			titleSize={titleSize}
			titleAlign={titleAlign}
			name={name}
			value={renderValue || selectedCep}
			mask="99999-999"
			required={required}
			onChange={handleOnChange}
			onBlur={handleOnBlur}
			error={hasError(errors, errorName || name)}
			style={{ border: border ? '' : 'none' }}
			readOnly={readOnly}
			size={size}
			maskChar={maskChar}
			disabled={disabled}
			setBeforeLeftPosition={setBeforeLeftPosition}
		/>
	);
};

export default CEP;
