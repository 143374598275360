import React, { useCallback } from 'react';

import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUX
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { addNotifications } from 'store/modules/app/notifications/actions';

// PATHS
import { ROUTE_CDHU_SERVICOS_ACORDO_TERMOS_IMPRIMIR } from 'pages/cdhu/routes/paths';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { formatCurrency } from 'utils/numberFunctions';

const GerarBoletoAcordo: React.FC = () => {
	const history = useHistory();

	const {
		consultarMutuario,
		consultarPrestacoesAtrasadas,
		simularEfetuarAcordo,
	} = useSelector((state: ApplicationState) => state.api.cdhu);

	const handleClickGerarBoleto = useCallback(() => {
		if (simularEfetuarAcordo.data) {
			const { urlBoletoGerado, mensagem } = simularEfetuarAcordo.data;

			if (urlBoletoGerado && urlBoletoGerado !== '') {
				window.open(urlBoletoGerado, '_blank')?.focus();
			} else {
				addNotifications({
					errors: [
						mensagem ||
							'Ocorreu um problema na geração do boleto. Tente mais tarde. Caso persista o problema contate a CDHU.',
					],
				});
			}
		}
	}, [simularEfetuarAcordo.data]);

	const handleClickTermos = useCallback(() => {
		history.push(ROUTE_CDHU_SERVICOS_ACORDO_TERMOS_IMPRIMIR);
	}, [history]);

	return (
		<>
			{consultarMutuario.data &&
				consultarPrestacoesAtrasadas.data &&
				simularEfetuarAcordo.data && (
					<Row gutter={[0, 10]}>
						<Col span={24}>
							<Section size="lg" title="Serviços / Acordo" titleSize="sm">
								<Row gutter={[0, 15]}>
									<Col span={24}>
										<Row gutter={[0, 5]}>
											<Col span={24}>
												<h5>1. Dados do Mutuário</h5>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>1.1. Nome:</Col>

											<Col>
												<b>{consultarMutuario.data.nome.toUpperCase()}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>1.2. CPF:</Col>

											<Col>
												<b>{consultarMutuario.data.cpf}</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 15]}>
									<Col span={24}>
										<Row gutter={[0, 5]}>
											<Col span={24}>
												<h5>2. Dados do Imóvel</h5>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>2.1. Empreendimento:</Col>

											<Col>
												<b>{consultarMutuario.data.cnjHab.toUpperCase()}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>2.2. Endereço:</Col>

											<Col>
												<b>
													{consultarMutuario.data.endCompleto.toUpperCase()}
												</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 15]}>
									<Col span={24}>
										<Row gutter={[0, 5]}>
											<Col span={24}>
												<h5>3. Dados do Financiamento</h5>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>3.1. Conta:</Col>

											<Col>
												<b>{consultarMutuario.data.numeroContrato}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>
												3.2. Valor total das parcelas atrasadas:
											</Col>

											<Col>
												<b>
													{formatCurrency(
														simularEfetuarAcordo.data.valorPrestacoesAtrasadas,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>3.3. Qtde. parcelas atrasadas:</Col>

											<Col>
												<b>
													{consultarPrestacoesAtrasadas.data.quantidadeField}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>
												3.4. Período parcelas atrasadas (mês/ano):
											</Col>

											<Col>
												<b>
													{simularEfetuarAcordo.data.periodoInicialAcordo &&
														simularEfetuarAcordo.data.periodoFinalAcordo && (
															<b>
																{`${simularEfetuarAcordo.data.periodoInicialAcordo} a ${simularEfetuarAcordo.data.periodoFinalAcordo}`}
															</b>
														)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>3.5. Valor da prestação vigente:</Col>

											<Col>
												<b>
													{formatCurrency(
														simularEfetuarAcordo.data.prestacaoLiquidaAtual,
													)}
												</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row>
									<Col span={24}>
										<Row gutter={[0, 5]}>
											<Col span={24}>
												<h5>4. Dados do Acordo</h5>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>4.1. Quantidade de parcelas do acordo:</Col>

											<Col>
												<b>{simularEfetuarAcordo.data.qtdParcelasAcordo}</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>
												4.2. Valor da parcela de entrada do acordo:
											</Col>

											<Col>
												<b>
													{formatCurrency(
														simularEfetuarAcordo.data.valorParcelaInicial,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>4.3. Valor da parcela do acordo:</Col>

											<Col>
												<b>
													{formatCurrency(
														simularEfetuarAcordo.data.valorParcelaAcordo,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>
												4.4. Valor da prestação com a parcela do acordo::
											</Col>

											<Col>
												<b>
													{formatCurrency(
														simularEfetuarAcordo.data.novaPrestacaoLiquida,
													)}
												</b>
											</Col>
										</Row>

										<Row gutter={[0, 5]}>
											<Col span={8}>4.5. Data de vencimento da 1ª parcela:</Col>

											<Col>
												<b>{simularEfetuarAcordo.data.dataPrestacaoInicial}</b>
											</Col>
										</Row>

										<Row>
											<Col span={8}>
												4.6. Data de vencimento da última parcela:
											</Col>

											<Col>
												<b>{simularEfetuarAcordo.data.dataPrestacaoFinal}</b>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row gutter={[0, 20]} className="no-print">
									<Col span={24} />
								</Row>

								<Row justify="center" className="no-print">
									<Col>
										<ButtonImage
											src="gerar-boleto"
											onClick={handleClickGerarBoleto}
										/>
									</Col>

									<Col offset={1}>
										<ButtonImage src="termos" onClick={handleClickTermos} />
									</Col>
								</Row>
							</Section>
						</Col>
					</Row>
				)}
		</>
	);
};

export default GerarBoletoAcordo;
