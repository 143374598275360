import { takeLatest, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse } from 'services/_api';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { getApiCDHUFinanceiro } from 'services/apiCDHU';
import { Types, ObterFichaFinanceiraRequest } from './types';

// ACTIONS
import {
	obterFichaFinanceiraSuccess,
	obterFichaFinanceiraFailure,
} from './actions';

function* obterFichaFinanceiraRequest(request: ReducerAction) {
	const { payload }: { payload: ObterFichaFinanceiraRequest } = request;

	const response: ApiResponse[] = yield all([
		getApiCDHUFinanceiro(
			`api/DadosFinanceiros/ObterFichaFinanceira?Contrato=${payload.numeroContrato}`,
			payload.token,
		),
	]);

	const fichaFinanceiraData = response[0].data;

	if (response[0].status === 200) {
		yield put(obterFichaFinanceiraSuccess(fichaFinanceiraData));
	} else {
		yield put(obterFichaFinanceiraFailure());
	}
}

export default all([
	takeLatest(Types.OBTER_FICHA_FINANCEIRA_REQUEST, obterFichaFinanceiraRequest),
]);
