import { takeLatest, put, all, call } from 'redux-saga/effects';
import { setLoader } from 'store/modules/app/loader/actions';

// SERVICES
import { ApiResponse as ApiResponseRotasp, postApi } from 'services/rotasp/api';
import { PATH_CDHU_MUTUARIOS_API } from 'services/rotasp/path';

// UTILS
import { treatObjNullValues } from 'utils/genericFunctions';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, GerarOcorrenciaData, GerarOcorrenciaRequest } from './types';

// ACTIONS
import {
	gerarOcorrenciaSuccess,
	gerarOcorrenciaFailure,
	gerarOcorrenciaClear,
} from './actions';

function* gerarOcorrenciaRequest(request: ReducerAction) {
	const { token, payload }: { token?: string; payload: GerarOcorrenciaRequest } = request;
	
	yield put(gerarOcorrenciaClear());

	const responseOcorrencia: ApiResponseRotasp = yield call(
		postApi,
		token || '',
		PATH_CDHU_MUTUARIOS_API,
		`ocorrencias`,
		payload,
	);

	setLoader(false);

	if (responseOcorrencia.status === 201) {
		const data = treatObjNullValues(
			responseOcorrencia.data,
		) as GerarOcorrenciaData;

		yield put(gerarOcorrenciaSuccess({ ...data }, payload));
	} else {
		yield put(gerarOcorrenciaFailure());
	}
}

export default all([
	takeLatest(Types.GERAR_OCORRENCIA_REQUEST, gerarOcorrenciaRequest),
]);
