export enum Types {
	OBTER_FICHA_FINANCEIRA_REQUEST = '@cdhu/OBTER_FICHA_FINANCEIRA_REQUEST',
	OBTER_FICHA_FINANCEIRA_SUCCESS = '@cdhu/OBTER_FICHA_FINANCEIRA_SUCCESS',
	OBTER_FICHA_FINANCEIRA_FAILURE = '@cdhu/OBTER_FICHA_FINANCEIRA_FAILURE',
	OBTER_FICHA_FINANCEIRA_CLEAR = '@cdhu/OBTER_FICHA_FINANCEIRA_CLEAR',
}

export interface ObterFichaFinanceira {
	status: number;
	data: ObterFichaFinanceiraData | null;
}

export interface ObterFichaFinanceiraData {
  base64Api: string;
  mensagem: string;
  statusCode: number;
}

export interface ObterFichaFinanceiraRequest {
  numeroContrato: string;
  token: string;
	baseUrl: string;
}
