import { AtualizarPortalSolicitacaoRequest } from 'store/modules/api/mp/atualizarSolicitacaoPortal/types';
import {
	ICadastroSolicitante,
	SguData,
	treatRequestAtualizarSolicitacao,
} from '../Forms/form';

export const treatDataUpdateRequestWithProtocol = (
	values: ICadastroSolicitante,
	sguData: SguData,
	protocolo: string,
	solicitacaoId?: string,
) => {
	const { tipoSolicitante } = values;

	const defaultData = treatRequestAtualizarSolicitacao(
		values,
		tipoSolicitante,
		sguData,
		solicitacaoId,
	);

	const data: AtualizarPortalSolicitacaoRequest = {
		...defaultData,
		protocolo,
		status: '2',
	};

	return data;
};
