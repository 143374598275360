import styled from 'styled-components';

export const ContainerDeficiencia = styled.div`
	table {
		border: 1px solid #ccc;
		padding: 5px;
		.ant-table-thead > tr > th {
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(196, 196, 196, 1) 45%
			);
			padding: 6px 16px;
			border: none;
			font-weight: bold;
			color: #4f4f4f;
			text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
			text-align: center;
			font-size: 0.9rem;
		}

		.ant-table-tbody {
			.ant-table-row-level-0 {
				font-weight: bold;
				td.ant-table-cell {
					padding: 0px 16px;
					border-bottom: none;
				}
			}
		}
	}
`;

export const ContainerProfissionalApoio = styled.div`
	table {
		border: 1px solid #ccc;
		padding: 5px;
		.ant-table-thead > tr > th {
			background: linear-gradient(
				180deg,
				rgba(255, 255, 255, 1) 0%,
				rgba(196, 196, 196, 1) 45%
			);
			padding: 6px 16px;
			border: none;
			font-weight: bold;
			color: #4f4f4f;
			text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.7);
			text-align: center;
			font-size: 0.9rem;
		}

		.ant-table-tbody {
			.ant-table-row-level-0 {
				font-weight: bold;
				td.ant-table-cell {
					padding: 0px 16px;
					border-bottom: none;
				}
			}
		}
	}
`;

export const Content = styled.div`
	section {
		margin-bottom: 20px;
	}
`;
