import { all } from 'redux-saga/effects';

// MUTUÁRIO
import consultarMutuario from './consultarMutuario/saga';

// PRESTAÇÕES ATRASADAS
import consultarPrestacoesAtrasadas from './consultarPrestacoesAtrasadas/saga';

// BOLETO AGRUPADO
import gerarBoletoAgrupado from './gerarBoletoAgrupado/saga';

// OCORRÊNCIA
import gerarOcorrencia from './gerarOcorrencia/saga';

// SOLICITANTE
import cadastrarSolicitante from './cadastrarSolicitante/saga';
import consultarSolicitante from './consultarSolicitante/saga';

// ACORDO
import simularEfetuarAcordo from './simularEfetuarAcordo/saga';

// OBTER FICHA FINANCEIRA
import obterFichaFinanceira from './obterFichaFinanceira/saga';

// OBTER TOKEN CDHU
import gerarTokenCdhu from './gerarTokenCdhu/saga';

export default all([
	// MUTUÁRIO
	consultarMutuario,

	// PRESTAÇÕES ATRASADAS
	consultarPrestacoesAtrasadas,

	// BOLETO AGRUPADO
	gerarBoletoAgrupado,

	// OCORRÊNCIA
	gerarOcorrencia,

	// SOLICITANTE
	consultarSolicitante,
	cadastrarSolicitante,

	// ACORDO
	simularEfetuarAcordo,

	// OBTER FICHA FINANCEIRA
	obterFichaFinanceira,

	// OBTER TOKEN CDHU
	gerarTokenCdhu,
]);
