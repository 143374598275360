import produce from 'immer';
import { ReducerAction } from 'store/modules/types';
import { Types, listarSolicitacaoPortal } from './types';

export const INITIAL_STATE: listarSolicitacaoPortal = {
	status: 0,
	data: [],
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): listarSolicitacaoPortal {
	return produce(state, draft => {
		switch (action.type) {
			case Types.LISTAR_SOLICITACAO_PORTAL_REQUEST: {
				draft.status = 100;
				break;
			}

			case Types.LISTAR_SOLICITACAO_PORTAL_SUCCESS: {
				draft.status = action.payload.status;
				draft.data = action.payload.data;
				break;
			}

			case Types.LISTAR_SOLICITACAO_PORTAL_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.LISTAR_SOLICITACAO_PORTAL_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}
		return draft;
	});
}
