export enum Types {
	CADASTRAR_FICHA_ALUNO_REQUEST = '@EDUCACAO/CADASTRAR_FICHA_ALUNO_REQUEST',
	CADASTRAR_FICHA_ALUNO_SUCCESS = '@EDUCACAO/CADASTRAR_FICHA_ALUNO_SUCCESS',
	CADASTRAR_FICHA_ALUNO_FAILURE = '@EDUCACAO/CADASTRAR_FICHA_ALUNO_FAILURE',
	LAT_LNG_SELECIONADA = '@EDUCACAO/LAT_LNG_SELECIONADA',
	CADASTRAR_FICHA_ALUNO_CLEAR = '@EDUCACAO/CADASTRAR_FICHA_ALUNO_CLEAR',
}

export interface CadastrarFichaAluno {
	status: number;
	data: CadastrarFichaAlunoData | null;
	dataRequest: FichaAlunoEnvio | null;
	latlng: LatLngSelecionada | null;
}

export interface CadastrarFichaAlunoData {
	sucesso: string;
	processoId: string;
	aluno: {
		numRA: string;
		digitoRA: string;
		siglaUFRA: string;
	};
}

export interface FichaAlunoEnvio {
	dadosPessoais: {
		nomeAluno: string;
		dataNascimento: string;
		codCorRaca: string;
		codSexo: string; // Alterado de 'sexo' para 'codSexo'
		nomeMae: string;
		nomePai: string;
		nomeSocial: string;
		nomeAfetivo: string;
		email: string;
		flagSigilo: string;
		codNacionalidade: string;
		nacionalidade?: string;
		codPaisOrigem: string;
		paisOrigem: string;
		dataEntradaPais: string;
		bolsaFamilia: boolean;
		quilombola: boolean;
		possuiInternet: boolean;
		possuiNotebookSmartphoneTablet: boolean;
		nomeMunicipioNascimento: string;
		ufMunicipioNascimento: string;
		codMunicipioNascimentoDNE: string;
		doadorOrgaos: boolean;
		tipoSanguineo: string;
	};
	documentos: {
		codigoINEP: string;
		cpf: string;
		numDocumentoCivil: string;
		digitoDocumentoCivil: string;
		ufDocumentoCivil: string;
		dataEmissaoDocumentoCivil: string;
		numeroNis: string;
		numeroCns: string;
		justificativaDocumentos: string;
	};
	enderecoResidencial: {
		codAreaLogradouro: string;
		areaLogradouro?: string;
		logradouro: string;
		numero: string;
		complemento: string;
		bairro: string;
		nomeCidade: string;
		ufCidade: string;
		latitude: string;
		longitude: string;
		cep: string;
		codMunicipioDNE: string;
		codLocalizacaoDiferenciada: string;
	};
	deficiencia: DeficienciaCadastroFichaAluno;
	recursoAvaliacao: RecursoAvaliacaoCadastroFichaAluno;
	certidaoAntiga: {
		numCertidao: string;
		livro: string;
		folha: string;
		municipioComarca: string;
		ufComarca: string;
		distritoCertidao: string;
		dataEmissaoCertidao: string;
		codMunicComarcaDNE: string;
	};
	certidaoNova: {
		certMatr01: string;
		certMatr02: string;
		certMatr03: string;
		certMatr04: string;
		certMatr05: string;
		certMatr06: string;
		certMatr07: string;
		certMatr08: string;
		certMatr09: string;
		dataEmissaoCertidao: string;
	};
	rastreio: {
		usuarioRemoto: string;
		nomeUsuario: string;
		numCPF: string;
		localPerfilAcesso: string;
	};
	latLngSelecionada?: LatLngSelecionada | null;
}

export interface RecursoAvaliacaoCadastroFichaAluno {
	guiaInterprete: boolean;
	interpreteLibras: boolean;
	leituraLabial: boolean;
	nenhum: boolean;
	provaAmpliada: boolean;
	fonteProva: string;
	fonteProva18: boolean;
	fonteProva20: boolean;
	provaBraile: boolean;
	auxilioTranscricao: boolean;
	auxilioLeitor: boolean;
	provaVideoLibras: boolean;
	cdAudioDefVisual: boolean;
	provaLinguaPortuguesa: boolean;
}

export interface DeficienciaCadastroFichaAluno {
	codigoNecessidade: string;
	mobilidadeReduzida: string | boolean;
	tipoMobilidadeReduzida: string;
	dataInicioMobilidadeReduzida: string;
	dataFimMobilidadeReduzida: string;
	codigoNivelSuporte: number | string;
	profissionalApoioEscolarVO: {
		profissionalApoioEscolar: boolean;
		atividadeDiaria: boolean;
		atividadeEscolar: boolean;
		apoioHigiene: boolean;
		apoioLocomocao: boolean;
		apoioAlimentacao: boolean;
		apoioBanheiro: boolean;
		apoioMedicamento: boolean;
		dataInicioVigenciaAtividadeDiaria: string;
		dataFimVigenciaAtividadeDiaria: string;
		dataInicioVigenciaAtividadeEscolar: string;
		dataFimVigenciaAtividadeEscolar: string;
	};
}

export interface LatLngSelecionada {
	lat: number;
	lng: number;
}
