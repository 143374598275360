import { AtendimentoState } from 'store/modules/api/sgu/atendimento/types';
import { ILoginUnicoState } from 'store/modules/api/sgu/loginUnico/types';
import { IncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/types';
import { limparMascara } from 'utils/genericFunctions';

export const treatRequestSguResponseEventsCDHU = (
	loginUnico: ILoginUnicoState,
	dadosAtendimento: AtendimentoState,
	cpfAtendimento: string,
	typeResponseEvent: boolean,
	eventType: string,
	eventTypeDescription: string,
): IncluirRequest => {
	const payload: IncluirRequest = {
		descricaoSistemOrigem: 'BALCÃO ÚNICO 4.0',
		tipoDocumento: 'CPF',
		numeroDocumento: (cpfAtendimento && limparMascara(cpfAtendimento)) || '', // cpf do atendimento
		numeroProcesso:
			dadosAtendimento?.salvarAtendimento.data?.idAtendimento ||
			'00000000-0000-0000-0000-000000000000', // id atendimento
		codigoLocalidade:
			(loginUnico.login.user?.posto && Number(loginUnico.login.user.posto)) ||
			0,
		idOrgao: loginUnico.login.user.idOrgao,
		cpf: loginUnico.login.user.cpf, // cpf do atendente
		descricaoServico: `CDHU - ${eventTypeDescription}`,
		idMotivo: 10,
		observacao: typeResponseEvent
			? `${eventType} - Contablizado com sucesso`
			: `${eventType} - Contablizado com erro`,
	};

	return payload;
};
