import { ICadastroSolicitante } from 'pages/mp/ReconhecimentoPaternidade/NovoCadastro/Forms/form';
import { Types } from './types';

export function preCadastroRequest(payload: ICadastroSolicitante) {
	return {
		type: Types.PRE_CADASTRO_REQUEST,
		payload,
	};
}

export function limpaPreCadastroRequest() {
	return {
		type: Types.LIMPA_PRE_CADASTRO_REQUEST,
	};
}
