import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, CadastrarSolicitante } from './types';

export const INITIAL_STATE: CadastrarSolicitante = {
	status: 0,
	data: null,
	form: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): CadastrarSolicitante {
	return produce(state, draft => {
		switch (action.type) {
			case Types.CADASTRAR_SOLICITANTE_REQUEST: {
				draft.form = action.payload;
				break;
			}
			case Types.CADASTRAR_SOLICITANTE_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.CADASTRAR_SOLICITANTE_FAILURE: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.CADASTRAR_SOLICITANTE_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.form = INITIAL_STATE.form;
				break;
			}

			default:
		}

		return draft;
	});
}
