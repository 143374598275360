import { PainelDeControle } from './painelDeControle/types';
import { PesquisaNominal } from './pesquisaNominal/types';
import { consultaAtestadoNominal } from './consultaAtestadoNominal/types';
import { emissaoNominal } from './emissaoNominal/types';
import { pesquisaCitaAAC } from './pesquisaCITA/types';
import { EmissaoAtestadoAntecedentes } from './emissaoAtestadoAntecedentes/types';
import { emissaoNumeral } from './emissaoNumeral/types';
import { EmissaoAtestadoPainelLiberacaoNormal } from './novoPainelDeControle/emissaoAtestadoNormal/types';
import { emissaoExigencias } from './novoPainelDeControle/emissaoExigencias/types';
import { UsuarioPendente } from './novoPainelDeControle/consultaUsuarioPendente/types';
import { enviarCentralNominal } from './enviarCentralNominal/types';
import { EnviarCentralNumeral } from './enviarCentralNumeral/types';
import { EncerrarAtendimento } from './novoPainelDeControle/encerrarAtendimento/types';
import { EmissaoAtestadoLiberacaoPositiva } from './novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/types';
import { consultaCpf } from './consutaCpf/types';

export enum Types {
	AAC_CLEAR = '@aac/AAC_CLEAR',
}

export interface AacState {
	consultaCpf: consultaCpf;
	painelDeControle: PainelDeControle;
	pesquisaNominal: PesquisaNominal;
	consultaAtestadoNominal: consultaAtestadoNominal;
	emissaoAtestadoAntecedentes: EmissaoAtestadoAntecedentes;
	emissaoNominal: emissaoNominal;
	emissaoNumeral: emissaoNumeral;
	emissaoAtestadoPainel: EmissaoAtestadoPainelLiberacaoNormal;
	pesquisaCitaAAC: pesquisaCitaAAC;
	emissaoExigencias: emissaoExigencias;
	atualizaAtendente: UsuarioPendente;
	usuarioPendente: UsuarioPendente;
	enviarCentralNominal: enviarCentralNominal;
	enviarCentralNumeral: EnviarCentralNumeral;
	encerrarAtendimento: EncerrarAtendimento;
	emissaoAtestadoLiberacaoPositiva: EmissaoAtestadoLiberacaoPositiva;
}
