import {
	formatDateISOWithTimeZone,
	limparMascara,
	unformatDateTime,
} from 'utils/genericFunctions';
import { EnviarSolicitacoesPortalRequest } from 'store/modules/api/mp/enviarSolicitacoesPortal/types';
import { ICadastroSolicitante, SguData } from './Forms/form';
import { getIdData } from './utils';

export const treatInitialFormValues = (
	data: ICadastroSolicitante,
): ICadastroSolicitante => {
	return {
		...data,
		filho: {
			...data.filho,
			genitor: {
				...data.filho.genitor,
				dtNascimento: data.filho.genitor.dtNascimento
					? data.filho.genitor.dtNascimento
					: '',
			},
		},
		responsavel: {
			...data.responsavel,
			dtNascimento: data.responsavel?.dtNascimento
				? data.responsavel.dtNascimento
				: '',
		},
	};
};

export function treatEnviarSolicitacoesPortalRequest(
	values: ICadastroSolicitante,
	solicitante: number,
	sguData: SguData,
): EnviarSolicitacoesPortalRequest {
	const { idAtendimento } = getIdData(sguData);
	const { cpf: cpfAtendente } = sguData.login.user;

	let defaultData: EnviarSolicitacoesPortalRequest = {
		// FORMATACAO PADRÃO
		filho: {
			nome: values.filho.nome,
			dataNascimento:
				formatDateISOWithTimeZone(
					new Date(unformatDateTime(values.filho.dtNascimento)),
				)?.toString() || '',
			sexo:
				(values.filho.sexo === 'M' && 'Masculino') ||
				(values.filho.sexo === 'F' && 'Feminino') ||
				'',
			cpf: limparMascara(values.filho.cpf),
			genitor: {
				nome: values.filho.genitor.nome,
				nomeMae: '',
				sexo:
					(values.filho.genitor.sexo === 'M' && 'Masculino') ||
					(values.filho.genitor.sexo === 'F' && 'Feminino') ||
					'',
				outrasInformacoes: values.filho.genitor.outrasInformacoes,
				cidadeNascimento: '',
				cpf: '',
				dataNascimento: '',
				email: '',
				endereco: {
					bairro: '',
					cep: '',
					cidade: '',
					complementoEndereco: '',
					logradouro: '',
					numero: '',
					uf: '',
				},
				rg: {
					numero: '',
					digito: '',
					uf: '',
				},
				telefones: [],
				ufNascimento: '',
			},
			email: values.filho.email || '',
			endereco: {
				logradouro: values.filho.endereco?.logradouro || '',
				numero: values.filho.endereco?.numero || '',
				complementoEndereco: values.filho.endereco?.complemEndereco || '',
				bairro: values.filho.endereco?.bairro || '',
				cep: values.filho.endereco?.cep || '',
				cidade: values.filho.endereco?.cidade || '',
				uf: values.filho.endereco?.uf || '',
			},
			rg: {
				numero: values.filho.rg?.numero ? values.filho.rg?.numero : '',
				digito:
					values.filho.rg?.numero && values.filho.rg?.digito
						? values.filho.rg?.digito
						: '',
				uf:
					values.filho.rg?.numero && values.filho.rg?.uf
						? values.filho.rg?.uf
						: '',
			},
			telefones: [
				{
					ddd: limparMascara(values.filho.telefones?.fixo).substr(0, 2),
					numero: limparMascara(values.filho.telefones?.fixo).substr(2, 8),
					tipo: 1,
				},
				{
					ddd: limparMascara(values.filho.telefones?.celular).substr(0, 2),
					numero: limparMascara(values.filho.telefones?.celular).substr(2, 9),
					tipo: 2,
				},
				{
					ddd: limparMascara(values.filho.telefones?.comercial).substr(0, 2),
					numero: limparMascara(values.filho.telefones?.comercial).substr(2, 8),
					tipo: 3,
				},
			],
		},
		aceiteTermoDeclaracaoPobreza: values.aceiteTermoDeclaracaoPobreza,
		aceiteTermo: values.aceiteTermo,
		aceiteAcrescNomePai: values.aceiteAcrescNomePai,
		idAtendente: cpfAtendente,
		origem: 'P',
		status: values.status ? values.status : '1',
		destinatarioSolicitacao: '',
		idAtendimento: idAtendimento || '',
		protocolo: '',
	};

	defaultData = {
		...defaultData,
		destinatarioSolicitacao: '1',
	};

	if (solicitante === 2) {
		defaultData = {
			...defaultData,
			destinatarioSolicitacao: '2',
			responsavel: {
				...defaultData.responsavel,
				nome: values.responsavel?.nome || '',
				email: values.responsavel?.email || '',
				dataNascimento: values.responsavel?.dtNascimento
					? formatDateISOWithTimeZone(
							new Date(unformatDateTime(values.responsavel.dtNascimento)),
					  )?.toString() || ''
					: '',
				sexo:
					(values.responsavel?.sexo === 'M' && 'Masculino') ||
					(values.responsavel?.sexo === 'F' && 'Feminino') ||
					'',
				cpf: limparMascara(values.responsavel?.cpf),
				rg: {
					numero: values.responsavel?.rg?.numero
						? values.responsavel.rg?.numero
						: '',
					digito:
						values.responsavel?.rg?.numero && values.responsavel.rg?.digito
							? values.responsavel.rg?.digito
							: '',
					uf:
						values.responsavel?.rg?.numero && values.responsavel.rg?.uf
							? values.responsavel.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.responsavel?.telefones?.fixo).substr(
							0,
							2,
						),
						numero: limparMascara(values.responsavel?.telefones?.fixo).substr(
							2,
							8,
						),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.celular).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.celular,
						).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.comercial).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.comercial,
						).substr(2, 8),
						tipo: 3,
					},
				],
				endereco: {
					logradouro: values.responsavel?.endereco?.logradouro || '',
					complementoEndereco:
						values.responsavel?.endereco?.complemEndereco || '',
					numero: values.responsavel?.endereco?.numero || '',
					bairro: values.responsavel?.endereco?.bairro || '',
					cep: values.responsavel?.endereco?.cep || '',
					cidade: values.responsavel?.endereco?.cidade || '',
					uf: values.responsavel?.endereco?.uf || '',
				},
			},
		};
	} else if (solicitante === 3) {
		defaultData = {
			...defaultData,
			destinatarioSolicitacao: '3',
			filho: {
				...defaultData.filho,
				genitor: {
					...defaultData.filho.genitor,
					dataNascimento: values.filho.genitor.dtNascimento
						? formatDateISOWithTimeZone(
								new Date(unformatDateTime(values.filho.genitor.dtNascimento)),
						  )?.toString() || ''
						: '',
					email: values.filho.genitor.email || '',
					cpf: limparMascara(values.filho.genitor.cpf),
					nomeMae: values.filho.genitor.nomeMae || '',
					endereco: {
						logradouro: values.filho.genitor.endereco?.logradouro || '',
						numero: values.filho.genitor.endereco?.numero || '',
						complementoEndereco:
							values.filho.genitor.endereco?.complemEndereco || '',
						bairro: values.filho.genitor.endereco?.bairro || '',
						cep: values.filho.genitor.endereco?.cep || '',
						cidade: values.filho.genitor.endereco?.cidade || '',
						uf: values.filho.genitor.endereco?.uf || '',
					},
					rg: {
						numero: values.filho.genitor.rg?.numero
							? values.filho.genitor.rg?.numero
							: '',
						digito:
							values.filho.genitor.rg?.numero && values.filho.genitor.rg?.digito
								? values.filho.genitor.rg?.digito
								: '',
						uf:
							values.filho.genitor.rg?.numero && values.filho.genitor.rg?.uf
								? values.filho.genitor.rg?.uf
								: '',
					},
					telefones: [
						{
							ddd: limparMascara(values.filho.genitor.telefones?.fixo).substr(
								0,
								2,
							),
							numero: limparMascara(
								values.filho.genitor.telefones?.fixo,
							).substr(2, 8),
							tipo: 1,
						},
						{
							ddd: limparMascara(
								values.filho.genitor.telefones?.celular,
							).substr(0, 2),
							numero: limparMascara(
								values.filho.genitor.telefones?.celular,
							).substr(2, 9),
							tipo: 2,
						},
						{
							ddd: limparMascara(
								values.filho.genitor.telefones?.comercial,
							).substr(0, 2),
							numero: limparMascara(
								values.filho.genitor.telefones?.comercial,
							).substr(2, 8),
							tipo: 3,
						},
					],
				},
			},
			responsavel: {
				...defaultData.responsavel,
				nome: values.responsavel?.nome || '',
				email: values.responsavel?.email || '',
				dataNascimento: values.responsavel?.dtNascimento
					? formatDateISOWithTimeZone(
							new Date(unformatDateTime(values.responsavel.dtNascimento)),
					  )?.toString() || ''
					: '',
				sexo:
					(values.responsavel?.sexo === 'M' && 'Masculino') ||
					(values.responsavel?.sexo === 'F' && 'Feminino') ||
					'',
				cpf: limparMascara(values.responsavel?.cpf),
				rg: {
					numero: values.responsavel?.rg?.numero
						? values.responsavel.rg?.numero
						: '',
					digito:
						values.responsavel?.rg?.numero && values.responsavel.rg?.digito
							? values.responsavel.rg?.digito
							: '',
					uf:
						values.responsavel?.rg?.numero && values.responsavel.rg?.uf
							? values.responsavel.rg?.uf
							: '',
				},
				telefones: [
					{
						ddd: limparMascara(values.responsavel?.telefones?.fixo).substr(
							0,
							2,
						),
						numero: limparMascara(values.responsavel?.telefones?.fixo).substr(
							2,
							8,
						),
						tipo: 1,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.celular).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.celular,
						).substr(2, 9),
						tipo: 2,
					},
					{
						ddd: limparMascara(values.responsavel?.telefones?.comercial).substr(
							0,
							2,
						),
						numero: limparMascara(
							values.responsavel?.telefones?.comercial,
						).substr(2, 8),
						tipo: 3,
					},
				],
				endereco: {
					logradouro: values.responsavel?.endereco?.logradouro || '',
					complementoEndereco:
						values.responsavel?.endereco?.complemEndereco || '',
					numero: values.responsavel?.endereco?.numero || '',
					bairro: values.responsavel?.endereco?.bairro || '',
					cep: values.responsavel?.endereco?.cep || '',
					cidade: values.responsavel?.endereco?.cidade || '',
					uf: values.responsavel?.endereco?.uf || '',
				},
			},
		};
	}

	// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
	const newPhoneListFilho = [];
	for (let i = 0; i < defaultData.filho.telefones.length; i++) {
		if (defaultData.filho.telefones[i].ddd !== '') {
			newPhoneListFilho.push(defaultData.filho.telefones[i]);
		}
	}
	defaultData.filho.telefones = newPhoneListFilho;

	if (defaultData.filho.genitor.telefones?.length) {
		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListGenitor = [];
		for (let i = 0; i < defaultData.filho.genitor.telefones.length; i++) {
			if (defaultData.filho.genitor.telefones[i].ddd !== '') {
				newPhoneListGenitor.push(defaultData.filho.genitor.telefones[i]);
			}
		}
		defaultData.filho.genitor.telefones = newPhoneListGenitor;
	}

	if (defaultData.responsavel?.telefones?.length) {
		// Necessário remover os telefones vazios para envio ao serviço enviarSolicitacaoMP
		const newPhoneListResponsavel = [];
		for (let i = 0; i < defaultData.responsavel.telefones.length; i++) {
			if (defaultData.responsavel.telefones[i].ddd !== '') {
				newPhoneListResponsavel.push(defaultData.responsavel.telefones[i]);
			}
		}
		defaultData.responsavel.telefones = newPhoneListResponsavel;
	}

	return defaultData;
}
