import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, CdhuState } from './types';

// MUTUÁRIO
import consultarMutuario from './consultarMutuario/reducer';

// PRESTAÇÕES ATRASADAS
import consultarPrestacoesAtrasadas from './consultarPrestacoesAtrasadas/reducer';

// BOLETO AGRUPADO
import gerarBoletoAgrupado from './gerarBoletoAgrupado/reducer';

// OCORRÊNCIA
import gerarOcorrencia from './gerarOcorrencia/reducer';

// SOLICITANTE
import cadastrarSolicitante from './cadastrarSolicitante/reducer';
import consultarSolicitante from './consultarSolicitante/reducer';

// ACORDO
import simularEfetuarAcordo from './simularEfetuarAcordo/reducer';

// OBTER FICHA FINANCEIRA
import obterFichaFinanceira from './obterFichaFinanceira/reducer';

// OBTER TOKEN CDHU
import gerarTokenCdhu from './gerarTokenCdhu/reducer';

const allReducers = combineReducers({
	// MUTUÁRIO
	consultarMutuario,

	// PRESTAÇÕES ATRASADAS
	consultarPrestacoesAtrasadas,

	// BOLETO AGRUPADO
	gerarBoletoAgrupado,

	// OCORRÊNCIA
	gerarOcorrencia,

	// SOLICITANTE
	cadastrarSolicitante,
	consultarSolicitante,

	// ACORDO
	simularEfetuarAcordo,

	// OBTER FICHA FINANCEIRA
	obterFichaFinanceira,

	// OBTER TOKEN CDHU
	gerarTokenCdhu
	
});

const combineReducer = (state: CdhuState, action: ReducerAction) => {
	if (action.type === Types.CDHU_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
