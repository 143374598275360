import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_PPT_EVENTOS_API } from 'services/rotasp/path';

// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { EventosPPTContabilizacaoRequest, Types } from './types';

// ACTIONS
import {
	eventosPPTContabilizacaoSuccess,
	eventosPPTContabilizacaoFailure,
} from './actions';

function* eventosPPTContabilizacao(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: EventosPPTContabilizacaoRequest } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_PPT_EVENTOS_API,
		`v1/eventos/contabilizacao`,
		payload,
		undefined,
		undefined,
		undefined,
	);

	if (response.status === 200) {
		yield put(eventosPPTContabilizacaoSuccess(response));
	} else {
		yield put(eventosPPTContabilizacaoFailure(response));
	}
}

export default all([
	takeLatest(
		Types.EVENTOS_PPT_CONTABILIZACAO_REQUEST,
		eventosPPTContabilizacao,
	),
]);
