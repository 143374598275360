import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col } from 'antd';
import { Formik, FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarVeiculoV110Clear,
	consultarVeiculoV110Request,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import {
	consultarRestricaoVeiculoClear,
	consultarRestricaoVeiculoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/actions';
import { IRequestConsultaRestricaoVeiculo } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';
import {
	consultarTaxaServicoClear,
	consultarTaxaServicoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Error from 'components/Common/Notifications/FormError/index';
import PesquisaPadraoCrv from 'pages/DetranCrv/components/pages/PesquisaPadraoCrv';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { IConsultarSegundaViaCrv, initialValues, schema } from './form';

interface ITransferencia {
	chassiOuPlaca: string;
	cpfCnpjProprietario: string | number;
	restricaoFinanceira: string;
}

const SegundaViaCrv: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
	const [tipoRestricao, setTipoRestricao] = useState<ITransferencia>({
		chassiOuPlaca: '',
		cpfCnpjProprietario: '',
		restricaoFinanceira: '',
	});
	const [bodyRestricao, setBodyRestricao] =
		useState<IRequestConsultaRestricaoVeiculo>({
			placa: '',
			documento: '',
			espelho: '',
			renavam: '',
			uf: '',
		});

	const [categoriaOficial, setCategoriaOficial] = useState<string | number>('');

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { consultarRestricaoVeiculo, consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	// const { errors } = useSelector(
	// 	(state: ApplicationState) => state.app.notifications,
	// );

	useEffect(() => {
		if (
			consultarVeiculoV110.status === 200 &&
			consultarVeiculoV110.data &&
			consultarTaxaServico.status === 0 &&
			categoriaOficial !== 3
		) {
			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: '7',
				identificadorTaxa:
					consultarVeiculoV110.data.caracteristicaVeiculo.renavam.toString() ||
					'',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		dispatch,
		consultarTaxaServico.status,
		consultarVeiculoV110.status,
		consultarVeiculoV110.data,
		categoriaOficial,
	]);

	useEffect(() => {
		if (
			consultarVeiculoV110.data &&
			consultarVeiculoV110.status === 200 &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			if (
				consultarVeiculoV110.data?.caracteristicaVeiculo.gravame.restricao !==
				''
			) {
				if (
					tipoRestricao.restricaoFinanceira !==
					consultarVeiculoV110.data?.caracteristicaVeiculo.gravame.restricao
				) {
					setNotificationErrors([
						'ERRO NA CONSISTÊNCIA DE GRAVAMES PARA ESTE VEÍCULO. FAVOR VERIFICAR.',
					]);
				}
			} else {
				const bodyRestricaoReq = {
					placa: consultarVeiculoV110.data?.caracteristicaVeiculo.placa,
					segunda_via_crv: '1',
					documento:
						bodyRestricao.documento ||
						consultarVeiculoV110.data?.caracteristicaVeiculo.proprietario
							.cpfCnpjProprietario,
					espelho: bodyRestricao.espelho || '0',
					renavam:
						consultarVeiculoV110.data?.caracteristicaVeiculo.renavam.toString(),
					uf:
						consultarVeiculoV110.data?.caracteristicaVeiculo.ufPlacaAnterior ||
						'SP',
				};
				if (bodyRestricao.documento && consultarRestricaoVeiculo.status === 0) {
					dispatch(
						consultarRestricaoVeiculoRequest(
							bodyRestricaoReq as IRequestConsultaRestricaoVeiculo,
						),
					);
				}
				if (consultarRestricaoVeiculo.status === 200) {
					history.push('/detran-crv/segunda-via-crv/emitir');
				}
			}
		}
	}, [
		bodyRestricao,
		consultarRestricaoVeiculo.status,
		consultarVeiculoV110,
		dispatch,
		history,
		tipoRestricao,
		consultarTaxaServico.status,
		categoriaOficial,
	]);

	const handleValidate = useCallback((formValues: IConsultarSegundaViaCrv) => {
		return getValidationsErrors(formValues, schema);
	}, []);

	const handleClear = useCallback(
		(formik?: FormikProps<IConsultarSegundaViaCrv>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(clearNotifications());
			setNotificationErrors([]);
			dispatch(consultarVeiculoV110Clear());
			dispatch(consultarRestricaoVeiculoClear());
			dispatch(consultarTaxaServicoClear());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(formValues: IConsultarSegundaViaCrv) => {
			const { chassi, placa, selecao, restricaoFinanceira, documento } =
				formValues;

			handleClear();

			setBodyRestricao({
				placa: placa.toString(),
				documento,
				espelho: '',
			});

			setTipoRestricao({
				chassiOuPlaca:
					selecao === 'Placa' ? placa?.trim() || '' : chassi?.trim() || '',
				cpfCnpjProprietario: documento,
				restricaoFinanceira: restricaoFinanceira || 'NADA_CONSTA',
			});

			dispatch(
				consultarVeiculoV110Request({
					chassi,
					placa,
					tipoConsulta: 'BAIXA_CRV',
				}),
			);
		},
		[dispatch, handleClear],
	);

	return (
		<>
			<Row>
				<Col span={24}>
					{notificationErrors.length > 0 && (
						<Error
							errors={notificationErrors}
							onClose={() => setNotificationErrors([])}
						/>
					)}
					<Section title="Pesquisar">
						<Formik
							validateOnChange={false}
							validateOnBlur={false}
							initialValues={initialValues}
							validate={handleValidate}
							onSubmit={handleSubmit}
						>
							{formik => (
								<PesquisaPadraoCrv
									formik={formik}
									setNotificationErrors={setNotificationErrors}
									notificationErrors={notificationErrors}
									segundaVia
									setCategoriaOficial={setCategoriaOficial}
								/>
							)}
						</Formik>
					</Section>

					<ButtonVoltar route={ROUTE_DETRAN_CRV} />
				</Col>
			</Row>
		</>
	);
};

export default SegundaViaCrv;
