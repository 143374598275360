import { combineReducers } from 'redux';

import listarSolicitacaoPortal from './listarSolicitacaoPortal/reducer';
import preCadastro from './preCadastro/reducer';
import enviarSolicitacoesPortal from './enviarSolicitacoesPortal/reducer';
import atualizarSolicitacaoPortal from './atualizarSolicitacaoPortal/reducer';
import enviarSolicitacaoMP from './enviarSolicitacaoMP/reducer';
import impressaoTermoPaternidade from './impressaoTermoPaternidade/reducer';
import impressaoGeraSenha from './impressaoGeraSenha/reducer';
import eventoInicio from './eventoInicio/reducer';
import eventoTermino from './eventoTermino/reducer';

export default combineReducers({
	enviarSolicitacoesPortal,
	atualizarSolicitacaoPortal,
	enviarSolicitacaoMP,
	listarSolicitacaoPortal,
	preCadastro,
	impressaoTermoPaternidade,
	impressaoGeraSenha,
	eventoInicio,
	eventoTermino,
});
