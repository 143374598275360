import { ReducerActionRotaSP } from 'store/modules/types';

import { EventosPPTContabilizacaoRequest, Types } from './types';

export function eventosPPTContabilizacaoRequest(
	token: string,
	data: EventosPPTContabilizacaoRequest,
): ReducerActionRotaSP {
	return {
		type: Types.EVENTOS_PPT_CONTABILIZACAO_REQUEST,
		token,
		payload: data,
	};
}
export function eventosPPTContabilizacaoSuccess(
	payload: any,
): ReducerActionRotaSP {
	return {
		type: Types.EVENTOS_PPT_CONTABILIZACAO_SUCCESS,
		payload,
	};
}
export function eventosPPTContabilizacaoFailure(
	payload: any,
): ReducerActionRotaSP {
	return {
		type: Types.EVENTOS_PPT_CONTABILIZACAO_FAILURE,
		payload,
	};
}

export function eventosPPTContabilizacaoClear(): { type: string } {
	return {
		type: Types.EVENTOS_PPT_CONTABILIZACAO_CLEAR,
	};
}
