export enum Types {
	REMOVER_INSCRICAO_REQUEST = '@EDUCACAO/REMOVER_INSCRICAO_REQUEST',
	REMOVER_INSCRICAO_SUCCESS = '@EDUCACAO/REMOVER_INSCRICAO_SUCCESS',
	REMOVER_INSCRICAO_FAILURE = '@EDUCACAO/REMOVER_INSCRICAO_FAILURE',
}

export interface RemoverInscricao {
	status: number;
	data: null | RemoverInscricaoData;
}

export interface RemoverInscricaoData {
	aluno: Aluno;
	anoLetivo: string;
	codigoEscola: string;
	tipoInscricao: string;
}

interface Aluno {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
}
