import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Skeleton } from 'antd';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	consultarVeiculoV110Clear,
	consultarVeiculoV110Request,
} from 'store/modules/api/detranCrv/SolicitarDocumentos/ConsultarVeiculoV110/actions';
import {
	consultarRestricaoVeiculoClear,
	consultarRestricaoVeiculoRequest,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/actions';
import { IRequestConsultaRestricaoVeiculo } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarRestricaoVeiculo/types';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCrv/enum/consultaCategoria/actions';
import { ConsultarTaxaServicoRequest } from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/types';
import {
	consultarTaxaServicoRequest,
	consultarTaxaServicoClear,
} from 'store/modules/api/detranCrv/ConsultarRestricoes/consultarTaxaServico/actions';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Error from 'components/Common/Notifications/FormError/index';
import PesquisaPadraoCrv from 'pages/DetranCrv/components/pages/PesquisaPadraoCrv';

// ROUTES
import { ROUTE_DETRAN_CRV } from 'routes/paths';
import { ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONFIRMACAO } from 'pages/DetranCrv/routes/paths';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

// FORM
import { ITransferenciaMunicipio, initialValues, schema } from './form';

const TransferenciaMunicipioEstado: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [valuesState, setValuesState] = useState(initialValues);
	const [notificationErrors, setNotificationErrors] = useState<string[]>([]);
	const [categoriaOficial, setCategoriaOficial] = useState<string | number>('');

	const { consultarVeiculoV110 } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.solicitarDocumentos,
	);

	const { consultarRestricaoVeiculo, consultarTaxaServico } = useSelector(
		(state: ApplicationState) => state.api.detranCrv.consultarRestricoes,
	);

	useEffect(() => {
		dispatch(consultaCategoriaRequest());
	}, [dispatch]);

	useEffect(() => {
		if (
			consultarVeiculoV110.status === 200 &&
			consultarVeiculoV110.data &&
			consultarTaxaServico.status === 0 &&
			categoriaOficial !== 3
		) {
			const dataConsultarTaxaServico: ConsultarTaxaServicoRequest = {
				codigoTaxaServico: '3',
				identificadorTaxa:
					consultarVeiculoV110.data.caracteristicaVeiculo.renavam.toString() ||
					'',
				despachante: '',
			};

			dispatch(consultarTaxaServicoRequest(dataConsultarTaxaServico));
		}
	}, [
		valuesState,
		dispatch,
		consultarTaxaServico.status,
		consultarVeiculoV110.status,
		consultarVeiculoV110.data,
		categoriaOficial,
	]);

	useEffect(() => {
		let bodyRestricaoReq: IRequestConsultaRestricaoVeiculo = {
			placa: '',
			documento: '',
			espelho: '',
		};
		if (
			consultarVeiculoV110.status === 200 &&
			(consultarTaxaServico.status === 200 || categoriaOficial === 3)
		) {
			if (
				valuesState.restricaoFinanceira !==
				consultarVeiculoV110.data?.caracteristicaVeiculo.gravame.restricao
			) {
				setNotificationErrors([
					'ERRO NA CONSISTÊNCIA DE GRAVAMES PARA ESTE VEÍCULO. FAVOR VERIFICAR.',
				]);
			} else {
				bodyRestricaoReq = {
					placa: consultarVeiculoV110.data?.caracteristicaVeiculo.placa || '',
					documento:
						valuesState.documento ||
						consultarVeiculoV110.data?.caracteristicaVeiculo.proprietario.cpfCnpjProprietario.toString() ||
						'',
					espelho: valuesState.espelho,
					renavam:
						consultarVeiculoV110.data?.caracteristicaVeiculo.renavam.toString(),
					uf: consultarVeiculoV110.data?.caracteristicaVeiculo.ufPlacaAnterior,
				};
				if (valuesState.documento && consultarRestricaoVeiculo.status === 0) {
					dispatch(
						consultarRestricaoVeiculoRequest(
							bodyRestricaoReq as IRequestConsultaRestricaoVeiculo,
						),
					);
				}
				if (consultarRestricaoVeiculo.status === 200) {
					history.push({
						pathname: ROUTE_DETRAN_TRANSFERENCIA_MUNICIPIO_CONFIRMACAO,
						state: {
							espelho: valuesState.espelho,
							cpf: valuesState.documento,
						},
					});
				}
			}
		}
	}, [
		consultarVeiculoV110,
		history,
		valuesState,
		dispatch,
		consultarRestricaoVeiculo,
		valuesState.restricaoFinanceira,
		consultarTaxaServico.status,
		categoriaOficial,
	]);

	const handleClear = useCallback(
		(formik?: FormikProps<ITransferenciaMunicipio>) => {
			if (formik) {
				formik.handleReset();
			}
			dispatch(clearNotifications());
			setNotificationErrors([]);
			dispatch(consultarVeiculoV110Clear());
			dispatch(consultarRestricaoVeiculoClear());
			dispatch(consultarTaxaServicoClear());
		},
		[dispatch],
	);

	const handleSubmit = useCallback(
		(values: ITransferenciaMunicipio) => {
			handleClear();
			setValuesState(values);
			const body = {
				placa: values.placa,
				chassi: values.chassi,
				tipoConsulta: values.tipoConsulta,
				restricaoFinanceira: values.restricaoFinanceira,
			};
			dispatch(consultarVeiculoV110Request(body));
		},
		[dispatch, handleClear],
	);

	return (
		<Suspense fallback={<Skeleton paragraph={{ rows: 4 }} />}>
			{notificationErrors.length > 0 && (
				<Error
					errors={notificationErrors}
					onClose={() => setNotificationErrors([])}
				/>
			)}
			<Section title="Pesquisar">
				<Formik
					validateOnChange={false}
					validateOnBlur={false}
					initialValues={initialValues}
					validate={values => getValidationsErrors(values, schema)}
					onSubmit={handleSubmit}
				>
					{(formik: FormikProps<any>) => {
						return (
							<PesquisaPadraoCrv
								formik={formik}
								setNotificationErrors={setNotificationErrors}
								notificationErrors={notificationErrors}
								setCategoriaOficial={setCategoriaOficial}
							/>
						);
					}}
				</Formik>
			</Section>
			<ButtonVoltar route={ROUTE_DETRAN_CRV} />
		</Suspense>
	);
};

export default TransferenciaMunicipioEstado;
