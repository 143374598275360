import { IAluno, IEvento } from '../../types';

export enum Types {
	CADASTRAR_RESPONSAVEL_REQUEST = '@EDUCACAO/CADASTRAR_RESPONSAVEL_REQUEST',
	CADASTRAR_RESPONSAVEL_SUCCESS = '@EDUCACAO/CADASTRAR_RESPONSAVEL_SUCCESS',
	CADASTRAR_RESPONSAVEL_FAILURE = '@EDUCACAO/CADASTRAR_RESPONSAVEL_FAILURE',
	LAT_LNG_SELECIONADA = '@EDUCACAO/LAT_LNG_SELECIONADA',
	CADASTRAR_RESPONSAVEL_CLEAR = '@EDUCACAO/CADASTRAR_RESPONSAVEL_CLEAR',
	PRE_CADASTRO_RESPONSAVEL_REQUEST = '@EDUCACAO/PRE_CADASTRO_RESPONSAVEL_REQUEST',
	PRE_CADASTRO_RESPONSAVEL_CLEAR = '@EDUCACAO/PRE_CADASTRO_RESPONSAVEL_CLEAR',
}

export interface CadastrarResponsavel {
	status: number;
	data: CadastrarResponsavelData | null;
	latlng: LatLngSelecionada | null;
	form?: CadastrarResponsavelData | null;
}

export interface CadastrarResponsavelData {
	evento?: IEvento;
	// atualizacao?: boolean;
	documentosAluno?: CadastroResponsavelDocumentosAluno;
	aluno?: IAluno;
	responsavel: Responsavel;
}

export interface Responsavel {
	cpf: string;
	nome: string;
	tipoOrigem: string;
	nrRG: string;
	digitoRG: string;
	ufRG: string;
	rnm: string;
	codResponsabilidade: string;
	codSexo: string;
	codEstadoCivil: string;
	emailResponsavel: string;
	dataNascimento: string;
	cidadeNascimento: string;
	ufNascimento: string;
	nomePaisNascimento: string;
	codPaisNascimento?: string;
	enderecoResidencial: EnderecoResponsavel;
	telefones: TelefoneResponsavel[];
}

export interface CadastroResponsavelDocumentosAluno {
	numeroCpf: string;
	numeroRg: string;
	siglaUfRg: string;
}

export interface EnderecoResponsavel {
	logradouro: string;
	numero: string;
	areaLogradouro: string;
	complemento: string;
	bairro: string;
	cep: string;
	nomeCidade: string;
	ufCidade: string;
	latitude: string;
	longitude: string;
	// codLocalizacaoDiferenciada: string;
}

export interface TelefoneResponsavel {
	dddNumero: string;
	numero: string;
	tipoTelefone: string | number;
	complemento: string;
	// sms: string;
}
export interface LatLngSelecionada {
	lat: number;
	lng: number;
}
