export enum Types {
	CONSULTAR_BCADASTRO_REQUEST = '@rfb/integrador/CONSULTAR_BCADASTRO_REQUEST',
	CONSULTAR_BCADASTRO_SUCCESS = '@rfb/integrador/CONSULTAR_BCADASTRO_SUCCESS',
	CONSULTAR_BCADASTRO_FAILURE = '@rfb/integrador/CONSULTAR_BCADASTRO_FAILURE',
	CONSULTAR_BCADASTRO_CLEAR = '@rfb/integrador/CONSULTAR_BCADASTRO_CLEAR',
}

export interface ConsultarBCadastroState {
	status: number;
	data: ConsultarBCadastro | null;
}

export interface IRequestWithHeader {
	body: ConsultarBCadastroRequest;
	headers: any;
}

export interface ConsultarBCadastroRequest {
	cpfidentificacao: string;
}

export interface ConsultarBCadastro {
	_id: string;
	_rev: string;
	anoExerc: string;
	bairro: string;
	cep: string;
	codMunDomic: string;
	codNatOcup: string;
	codOcup: 0;
	codSexo: string;
	codSitCad: string;
	codUA: string;
	complemento: string;
	cpfId: string;
	dtInscricao: string;
	dtNasc: string;
	dtUltAtualiz: string;
	indEstrangeiro: string;
	indResExt: string;
	logradouro: string;
	nomeContribuinte: string;
	nomeMae: string;
	nroLogradouro: string;
	telefone: string;
	tipoLogradouro: string;
	ufMunDomic: string;
}
