import produce from 'immer';
import { ReducerAction } from 'store/modules/types';

// TYPES
import { Types, EventosPPTContabilizacao } from './types';

export const INITIAL_STATE: EventosPPTContabilizacao = {
	status: 0,
	data: null,
	dataFailure: null,
	detailsEventRequest: {
		historyExecutedEvents: [],
	},
	detailsStartSection: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): EventosPPTContabilizacao {
	return produce(state, draft => {
		switch (action.type) {
			case Types.EVENTOS_PPT_CONTABILIZACAO_REQUEST: {
				draft.status = 100;
				draft.data = INITIAL_STATE.data;
				draft.dataFailure = INITIAL_STATE.dataFailure;
				draft.detailsEventRequest = draft.detailsEventRequest
					?.historyExecutedEvents.length
					? {
							historyExecutedEvents: [
								...draft.detailsEventRequest?.historyExecutedEvents,
								action.payload.tipo,
							],
					  }
					: {
							historyExecutedEvents: action.payload.tipo
								? [action.payload.tipo]
								: [''],
					  };
				draft.detailsStartSection = {
					cpf: action.payload.cpf || '',
					dataInicio: action.payload.dados?.servicos?.data_inicio || '',
				};
				break;
			}

			case Types.EVENTOS_PPT_CONTABILIZACAO_SUCCESS: {
				draft.data = action.payload.response;
				draft.status = action.payload.status;
				draft.dataFailure = INITIAL_STATE.dataFailure;
				break;
			}

			case Types.EVENTOS_PPT_CONTABILIZACAO_FAILURE: {
				draft.status = action.payload.status || 400;
				draft.dataFailure = action.payload.response || null;
				draft.data = INITIAL_STATE.data;
				break;
			}
			case Types.EVENTOS_PPT_CONTABILIZACAO_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				draft.dataFailure = INITIAL_STATE.dataFailure;
				draft.detailsEventRequest = INITIAL_STATE.detailsEventRequest;
				break;
			}

			default:
		}
	});
}
