export const DESC_ORGAO = 'CDHU';
export const ID_ORGAO_CDHU = 466;

export const BOLETO_AGRUPADO_TOTAL = {
	DESC: 'boleto agrupado total',
	TIPO: 'cdhu_boleto_agrupado_total',
	ID_SERVICO: 1,
};

export const BOLETO_AGRUPADO_PARCELAS = {
	DESC: 'boleto agrupado parcelas',
	TIPO: 'cdhu_boleto_agrupado_parcelas',
	ID_SERVICO: 2,
};

export const ACORDO = {
	DESC: 'acordo orgao',
	TIPO: 'cdhu_acordo_orgao',
	ID_SERVICO: 5,
};

export const INFORMACAO_TRANSF_TITULARIDADE = {
	DESC: 'informacao transferencia titularidade',
	TIPO: 'cdhu_informacao_transferencia_titularidade',
	ID_SERVICO: 6,
};

export const INFORMACAO_SEGURO_HABITACIONAL = {
	DESC: 'informacao seguro habitacional',
	TIPO: 'cdhu_informacao_seguro_habitacional',
	ID_SERVICO: 7,
};

export const INFORMACAO_SALDO_DEVEDOR = {
	DESC: 'informacao saldo devedor',
	TIPO: 'cdhu_informacao_saldo_devedor',
	ID_SERVICO: 8,
};

export const INFORMACAO_SITUACAO_FINANCEIRA = {
	DESC: 'informacao situacao financeira',
	TIPO: 'cdhu_informacao_situacao_financeira',
	ID_SERVICO: 9,
};

export const INFORMACAO_INSCRICOES_PGMS_HABITACIONAIS = {
	DESC: 'informacao inscricoes programas habitacionais',
	TIPO: 'cdhu_informacao_incricoes_programas_habitacionais',
	ID_SERVICO: 10,
};
