import produce from 'immer';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, ObterFichaFinanceira } from './types';

export const INITIAL_STATE: ObterFichaFinanceira = {
	status: 0,
	data: null,
};

export default function modulos(
	state = INITIAL_STATE,
	action: ReducerAction,
): ObterFichaFinanceira {
	return produce(state, draft => {
		switch (action.type) {
			case Types.OBTER_FICHA_FINANCEIRA_REQUEST: {
				draft.status = 100;
				break;
			}
			case Types.OBTER_FICHA_FINANCEIRA_SUCCESS: {
				draft.status = 200;
				draft.data = action.payload;
				break;
			}
			case Types.OBTER_FICHA_FINANCEIRA_FAILURE: {
				draft.status = 400;
				draft.data = null;
				break;
			}
			case Types.OBTER_FICHA_FINANCEIRA_CLEAR: {
				draft.status = INITIAL_STATE.status;
				draft.data = INITIAL_STATE.data;
				break;
			}

			default:
		}

		return draft;
	});
}
