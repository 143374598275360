import React, { useCallback, useContext, useEffect } from 'react';

import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { cdhuClear } from 'store/modules/api/cdhu/actions';
import { eventosPPTContabilizacaoRequest } from 'store/modules/api/utils/eventosPPT/contabilizacao/actions';
import { estatisticaIncluirRequest } from 'store/modules/api/sguService/estatisticasIncluir/actions';

// PATHS
import {
	ROUTE_CDHU_SERVICOS_SUCESSO,
	ROUTE_CDHU_SOLICITANTE_PESQUISAR,
} from 'pages/cdhu/routes/paths';

// UTILS
import { treatRequestEventsContabilizacaoPPT } from 'utils/functions/treatEventosPPTRequest';
import { treatRequestSguResponseEventsCDHU } from 'pages/cdhu/utils/functions/sguStatistics/treatServiceRequest';
import {
	BOLETO_AGRUPADO_PARCELAS,
	BOLETO_AGRUPADO_TOTAL,
	DESC_ORGAO,
	ID_ORGAO_CDHU,
} from 'pages/cdhu/utils/tiposEventos';

// COMPONENTS
import Section from 'components/Common/Section';
import ButtonVoltar from 'components/Common/ButtonVoltar';
import DadosMutuario from '../components/DadosMutuario';
import SituacaoContrato from '../components/SituacaoContrato';
import Calculo from './Calculo';
import Opcoes from './Opcoes';

const BoletoAgrupado: React.FC = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { gerarBoletoAgrupado, consultarMutuario } = useSelector(
		(state: ApplicationState) => state.api.cdhu,
	);

	const { loginUnico, atendimento } = useSelector(
		(state: ApplicationState) => state.api.sgu,
	);

	const { eventosPPTContabilizacao } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	useEffect(() => {
		if (gerarBoletoAgrupado.status === 200 && gerarBoletoAgrupado.form) {
			const eventDataByTypePayment =
				(gerarBoletoAgrupado.form.selectedFormAllInstallments &&
					BOLETO_AGRUPADO_TOTAL) ||
				(gerarBoletoAgrupado.form.selectedFormParcialInstallments &&
					BOLETO_AGRUPADO_PARCELAS) ||
				'';

			if (eventosPPTContabilizacao.status === 0 && eventDataByTypePayment) {
				const treatedEventsPPTRequest = treatRequestEventsContabilizacaoPPT(
					atendimento,
					loginUnico,
					consultarMutuario.data?.cpf || '',
					DESC_ORGAO,
					ID_ORGAO_CDHU,
					eventDataByTypePayment.DESC,
					eventDataByTypePayment.ID_SERVICO,
					eventDataByTypePayment.TIPO,
				);
				dispatch(
					eventosPPTContabilizacaoRequest(token, treatedEventsPPTRequest),
				);
			}
		}
	}, [
		atendimento,
		consultarMutuario.data,
		dispatch,
		eventosPPTContabilizacao.status,
		gerarBoletoAgrupado.form,
		gerarBoletoAgrupado.status,
		loginUnico,
		token,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status === 0 &&
			eventosPPTContabilizacao.status !== 0 &&
			eventosPPTContabilizacao.status !== 100 &&
			gerarBoletoAgrupado.form
		) {
			const eventDataByTypePayment =
				(gerarBoletoAgrupado.form.selectedFormAllInstallments &&
					BOLETO_AGRUPADO_TOTAL) ||
				(gerarBoletoAgrupado.form.selectedFormParcialInstallments &&
					BOLETO_AGRUPADO_PARCELAS) ||
				'';

			if (eventDataByTypePayment) {
				const payload = treatRequestSguResponseEventsCDHU(
					loginUnico,
					atendimento,
					consultarMutuario.data?.cpf || '',
					eventosPPTContabilizacao.status === 200,
					eventDataByTypePayment.DESC,
					eventDataByTypePayment.TIPO,
				);
				dispatch(estatisticaIncluirRequest(payload));
			}
		}
	}, [
		eventosPPTContabilizacao.status,
		estatisticasIncluir.status,
		dispatch,
		atendimento,
		loginUnico,
		consultarMutuario.data,
		gerarBoletoAgrupado.form,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100
		) {
			history.push(ROUTE_CDHU_SERVICOS_SUCESSO);
		}
	}, [dispatch, estatisticasIncluir.status, history]);

	const handleButtonVoltar = useCallback(() => {
		dispatch(cdhuClear());
		history.push(ROUTE_CDHU_SOLICITANTE_PESQUISAR);
	}, [dispatch, history]);

	return (
		<>
			<DadosMutuario />

			<SituacaoContrato />

			<Row gutter={[0, 10]}>
				<Col span={24}>
					<Section size="lg" title="Boleto agrupado" titleSize="sm">
						<Calculo />

						<Opcoes />
					</Section>

					<ButtonVoltar navigate={false} onClick={handleButtonVoltar} />
				</Col>
			</Row>
		</>
	);
};

export default BoletoAgrupado;
