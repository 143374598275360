import React, { useCallback, useEffect, useState } from 'react';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	protocoloFicRequest,
	protocoloFicClear,
} from 'store/modules/api/iirgd/protocoloFic/actions';

// COMPONENTS
import ButtonImage from 'components/Common/ButtonImage';
import PDFViewer from 'components/Common/PDFViewer';

// UTILS
import { formatarProtocoloFIC, getSedex, getTipoCili } from './functions';

interface Props {
	clicked?: () => void;
}

export const ImprimirProtocolo: React.FC<Props> = ({ clicked }) => {
	const dispatch = useDispatch();

	const [PDFTitle, setPDFTitle] = useState<string>('');
	const [protocoloBase64, setProtocoloBase64] = useState<string>('');

	const { login } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico,
	);

	const {
		cadastroRg,
		protocoloFic,
		consultaPesquisaCirg,
		consultaPesquisaCriminal,
		consultaPesquisaCidadao,
		scriptPreAtendimento,
		liberacaoCili,
	} = useSelector((state: ApplicationState) => state.api.iirgd);

	const { disponibilzarDare } = useSelector(
		(state: ApplicationState) => state.api.potencial,
	);

	const { confrontarDigitais, consultarDigitais } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const { capturarBiometria } = useSelector(
		(state: ApplicationState) => state.api.biometria,
	);

	const { dadosAgendamento } = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento,
	);

	const getChanges = useCallback(() => {
		let response = '';
		if (scriptPreAtendimento.adocao) {
			response = `AD`;
		}
		if (scriptPreAtendimento.paternidadeReconhecida) {
			response = `${response !== '' ? `${response} | ` : ''}PM`;
		}
		if (scriptPreAtendimento.tranGenero) {
			response = `${response !== '' ? `${response} | ` : ''}TG`;
		}
		if (scriptPreAtendimento.outros) {
			response = `${response !== '' ? `${response} | ` : ''}O`;
		}
		return response;
	}, [
		scriptPreAtendimento.adocao,
		scriptPreAtendimento.paternidadeReconhecida,
		scriptPreAtendimento.tranGenero,
		scriptPreAtendimento.outros,
	]);

	useEffect(() => {
		if (protocoloFic.status === 200 && protocoloFic.data) {
			setPDFTitle('PROTOCOLO PARA SIMPLES CONFERÊNCIA DE DADOS');
			setProtocoloBase64(protocoloFic.data.arquivoPdf);
			if (clicked) clicked();
		}
	}, [protocoloFic, clicked]);

	useEffect(() => {
		dispatch(protocoloFicClear());
	}, [dispatch]);

	const imprimirProtocolo = useCallback(async () => {
		if (protocoloFic.status !== 200) {
			let payload = formatarProtocoloFIC(
				login,
				cadastroRg,
				getSedex(consultaPesquisaCirg, cadastroRg),
				disponibilzarDare.data?.codigo ?? '',
				consultaPesquisaCriminal.restricaoCriminal,
				getTipoCili(
					consultaPesquisaCidadao,
					cadastroRg,
					confrontarDigitais,
					capturarBiometria,
					consultarDigitais,
					consultaPesquisaCriminal.restricaoCriminal,
				),
				confrontarDigitais.resultado,
				dadosAgendamento,
				liberacaoCili.data ? liberacaoCili.data.codigoRetorno === '00' : false,
			);
			if (
				payload &&
				scriptPreAtendimento.preAtendimentoValido &&
				scriptPreAtendimento.teveAlteracao
			) {
				const alterouNome = getChanges();
				payload = { ...payload, cidadao: { ...payload.cidadao, alterouNome } };
			}
			if (payload) dispatch(protocoloFicRequest(payload));
			return;
		}

		if (protocoloFic.status === 200 && protocoloFic.data) {
			setPDFTitle('PROTOCOLO PARA SIMPLES CONFERÊNCIA DE DADOS');
			setProtocoloBase64(protocoloFic.data.arquivoPdf);
			if (clicked) clicked();
		}
	}, [
		liberacaoCili,
		cadastroRg,
		capturarBiometria,
		clicked,
		confrontarDigitais,
		consultaPesquisaCidadao,
		consultaPesquisaCirg,
		consultaPesquisaCriminal.restricaoCriminal,
		dispatch,
		disponibilzarDare,
		getChanges,
		login,
		protocoloFic.data,
		protocoloFic.status,
		scriptPreAtendimento.preAtendimentoValido,
		scriptPreAtendimento.teveAlteracao,
		dadosAgendamento,
		consultarDigitais,
	]);

	const handleClick = useCallback(() => {
		imprimirProtocolo();
	}, [imprimirProtocolo]);

	const handleOnClose = useCallback(() => {
		setProtocoloBase64('');

		if (clicked) clicked();
	}, [clicked]);

	return (
		<>
			<ButtonImage
				type="button"
				src="imprimir-protocolo"
				onClick={handleClick}
			/>

			{PDFTitle && protocoloBase64 && (
				<PDFViewer
					title={PDFTitle}
					source={protocoloBase64}
					onClose={handleOnClose}
				/>
			)}
		</>
	);
};
