import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// ANTD
import { Row, Col } from 'antd';

// ROUTES
import { ROUTE_DETRAN_CNH } from 'routes/paths';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	domainServiceNaturalidadesClear,
	domainServiceNaturalidadesRequest,
} from 'store/modules/api/sguService/domainService/naturalidade/actions';
import { consultaMunicipiosClear } from 'store/modules/api/detranCnh/condutor/consultaMunicipios/actions';
import { limparMascara, showComponent } from 'utils/genericFunctions';
import { DadosCidadaoRequest } from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/types';
import {
	dadosCidadaoClear,
	dadosCidadaoRequest,
} from 'store/modules/api/detranCnh/divisaoEquitativa/dadosCidadao/actions';
import { consultarInaptoRequest } from 'store/modules/api/detranCnh/sockets/consultarInapto/actions';
import { consultaCategoriaRequest } from 'store/modules/api/detranCnh/consulta/categoria/actions';
import {
	consultaCEPClear,
	consultaCEPRequest,
} from 'store/modules/api/detranCnh/consulta/cep/actions';
import {
	estatisticaIncluirClear,
	estatisticaIncluirRequest,
} from 'store/modules/api/sguService/estatisticasIncluir/actions';
import {
	consultaCidadesUFClear,
	consultaCidadesUFRequest,
} from 'store/modules/api/utils/consultaCidadesUF/actions';
import { addNotifications } from 'store/modules/app/notifications/actions';

// PATHS
import { ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH } from 'pages/DetranCnh/routes/paths';

// UTILS
import { treatRequestSguStatisticsAlterarRenachGerado } from 'pages/DetranCnh/utils/functions/sguStatistics/treatServiceRequest';
import { CNH_PROCESS_VALIDATION } from 'pages/DetranCnh/utils/constants/messages';

// COMPONENTS COMMON
import ButtonVoltar from 'components/Common/ButtonVoltar';
import ButtonImage from 'components/Common/ButtonImage';

// FORM
import { FormCnhEstrangeiro } from 'pages/DetranCnh/components/pages/PreCadastroEstrangeiro/StatusCondutor/form';
import { consultaCondutorClear } from 'store/modules/api/detranCnh/condutor/consultaCondutor/actions';
import {
	alterarCadastroClear,
	alterarCadastroRequest,
} from 'store/modules/api/detranCnh/renachIntegrado/alterarCadastro/actions';
import Alert from 'components/Common/Notifications/Alert';
import AgendamentoMedico from '../AgendamentoMedico';
import AgendamentoPsicologico from '../AgendamentoPsicologico';
import { schema, treatInitialValues, treatValuesIntegrado } from './form';

// COMPONENTS
import StatusCondutor from '../../components/pages/PreCadastroEstrangeiro/StatusCondutor';

// TYPES UTILS
import { enumFormUse } from '../../../../utils/constants/types';
import { ICadastraExameMedico } from '../AgendamentoMedico/form';

export function AlterarRenachGerado() {
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const dadosAtendimento = useSelector(
		(state: ApplicationState) => state.api.sgu.atendimento.salvarAtendimento,
	);

	const { consultaCidadesUF } = useSelector(
		(state: ApplicationState) => state.api.utils,
	);

	const listaMunicipios = useSelector(
		(state: ApplicationState) => state.api.utils.consultaCidadesUF.data,
	);

	const { alterarCadastro } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { consultarInapto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.sockets,
	);

	const { consultaCondutor } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.condutor,
	);

	const { dadosCidadao } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.divisaoEquitativa,
	);

	const { consultaCEP, consultaCategoria } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.consulta,
	);

	const { consultaProcessoAberto } = useSelector(
		(state: ApplicationState) => state.api.detranCnh.renachIntegrado,
	);

	const { consultaNaturalidades } = useSelector(
		(state: ApplicationState) => state.api.sguService.domainService,
	);

	const { estatisticasIncluir } = useSelector(
		(state: ApplicationState) => state.api.sguService,
	);

	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const [dadosPreCadastro, setDadosPreCadastro] = useState(
		treatInitialValues(null, null, null, null),
	);
	const [requestData, setRequestData] = useState<null | {
		requestValues: FormCnhEstrangeiro;
		cidadao: DadosCidadaoRequest;
	}>(null);
	const [medicoData, setMedicoData] = useState<ICadastraExameMedico | null>(
		null,
	);
	const [clicked, setClicked] = useState(false);

	const [formToShow, setFormToShow] = useState<null | string>(null);

	const handleBackButton = () => {
		setRequestData(null);
		setFormToShow(null);
		dispatch(dadosCidadaoClear());
		dispatch(alterarCadastroClear());
		dispatch(estatisticaIncluirClear());
	};

	const handleSubmit = useCallback(
		(values: FormCnhEstrangeiro) => {
			if (!values.codigoUnidadeTransito) {
				addNotifications({
					errors: CNH_PROCESS_VALIDATION.CIRETRAN_INVALIDA,
				});
				return;
			}

			const cidadao = {
				cidadao: {
					autoriza_envio_email: values.autorizaEnvioEmail === 'S',
					autoriza_envio_sms: values.autorizaEnvioSms === 'S',
					cpf: Number(values.cpf) || 0,
					celular: limparMascara(values.celular).substring(2, 11),
					ddd_celular: limparMascara(values.celular).substring(0, 2),
					ddd_telefone: limparMascara(values.telefone).substring(0, 2),
					telefone: limparMascara(values.telefone).substring(2, 11),
					email: values.email || '',
					necessidade_veiculo_adaptado: false,
					nome: values.nomeSocial || values.nome,
					pretende_atividade_remunerada: values.flagAtividadeRemunerada === 'S',
				},
			};

			setClicked(true);
			setRequestData({ requestValues: values, cidadao });
			dispatch(alterarCadastroClear());
		},
		[dispatch],
	);

	useEffect(() => {
		dispatch(domainServiceNaturalidadesClear());
		dispatch(consultaMunicipiosClear());
		dispatch(consultaCEPClear());
		dispatch(dadosCidadaoClear());
		dispatch(alterarCadastroClear());
		dispatch(estatisticaIncluirClear());
		dispatch(consultaCidadesUFClear());
		return () => {
			dispatch(consultaCondutorClear());
		};
	}, [dispatch]);

	useEffect(() => {
		if (
			consultaCondutor.status === 200 &&
			consultaCondutor.data &&
			consultaCategoria.status === 0
		) {
			const funcionaldiade = Object.keys(enumFormUse).map((key: any) => {
				if (
					consultaCondutor.data &&
					enumFormUse[key as keyof typeof enumFormUse] ===
						consultaCondutor.data.usoFormulario.trim().replaceAll('0', '')
				) {
					return key;
				}
				return null;
			});
			dispatch(
				consultaCategoriaRequest({
					funcionalidade: funcionaldiade[0] || '',
				}),
			);
		}
	}, [
		consultaCategoria.status,
		consultaCondutor.data,
		consultaCondutor.status,
		dispatch,
	]);

	// Consulta Naturalidade
	useEffect(() => {
		if (
			consultaCondutor.status === 200 &&
			consultaCondutor.data &&
			consultaCondutor.data.nacionalidade === '1'
		) {
			dispatch(
				domainServiceNaturalidadesRequest({
					idIbge: consultaCondutor.data.naturalidade.trim(),
				}),
			);
		}
	}, [consultaCondutor.data, consultaCondutor.status, dispatch]);

	// GET COMBO DE NATURALIDADE DE ACORDO COM A UF DO IBGE
	useEffect(() => {
		if (
			consultaNaturalidades.status === 200 &&
			consultaNaturalidades.data &&
			consultaNaturalidades.data[0].municipioIBGE.uf &&
			consultaCidadesUF.status === 0
		) {
			dispatch(
				consultaCidadesUFRequest(
					consultaNaturalidades.data[0].municipioIBGE.uf,
				),
			);
		}
	}, [
		consultaNaturalidades.status,
		consultaNaturalidades.data,
		dispatch,
		consultaCidadesUF.status,
	]);

	useEffect(() => {
		if (
			consultaCondutor.status === 200 &&
			consultaCondutor.data &&
			consultaCEP.status === 0
		) {
			dispatch(
				consultaCEPRequest({
					cep: consultaCondutor.data.cep.trim().padStart(8, '0'),
				}),
			);
		}
	}, [
		consultaCEP.status,
		consultaCondutor.data,
		consultaCondutor.status,
		dispatch,
	]);

	useEffect(() => {
		if (
			consultaCEP.status !== 0 &&
			consultaCEP.status !== 100 &&
			consultaProcessoAberto.data &&
			consultaCondutor.data
		) {
			const endereco = consultaCEP.data || null;
			const uf = consultaNaturalidades.data
				? consultaNaturalidades.data[0].municipioIBGE.uf
				: null;

			const newValues = treatInitialValues(
				consultaProcessoAberto.data,
				consultaCondutor.data,
				uf,
				endereco,
			);

			setDadosPreCadastro(newValues);
		}
	}, [
		consultaCEP.status,
		consultaCEP.data,
		consultaCondutor.data,
		consultaNaturalidades.data,
		consultaProcessoAberto.data,
	]);

	// ESTATISTICA SGU CAMPOS SELECIONADOS - ANTES DE GERAR RENACH
	useEffect(() => {
		if (
			consultaCondutor.data &&
			requestData?.requestValues &&
			estatisticasIncluir.status === 0 &&
			alterarCadastro.status === 0
		) {
			const payload = treatRequestSguStatisticsAlterarRenachGerado(
				user,
				dadosAtendimento,
				requestData.requestValues,
				consultaCondutor.data,
				'SELECTED_FIELDS',
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		alterarCadastro.status,
		consultaCondutor.data,
		dadosAtendimento,
		dispatch,
		estatisticasIncluir.status,
		requestData,
		user,
	]);

	useEffect(() => {
		if (
			estatisticasIncluir.status !== 0 &&
			estatisticasIncluir.status !== 100 &&
			alterarCadastro.status === 0 &&
			requestData?.cidadao
		) {
			dispatch(dadosCidadaoRequest(requestData.cidadao));
		}
	}, [
		alterarCadastro.status,
		dispatch,
		estatisticasIncluir.status,
		requestData,
	]);

	// GERAR RENACH
	useEffect(() => {
		if (clicked && dadosCidadao.status === 200 && requestData?.requestValues) {
			setClicked(false);
			if (
				showComponent(
					[
						enumFormUse.SEGUNDA_VIA,
						enumFormUse.RENOVACAO,
						enumFormUse.ADICAO_MUDANCA,
					],
					dadosPreCadastro.usoFormularioEnum,
				) &&
				requestData.requestValues.flagAtividadeRemunerada === 'S' &&
				consultaCondutor.data?.pretendeAtividadeRemunerada.trim() !== 'S'
			) {
				setFormToShow('psicologico');
			} else {
				dispatch(
					alterarCadastroRequest(
						treatValuesIntegrado(requestData.requestValues, user),
					),
				);
				dispatch(estatisticaIncluirClear());
			}
		}
	}, [
		alterarCadastro.status,
		consultaCondutor.data,
		dadosPreCadastro.flagAtividadeRemunerada,
		dadosPreCadastro.usoFormularioEnum,
		dispatch,
		requestData,
		user,
		clicked,
		dadosCidadao.status,
	]);

	// REGISTRA ESTATISTICA SGU APÓS GERAR O RENACH - NUMERO RENACH OU MSG ERRO.
	useEffect(() => {
		if (
			consultaCondutor.data &&
			requestData?.requestValues &&
			alterarCadastro.status !== 0 &&
			alterarCadastro.status !== 100 &&
			(alterarCadastro.data || alterarCadastro.dataError)
		) {
			const payload = treatRequestSguStatisticsAlterarRenachGerado(
				user,
				dadosAtendimento,
				requestData.requestValues,
				consultaCondutor.data,
				'EDITED_RENACH',
				alterarCadastro,
			);
			dispatch(estatisticaIncluirRequest(payload));
		}
	}, [
		dadosAtendimento,
		dispatch,
		alterarCadastro,
		user,
		consultaCondutor.data,
		requestData,
	]);

	useEffect(() => {
		if (alterarCadastro.status === 200 && requestData?.cidadao?.cidadao) {
			if (
				consultaCondutor.data &&
				showComponent(
					[enumFormUse.RENOVACAO, enumFormUse.ADICAO_MUDANCA],
					consultaCondutor.data.usoFormulario.trim().replaceAll('0', '') || '',
				)
			) {
				if (
					requestData?.requestValues?.flagAtividadeRemunerada === 'S' &&
					consultaCondutor.data?.pretendeAtividadeRemunerada === 'N' &&
					consultarInapto.status === 0
				) {
					const inapto = {
						cpf: requestData.cidadao.cidadao.cpf.toString(),
						tipoExame: 'AMBOS',
						flagDeficienteFisico:
							requestData.cidadao.cidadao.necessidade_veiculo_adaptado,
					};
					dispatch(consultarInaptoRequest(inapto));
				}

				if (requestData?.requestValues?.cpf) {
					history.push({
						pathname: ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH,
						state: { cpf: requestData.requestValues.cpf },
					});
				}
			} else {
				history.push({
					pathname: ROUTE_DETRAN_CNH_ALTERAR_RENACH_RENACH,
					state: { cpf: requestData?.requestValues?.cpf || '' },
				});
			}
		}
	}, [
		alterarCadastro.status,
		consultaCondutor.data,
		consultarInapto.status,
		dispatch,
		history,
		requestData,
	]);

	return (
		<>
			{alterarCadastro.status &&
			alterarCadastro.status !== 0 &&
			alterarCadastro.status !== 100 &&
			alterarCadastro.status !== 200 &&
			alterarCadastro.status !== 201 &&
			alterarCadastro.dataError?.mensagem &&
			((notifications.errors && !notifications.errors.length) ||
				(notifications.errors.length > 0 &&
					Array.isArray(notifications.errors) &&
					notifications.errors[0] !== alterarCadastro.dataError?.mensagem) ||
				(notifications.errors.length > 0 &&
					!Array.isArray(notifications.errors) &&
					notifications.errors !== alterarCadastro.dataError?.mensagem)) ? (
				// eslint-disable-next-line react/jsx-indent
				<Alert
					type="error"
					message={alterarCadastro.dataError?.mensagem}
					onClose={() => {}}
				/>
			) : null}
			{!formToShow && (
				<StatusCondutor
					dadosPreCadastro={dadosPreCadastro}
					handleSubmitWithToken={handleSubmit}
					handleSubmitWithoutToken={handleSubmit}
					schema={schema}
					listaMunicipios={listaMunicipios}
				/>
			)}
			{(formToShow === 'medico' || formToShow === 'ambos') && (
				<>
					<AgendamentoMedico
						dadosCidadao={requestData?.requestValues || dadosPreCadastro}
						setMedicoData={setMedicoData}
						setFormToShow={setFormToShow}
						formToShow={formToShow}
					/>
					<Row justify="space-between" align="middle">
						<Col>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									handleBackButton();
								}}
							/>
						</Col>
						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push(ROUTE_DETRAN_CNH)}
							/>
						</Col>
					</Row>
				</>
			)}
			{formToShow === 'psicologico' && (
				<>
					<AgendamentoPsicologico
						dadosCidadao={requestData?.requestValues || dadosPreCadastro}
						medicoData={medicoData}
					/>
					<Row justify="space-between" align="middle">
						<Col>
							<ButtonVoltar
								navigate={false}
								onClick={() => {
									handleBackButton();
								}}
							/>
						</Col>
						<Col>
							<ButtonImage
								type="button"
								src="outro-servico-detran"
								onClick={() => history.push(ROUTE_DETRAN_CNH)}
							/>
						</Col>
					</Row>
				</>
			)}
		</>
	);
}
