export enum Types {
	ENVIAR_SOLICITACAO_MP_REQUEST = '@MP/ENVIAR_SOLICITACAO_MP_REQUEST',
	ENVIAR_SOLICITACAO_MP_SUCCESS = '@MP/ENVIAR_SOLICITACAO_MP_SUCCESS',
	ENVIAR_SOLICITACAO_MP_FAILURE = '@MP/ENVIAR_SOLICITACAO_MP_FAILURE',
	LIMPAR_SOLICITACAO_MP = '@MP/LIMPAR_SOLICITACAO_MP',
}

export interface enviarSolicitacaoMP {
	status: number;
	data: enviarSolicitacaoMPData;
}

export interface enviarSolicitacaoMPData {
	protocolo: string;
}

interface Phone {
	ddd: string;
	numero: string;
	tipo: number;
}

interface Address {
	bairro: string;
	cep: string;
	cidade: string;
	complementoEndereco: string;
	logradouro: string;
	numero: string;
	uf: string;
}

export interface IRequestEnviarSolicitacaoMP {
	data: string;
	interessado: {
		cpf: string;
		dataNascimento: string;
		email: string;
		endereco: Address;
		nome: string;
		rg: string;
		ufRg: string;
		sexo: string;
		telefones: Phone[];
	};
	genitor: {
		cpf: string;
		dataNascimento: string;
		email: string;
		endereco: Address;
		localNascimento: string;
		nome: string;
		nomeMae: string;
		rg: string;
		ufRg: string;
		sexo: string;
		telefones: Phone[];
	};
	informacoesAdicionais: string;
	postoPoupatempo: string;
	responsavel?: {
		cpf: string;
		dataNascimento: string;
		email: string;
		endereco: Address;
		nome: string;
		rg: string;
		ufRg: string;
		sexo: string;
		telefones: Phone[];
	};
	anexos: {
		nome: string;
		tipo: string;
		conteudo: string;
	}[];
}
