/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback } from 'react';

import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { clearNotifications } from 'store/modules/app/notifications/actions';
import {
	formatDateEnUs,
	limparMascara,
	validacaoData,
} from 'utils/genericFunctions';
import handleFailed from 'utils/getValidations';
import getValidationsErrors from 'utils/getValidationsErrors';
import * as Yup from 'yup';

// STYLED
import { Container, Title, Content, Box, SubTitle, Hint } from '../styled';

interface Props extends InputProps {
	title?: string;
	titleSize?: 'sm' | 'md' | 'lg' | 'xl' | number;
	titleQuestion?: boolean;
	titleAlign?: 'start' | 'end';
	subtitle?: string;
	hint?: string;
	name: string;
	autoFocus?: boolean;
	required?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	emptyValidate?: boolean;
	size?: number;
	error?: boolean;
	formik?: any;
	higherCurrent?: boolean;
	setBeforeLeftPosition?: number;
	customChanges?: (e: React.ChangeEvent<HTMLInputElement>) => void | null;
}

const ValidDataInput: React.FC<Props> = ({
	title,
	titleSize = 'md',
	titleQuestion = false,
	titleAlign = 'start',
	subtitle,
	hint,
	name,
	autoFocus = false,
	required = false,
	disabled = false,
	readOnly = false,
	emptyValidate = true,
	size = 100,
	error,
	onChange,
	onBlur,
	formik,
	higherCurrent = false,
	customChanges = null,
	setBeforeLeftPosition = -4,
	...rest
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [dataError, setDataError] = useState(false);
	const [invalidDate, setInvalidDate] = useState(false);
	const dispatch = useDispatch();

	const handleInputFocus = useCallback(() => {
		setIsFocused(true);
	}, []);

	const handleInputBlur = useCallback(
		(e: { target: { value: string } }) => {
			if (limparMascara(e.target.value).length !== 8) {
				formik.setFieldValue(name, '');
				if (dataError && emptyValidate) {
					handleFailed({
						mensagem_erro: `Campo Obrigatório: ${title}.`,
					});
				} else {
					dispatch(clearNotifications());
					setInvalidDate(false);
				}
			} else if (limparMascara(e.target.value).length === 8) {
				if (validacaoData(e.target.value) && !higherCurrent) {
					setDataError(false);
					setInvalidDate(false);
					dispatch(clearNotifications());
				} else {
					handleFailed({
						mensagem_erro: `${title}: '${e.target.value}' não é uma data válida.`,
					});
					formik.isValid = false;
					setInvalidDate(true);
					setDataError(true);
				}
				if (higherCurrent && validacaoData(e.target.value)) {
					const dataAtual = new Date();
					const formatDate = formatDateEnUs(e.target.value);

					if (formatDate <= dataAtual) {
						setDataError(false);
						setInvalidDate(false);
						dispatch(clearNotifications());
					} else {
						handleFailed({
							mensagem_erro: `${title}: '${e.target.value}' é uma data superior a data atual.`,
						});
						formik.isValid = false;
						setInvalidDate(true);
						setDataError(true);
					}
				}
			}

			setIsFocused(false);
		},
		[formik, name, dataError, emptyValidate, title, dispatch, higherCurrent],
	);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			formik.setFieldValue(name, e.currentTarget.value);
			if (customChanges) customChanges(e);
		},
		[formik, name, customChanges],
	);

	return (
		<Container>
			{title && (
				<Title
					align={titleAlign}
					size={titleSize}
					required={required}
					setBeforeLeftPosition={setBeforeLeftPosition}
				>
					<label htmlFor={name}>{`${title}${titleQuestion ? '?' : ':'}`}</label>
				</Title>
			)}
			<Content>
				<Box
					size={size}
					isErrored={!!error || (!!dataError && emptyValidate) || invalidDate}
					isFocused={isFocused}
					isDisabled={disabled}
					isReadOnly={readOnly}
				>
					<ReactInputMask
						name={name}
						id={name}
						onFocus={handleInputFocus}
						onBlur={e => handleInputBlur(e)}
						autoFocus={autoFocus}
						onChange={e => handleChange(e)}
						disabled={disabled}
						readOnly={readOnly}
						onKeyDown={e => {
							rest.onKeyDown
								? rest.onKeyDown(e)
								: e.key === 'Enter' && e.preventDefault();
						}}
						{...rest}
					/>

					{subtitle && (
						<SubTitle>
							<span>{subtitle}</span>
						</SubTitle>
					)}
				</Box>

				{hint && <Hint>{hint}</Hint>}
			</Content>
		</Container>
	);
};

export default ValidDataInput;
