export enum Types {
	EMISSAO_EXIGENCIAS_REQUEST = '@aac/EMISSAO_EXIGENCIAS_REQUEST',
	EMISSAO_EXIGENCIAS_SUCCESS = '@aac/EMISSAO_EXIGENCIAS_SUCCESS',
	EMISSAO_EXIGENCIAS_FAILURE = '@aac/EMISSAO_EXIGENCIAS_FAILURE',
	EMISSAO_EXIGENCIAS_CLEAR = '@aac/EMISSAO_EXIGENCIAS_CLEAR',
}

export interface emissaoExigencias {
	status: number;
	data: EmissaoExigencia;
}

export interface EmissaoExigencia {
	pdfBase64: string;
	nomeSocial: string;
}

export interface RequestEmissaoExigencia {
	codigoRetorno: string;
	codigoValidacao: string;
	dataNascimento: string | null;
	dataNascimentoTs?: string;
	digitoRg: string;
	exigBaixaDesaparecido: string;
	exigBO: string;
	exigBODelegacia: string;
	exigBONumero: string;
	exigCertidaoCriminais: string;
	exigCertidaoCriminais01: string;
	exigCertidaoCriminais02: string;
	exigCertidaoDistribuidor: string;
	exigCertidaoDistribuidor01: string;
	exigCertidaoObjeto: string;
	exigCopiaApresentado: string;
	exigDelegacia01: string;
	exigDelegacia02: string;
	exigDelegacia03: string;
	exigDelegacia04: string;
	exigDelegacia05: string;
	exigDelegacia06: string;
	exigDelegacia07: string;
	exigDelegacia08: string;
	exigDelegacia09: string;
	exigDelegacia10: string;
	exigenciaEvadido: string;
	exigenciaIP01: string;
	exigenciaIP02: string;
	exigenciaIP03: string;
	exigenciaIP04: string;
	exigenciaIP05: string;
	exigenciaIP06: string;
	exigenciaIP07: string;
	exigenciaIP08: string;
	exigenciaIP09: string;
	exigenciaIP10: string;
	exigenciaPreso: string;
	exigenciaProcurado: string;
	exigInstauracao01: string;
	exigInstauracao02: string;
	exigInstauracao03: string;
	exigInstauracao04: string;
	exigInstauracao05: string;
	exigInstauracao06: string;
	exigInstauracao07: string;
	exigInstauracao08: string;
	exigInstauracao09: string;
	exigInstauracao10: string;
	exigObservacao: string;
	exigObservacao01: string;
	exigObservacao02: string;
	exigObservacao03: string;
	exigObservacao04: string;
	exigObservacao05: string;
	exigObservacao06: string;
	exigObservacao07: string;
	exigObservacao08: string;
	exigObservacao09: string;
	exigObservacao10: string;
	exigProcesso01: string;
	exigProcesso02: string;
	exigProcesso03: string;
	exigProcesso04: string;
	exigProcesso05: string;
	exigProcesso06: string;
	exigProcesso07: string;
	exigProcesso08: string;
	exigProcesso09: string;
	exigProcesso10: string;
	exigVara01: string;
	exigVara02: string;
	exigVara03: string;
	exigVara04: string;
	exigVara05: string;
	exigVara06: string;
	exigVara07: string;
	exigVara08: string;
	exigVara09: string;
	exigVara10: string;
	id: string;
	nome: string;
	nomeMae: string;
	nomePai: string;
	nomeSocial: string;
	numeroDocumento: string;
	numeroRg: string | number;
	origem: string;
	resultado: string;
	retorno: string;
	sexo: string;
	status: string;
	tipoAA: string;
	tipoDoc: string;
	tipoDocApresentado: string;
	tipoLiberacao: string;
	usuarioCentral: string;
	usuarioPosto: string;
	docProcurador: string;
	nomeProcurador: string;
	cpf: string;
	dcCpf: string;
}
