import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from '../../types';
import { Types, AacState } from './types';

import painelDeControle from './painelDeControle/reducer';

import pesquisaNominal from './pesquisaNominal/reducer';
import consultaAtestadoNominal from './consultaAtestadoNominal/reducer';
import emissaoAtestadoAntecedentes from './emissaoAtestadoAntecedentes/reducer';
import emissaoNominal from './emissaoNominal/reducer';
import emissaoNumeral from './emissaoNumeral/reducer';
import pesquisaCitaAAC from './pesquisaCITA/reducer';

// NOVO PAINEL DE CONTROLE
import emissaoAtestadoPainel from './novoPainelDeControle/emissaoAtestadoNormal/reducer';
import emissaoExigencias from './novoPainelDeControle/emissaoExigencias/reducer';
import usuarioPendente from './novoPainelDeControle/consultaUsuarioPendente/reducer';
import encerrarAtendimento from './novoPainelDeControle/encerrarAtendimento/reducer';
import emissaoAtestadoLiberacaoPositiva from './novoPainelDeControle/emissaoAtestadoLiberacaoPositiva/reducer';

import enviarCentralNominal from './enviarCentralNominal/reducer';
import enviarCentralNumeral from './enviarCentralNumeral/reducer';

import consultaCpf from './consutaCpf/reducer';

const allReducers = combineReducers({
	consultaCpf,
	pesquisaNominal,
	consultaAtestadoNominal,
	emissaoAtestadoAntecedentes,
	emissaoNominal,
	emissaoNumeral,
	emissaoAtestadoPainel,
	emissaoExigencias,
	pesquisaCitaAAC,
	painelDeControle,
	usuarioPendente,
	enviarCentralNominal,
	enviarCentralNumeral,
	encerrarAtendimento,
	emissaoAtestadoLiberacaoPositiva,
});

const combineReducer = (state: AacState, action: ReducerAction) => {
	if (action.type === Types.AAC_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
