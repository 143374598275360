export enum Types {
	EMISSAO_ATESTADO_LIBERACAO_POSITIVA_REQUEST = '@aac/EMISSAO_ATESTADO_LIBERACAO_POSITIVA_REQUEST',
	EMISSAO_ATESTADO_LIBERACAO_POSITIVA_SUCCESS = '@aac/EMISSAO_ATESTADO_LIBERACAO_POSITIVA_SUCCESS',
	EMISSAO_ATESTADO_LIBERACAO_POSITIVA_FAILURE = '@aac/EMISSAO_ATESTADO_LIBERACAO_POSITIVA_FAILURE',
	EMISSAO_ATESTADO_LIBERACAO_POSITIVA_CLEAR = '@aac/EMISSAO_ATESTADO_LIBERACAO_POSITIVA_CLEAR',
}

export interface EmissaoAtestadoLiberacaoPositiva {
	status: number;
	data: ResponseEmissaoAtestadoLiberacaoPositiva;
}

export interface ResponseEmissaoAtestadoLiberacaoPositiva {
	pdfBase64: string;
	nomeSocial: string;
}

export interface RequestEmissaoAtestadoLiberacaoPositiva {
	nome: string;
	nomeSocial: string;
	nomeMae: string;
	nomePai: string;
	numeroDocumento: string;
	numeroRg: string | number;
	digitoRg: string;
	dataNascimento: string | null;
	dataNascimentoTs?: string | null;
	sexo: string;
	resultado: string;
	codigoValidacao: string;
	codigoRetorno: string;
	tipoLiberacao: string;
	positivaExecucao: string;
	positivaInicio: string;
	positivaTermino: string;
	positivaObservacao01: string;
	positivaObservacao02: string;
	positivaRegime: string;
	origem: string;
	docProcurador: string;
	nomeProcurador: string;
	tipoDocApresentado: string;
	naoPagamentoMultaMes?: string;
	naoPagamentoMultaAno?: string;
	naoPgtoNumeroCertidao?: string;
	naoPgtoDataCertidao?: string;
	cpf: string;
	dcCpf: string;
}
