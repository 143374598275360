export enum Types {
	RECUPERAR_ATENDIMENTO_REQUEST = '@atendimento/RECUPERAR_ATENDIMENTO_INTEGRADOR_REQUEST',
	RECUPERAR_ATENDIMENTO_SUCCESS = '@atendimento/RECUPERAR_ATENDIMENTO_INTEGRADOR_SUCCESS',
	RECUPERAR_ATENDIMENTO_FAILURE = '@atendimento/RECUPERAR_ATENDIMENTO_INTEGRADOR_FAILURE',
	RECUPERAR_ATENDIMENTO_CLEAR = '@atendimento/RECUPERAR_ATENDIMENTO_INTEGRADOR_CLEAR',
}

export interface RecuperarAtendimento {
	status: number;
	data: RecuperarAtendimentoData | null;
	messageError: string[] | null;
}

export interface RecuperarAtendimentoData {
	cpf: string;
	cpfCidadao: string;
	msg: string;
	estadoUsuario: string;
	idPonto: number;
	nomePonto: string;
	estadoPonto: string;
	idAtendimento: string;
	idCidadao: string;
}

export interface RecuperarAtendimentoRequest {
	cpf: string;
}
