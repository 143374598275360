import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useHistory } from 'react-router-dom';

// REDUCER
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { salvarAtendimentoRequest } from 'store/modules/api/sgu/atendimento/salvar/actions';
import { listaMotivoJustificativaRequest } from 'store/modules/api/sgu/atendimento/listaMotivoJustificativa/actions';

// COMPONENTES
import ButtonVoltar from 'components/Common/ButtonVoltar';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import { Field, Form, Formik } from 'formik';
import TextArea from 'components/Common/Form/Input/TextArea';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import getValidationsErrors from 'utils/getValidationsErrors';

import { DataArrayProps } from '../RecuperaAtendimento/form';
import {
	initialValues,
	schema,
	JustificativaAtendimentoProps,
	treatValues,
} from './form';

const JustificativaAtendimento: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [recoveryData, setRecoveryData] = useState<DataArrayProps | null>(null);

	const { descricaoServico, redirecionamento } = useSelector(
		(state: ApplicationState) => state.atendimento,
	);

	const atendimento = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.recuperarAtendimentoIntegrador.data,
	);

	const { data } = useSelector(
		(state: ApplicationState) =>
			state.api.sgu.atendimento.listaMotivoJustificativa,
	);

	const { user } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.login,
	);

	const { orgaos } = useSelector(
		(state: ApplicationState) => state.api.sgu.loginUnico.user,
	);

	useEffect(() => {
		dispatch(listaMotivoJustificativaRequest());
	}, [dispatch]);

	useEffect(() => {
		const orgao = {
			id: user.idOrgao.toString(),
			nome: orgaos.find((org: any) => org.id === user.idOrgao).nome,
		};
		// CPF, LOCALIDADE, DESCRICAO, ORGAO
		setRecoveryData({
			cpfUsuario: user.cpf,
			codLocalidade: Number(user.posto),
			descricaoServico,
			orgao,
			atendimento,
		});
	}, [atendimento, descricaoServico, orgaos, user]);

	const handleSubmit = useCallback(
		(values: JustificativaAtendimentoProps) => {
			const teatedValues = treatValues(recoveryData, values, user.cpf);
			dispatch(salvarAtendimentoRequest(teatedValues));

			if (redirecionamento) {
				history.push(redirecionamento);
			}
		},
		[dispatch, history, recoveryData, redirecionamento, user.cpf],
	);

	return (
		<Row>
			<Col span={24}>
				<Section title="Prosseguir atendimento">
					<Formik
						validateOnChange={false}
						validateOnBlur={false}
						initialValues={initialValues}
						validate={values => getValidationsErrors(values, schema)}
						onSubmit={handleSubmit}
					>
						{formik => (
							<Form autoComplete="off">
								<Row gutter={[0, 10]} align="middle" justify="center">
									<Col span={16}>
										<Field
											as={Select}
											name="motivoJustificativa"
											title="Selecione o motivo"
											titleSize="xl"
											titleAlign="start"
											options={data}
											onChange={(v: string) => {
												formik.setFieldValue('motivoJustificativa', v);
											}}
											error={!!formik.errors.motivoJustificativa}
										/>
									</Col>
									<Col span={16}>
										<Field
											as={TextArea}
											disabled={formik.values.motivoJustificativa !== 'OUTROS'}
											name="justificativa"
											title="Justifique"
											titleSize="xl"
											titleAlign="start"
											maxLength={338}
											error={!!formik.errors.justificativa}
										/>
									</Col>
									<Col span={16}>
										<Row gutter={[0, 10]}>
											<Col>
												<ButtonImage type="submit" src="seguir" />
											</Col>
										</Row>
									</Col>
								</Row>
							</Form>
						)}
					</Formik>
				</Section>

				<ButtonVoltar />
			</Col>
		</Row>
	);
};

export default JustificativaAtendimento;
