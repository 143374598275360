import { takeLatest, put, all } from 'redux-saga/effects';
import { setLoader } from 'store/modules/app/loader/actions';

// SERVICES
import { postApiCDHUToken } from 'services/apiCDHU';

// TYPES
import { Types } from './types';

// ACTIONS
import {
	obterTokenCdhuSuccess,
	obterTokenCdhuFailure,
} from './actions';
import { ApiResponse } from 'services/_api';

function* obterTokenCdhuRequest() {

	const response: ApiResponse[] = yield all([
		postApiCDHUToken(
			`https://servicos-hml.cdhu.sp.gov.br/api/Autentications/CriarTokenIdentity`
		),
	]);
	setLoader(false);

	if (response[0].status === 200) {
		yield put(obterTokenCdhuSuccess(response[0].data));
	} else {
		yield put(obterTokenCdhuFailure());
	}
}

export default all([
	takeLatest(Types.OBTER_TOKEN_CDHU_REQUEST, obterTokenCdhuRequest),
]);
