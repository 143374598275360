import { ReducerActionRotaSP } from 'store/modules/types';
import { ListarSolicitacaoPortalRequest, Types } from './types';

export function listarSolicitacaoPortalRequest(
	token: string,
	payload: ListarSolicitacaoPortalRequest,
): ReducerActionRotaSP {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_REQUEST,
		token,
		payload,
	};
}
export function listarSolicitacaoPortalSuccess(payload: object) {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_SUCCESS,
		payload,
	};
}
export function listarSolicitacaoPortalFailure(payload: object): any {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_FAILURE,
		payload,
	};
}

export function listarSolicitacaoPortalClear(): any {
	return {
		type: Types.LISTAR_SOLICITACAO_PORTAL_CLEAR,
	};
}
