export enum Types {
	CONSULTAR_INSCRICAO_REQUEST = '@EDUCACAO/CONSULTAR_INSCRICAO_REQUEST',
	CONSULTAR_INSCRICAO_SUCCESS = '@EDUCACAO/CONSULTAR_INSCRICAO_SUCCESS',
	CONSULTAR_INSCRICAO_FAILURE = '@EDUCACAO/CONSULTAR_INSCRICAO_FAILURE',
	CONSULTAR_INSCRICAO_NO_CONTENT = '@EDUCACAO/CONSULTAR_INSCRICAO_NO_CONTENT',
	SELECIONAR_INSCRICAO = '@EDUCACAO/SELECIONAR_INSCRICAO',
	CONSULTAR_INSCRICAO_CLEAR = '@EDUCACAO/CONSULTAR_INSCRICAO_CLEAR',
}

export interface ConsultarInscricao {
	status: number;
	data: ConsultarInscricaoData | null;
	request: ConsultaInscricaoRequest | null;
}

interface ConsultarInscricaoData {
	processoId?: number;
	inscricoes?: Inscricao[];
	inscricaoSelecionada?: Inscricao | null;
}

export interface ConsultaInscricaoRequest {
	numeroRA: string;
	digitoRA: string;
	siglaUFRA: string;
	anoLetivo: string;
}

interface Inscricao {
	codigoEscola: string;
	nomeEscola: string;
	codigoTipoEnsino: string;
	codigoSerieAno: string;
	tipo: string;
	descricaoTipo: string;
	data: string;
	codigoEscolaAlocacao: string;
	nomeEscolaAlocacao: string;
	numeroClasseAlocacao: string;
	dataAlocacao: string;
	redeEnsino: string;
	redeEnsinoEscolaAlocacao: string;
	municipioEscola: string;
	municipioEscolaAlocacao: string;
	situacao: string;
}
