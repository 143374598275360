import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES

// TYPES
import { ReducerAction } from 'store/modules/types';
import { getApi, ApiResponse } from 'services/rotasp/api';
import { PATH_EDUCACAO_SEDUC_FICHAS_API } from 'services/rotasp/path';
import { Types, RequestConsultaFichaAluno } from './types';

// ACTIONS
import {
	consultaFichaAlunoSuccess,
	consultaFichaAlunoFailure,
} from './actions';

function* consultaFichaAluno(request: ReducerAction) {
	const {
		token,
		payload,
	}: { token?: string; payload: RequestConsultaFichaAluno } = request;
	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_EDUCACAO_SEDUC_FICHAS_API,
		`fichas`,
		payload,
	);

	if (response.status === 200 || response.status === 204) {
		yield put(consultaFichaAlunoSuccess({ response, request: payload }));
	} else if (response.error) {
		yield put(consultaFichaAlunoFailure());
	}
}

export default all([
	takeLatest(Types.CONSULTA_FICHA_ALUNO_REQUEST, consultaFichaAluno),
]);
