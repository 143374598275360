import * as Yup from 'yup';
import { onlyLetters, minCharacters } from 'utils/yupValidations';
import { verifyDateIsLowerCurrentDate } from 'utils/dateFunctions';

export interface IFormConsultarAluno {
	filtrosNomes: {
		nomeAluno: string;
		nomeMae: string;
		nomePai: string;
	};
	dataNascimento: string;
}

export const initialValues: IFormConsultarAluno = {
	filtrosNomes: {
		nomeAluno: '',
		nomeMae: '',
		nomePai: '',
	},
	dataNascimento: '',
};

export interface IConsultarFichaAluno {
	aluno: {
		digitoRA: string;
		numeroRA: string;
		siglaUFRA: string;
	};
}

export const initialValuesConsultarFicha: IConsultarFichaAluno = {
	aluno: {
		digitoRA: '',
		numeroRA: '',
		siglaUFRA: '',
	},
};

export const schema = Yup.object<IFormConsultarAluno>().shape({
	filtrosNomes: Yup.object().shape({
		nomeAluno: Yup.string()
			.required(`Necessário preencher o nome completo.`)
			.max(100, 'Nome aluno deve conter no máximo 100 caracteres')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo nome aluno: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			})
			.test({
				name: 'minCharacters',
				exclusive: true,
				message: 'Nome aluno deve conter no mínimo 8 caracteres.',
				test: value => minCharacters(8, value),
			}),
		nomeMae: Yup.string()
			.when(['nomePai'], {
				is: nomePai => nomePai === '',
				then: Yup.string().required(
					`Necessário preencher o nome da mãe ou o nome do pai.`,
				),
			})
			.max(100, 'Nome mãe deve conter no máximo 100 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo nome mãe: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			})
			.test({
				name: 'minCharacters',
				exclusive: true,
				message: 'Nome mãe deve conter no mínimo 8 caracteres.',
				test: value => minCharacters(8, value),
			}),
		nomePai: Yup.string()
			.max(100, 'Nome pai deve conter no máximo 100 caracteres.')
			.test({
				name: 'onlyLetters',
				exclusive: true,
				message: 'Campo nome pai: Apenas letras são permitidas.',
				test: value => onlyLetters(value),
			})
			.test({
				name: 'minCharacters',
				exclusive: true,
				message: 'Nome pai deve conter no mínimo 8 caracteres.',
				test: value => minCharacters(8, value),
			}),
	}),
	dataNascimento: Yup.string()
		.required(`Necessário preencher a data de nascimento.`)
		.max(10)
		.test({
			name: 'verifyDateIsLowerCurrentDate',
			exclusive: true,
			message: 'Campo Data de Nascimento: Data inválida.',
			test: value => (value ? verifyDateIsLowerCurrentDate(value) : true),
		}),
});
