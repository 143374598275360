import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaRestricaoVeiculo } from './types';

export function consultarRestricaoVeiculoRequest(
	data: IRequestConsultaRestricaoVeiculo,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RESTRICAO_VEICULO,
		payload: data,
	};
}

export function consultarRestricaoVeiculoSuccess(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RESTRICAO_VEICULO_SUCCESS,
		payload,
	};
}

export function consultarRestricaoVeiculoFailure(
	payload: object,
): ReducerAction {
	return {
		type: Types.CONSULTAR_RESTRICAO_VEICULO_FAILURE,
		payload,
	};
}

export function consultarRestricaoVeiculoClear(): ReducerAction {
	return {
		type: Types.CONSULTAR_RESTRICAO_VEICULO_CLEAR,
		payload: null,
	};
}
