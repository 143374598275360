import { combineReducers } from 'redux';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, AtendimentoState } from './types';

import recuperarAtendimento from './recuperar/reducer';
import salvarAtendimento from './salvar/reducer';
import listaMotivoJustificativa from './listaMotivoJustificativa/reducer';
import dadosAgendamento from './dadosAgendamento/reducer';
import recuperarAtendimentoIntegrador from './recuperarAtendimentoIntegrador/reducer';

const allReducers = combineReducers({
	recuperarAtendimento,
	recuperarAtendimentoIntegrador,
	salvarAtendimento,
	listaMotivoJustificativa,
	dadosAgendamento,
});

const combineReducer = (state: AtendimentoState, action: ReducerAction) => {
	if (action.type === Types.ATENDIMENTO_CLEAR) {
		return allReducers(undefined, action);
	}

	return allReducers(state, action);
};

export default combineReducer;
