import React, { useCallback, useEffect, useState } from 'react';
import { Field, FormikProps, Form } from 'formik';
import { Col, Row, Modal } from 'antd';
// import { v4 as uuidV4 } from 'uuid';

// COMPONENTS
// import Alert from 'components/Common/Notifications/Alert';
import Button from 'components/Common/Button';
import Input from 'components/Common/Form/Input/Input';
import InputMask from 'components/Common/Form/Input/InputMask';
import Section from 'components/Common/Section';
import Select from 'components/Common/Form/Select';
import Error from 'components/Common/Notifications/FormError/index';

// UTILS
import hasError from 'utils/getFormErrors';

// STORE
import {
	clearNotificationsValidate,
	showInside,
} from 'store/modules/app/notifications/actions';
import dataEnum from 'store/modules/api/educacaoUpdate/enum';

// FORM
import { ApplicationState } from 'store';
import { useSelector } from 'react-redux';
import { ITelefone } from './form';
import { IFichaAluno } from '../../../../form';

interface IProps {
	formik: FormikProps<IFichaAluno>;
	modalTelefone: boolean;
	setModalTelefone: (v: boolean) => void;
	setTelefones: (v: Array<any>) => void;
	telefoneSelecionado: ITelefone;
	setTelefoneSelecionado: (v: object) => void;
}

const ModalTelefone: React.FC<IProps> = ({
	formik,
	modalTelefone = false,
	setModalTelefone = () => {},
	setTelefones = () => {},
	telefoneSelecionado = {},
	setTelefoneSelecionado = () => {},
}) => {
	const { notifications } = useSelector((state: ApplicationState) => state.app);

	const [tipoCelular, setTipoCelular] = useState<boolean>(false);
	// const [telefoneInvalid, setTelefoneInvalid] = useState<boolean>(false);
	const [maskDigitoTelefone, setMaskDigitoTelefone] = useState('');

	useEffect(() => {
		if (telefoneSelecionado) {
			formik.setFieldValue('telefone', telefoneSelecionado);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [telefoneSelecionado]);

	const resetTelefone = useCallback(() => {
		// formik.setFieldValue('telefone.id', '');
		formik.setFieldValue('telefone.tipo', 0);
		formik.setFieldValue('telefone.descricaoTipo', '');
		formik.setFieldValue('telefone.sms', '');
		formik.setFieldValue('telefone.ddd', '');
		formik.setFieldValue('telefone.numero', '');
		formik.setFieldValue('telefone.complemento', '');
	}, [formik]);

	useEffect(() => {
		if (tipoCelular) {
			// setDigitoTelefone(9);
			setMaskDigitoTelefone('99999-9999');
		} else {
			// setDigitoTelefone(8);
			setMaskDigitoTelefone('9999-9999');
		}
	}, [formik.values, tipoCelular]);

	const handleChangeTipo = (v: number) => {
		const [result] = dataEnum.tipo.filter(nome => {
			if (nome.value === v) {
				return nome;
			}
			return null;
		});
		formik.setFieldValue('telefone.descricaoTipo', result?.label);
		formik.setFieldValue('telefone.tipo', result?.value);

		if (v !== 3) formik.setFieldValue('telefone.sms', '');
	};

	const handleChangeSMS = (v: any) => {
		const [result] = dataEnum.sms.filter(nome => {
			if (nome.value === v) {
				return nome?.label;
			}
			return null;
		});
		formik.setFieldValue('telefone.sms', result?.label);
	};

	// const handleCleanNotification = useCallback(() => {}, []);

	useEffect(() => {
		if (modalTelefone) {
			showInside(true);
		} else {
			showInside(false);
		}
	}, [notifications.errors, modalTelefone]);

	return (
		<Modal
			title=""
			visible={modalTelefone}
			onCancel={() => {
				resetTelefone();
				setTelefoneSelecionado({});
				clearNotificationsValidate();
				setModalTelefone(false);
			}}
			width={880}
			footer={null}
			className="modal-ficha-aluno-telefones"
		>
			{notifications.errors &&
				notifications.errors.length > 0 &&
				notifications.showInside && <Error errors={notifications.errors} />}

			<Section title="Adicionar Telefones">
				<Form autoComplete="off">
					{/* {telefoneInvalid && (
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<Alert
									type="warning"
									message="Esse número de telefone já foi inserido"
									onClose={handleCleanNotification}
								/>
							</Col>
						</Row>
					)} */}
					<Row gutter={[0, 10]}>
						<Col span={12}>
							<Field
								as={Select}
								title="Tipo Telefone"
								titleSize="md"
								name="telefone.tipo"
								options={dataEnum.tipo}
								onChange={(v: number) => {
									handleChangeTipo(v);
									v === 3 ? setTipoCelular(true) : setTipoCelular(false);
								}}
								error={hasError(formik.errors, 'telefone.tipo')}
								required
							/>
						</Col>
						<Col span={12}>
							<Field
								as={Select}
								title="Aceita SMS"
								titleSize="md"
								name="telefone.sms"
								disabled={formik.values.telefone.tipo !== 3}
								options={dataEnum.sms}
								onChange={(v: string) => handleChangeSMS(v)}
								error={hasError(formik.errors, 'telefone.sms')}
							/>
						</Col>
					</Row>
					<Row gutter={[0, 10]}>
						<Col span={5}>
							<Field
								as={InputMask}
								titleSize="md"
								title="DDD"
								mask="99"
								name="telefone.ddd"
								error={hasError(formik.errors, 'telefone.ddd')}
								required
							/>
						</Col>

						<Col span={10}>
							<Field
								as={InputMask}
								title="Número"
								name="telefone.numero"
								mask={maskDigitoTelefone}
								error={hasError(formik.errors, 'telefone.numero')}
								required
							/>
						</Col>

						<Col span={9}>
							<Field
								as={Input}
								title="Complemento"
								name="telefone.complemento"
								error={hasError(formik.errors, 'telefone.complemento')}
								maxLength={10}
							/>
						</Col>
					</Row>
					<Button type="submit">
						{telefoneSelecionado?.numero ? 'Salvar' : '+ Adicionar'}
					</Button>
				</Form>
			</Section>
		</Modal>
	);
};

export default ModalTelefone;
