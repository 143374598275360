import { takeLatest, call, put, all } from 'redux-saga/effects';
import { ApiResponse, getApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { Types } from './types';
import {
	listarSolicitacaoPortalSuccess,
	listarSolicitacaoPortalFailure,
} from './actions';

function* listarSolicitacaoPortalRequest({ token, payload }: any) {
	const statusAllowed = [200, 204];

	const response: ApiResponse = yield call(
		getApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		'portal/solicitacoes',
		payload,
		undefined,
		undefined,
		undefined,
		undefined,
		statusAllowed,
	);
	if (response.status === 200) {
		yield put(listarSolicitacaoPortalSuccess(response));
	} else {
		yield put(listarSolicitacaoPortalFailure(response));
	}
}

export default all([
	takeLatest(
		Types.LISTAR_SOLICITACAO_PORTAL_REQUEST,
		listarSolicitacaoPortalRequest,
	),
]);
