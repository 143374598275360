import { takeLatest, call, put, all } from 'redux-saga/effects';

import { ApiResponse, postApi } from 'services/rotasp/api';
import { PATH_MP_PATERNIDADE_API } from 'services/rotasp/path';
import { ReducerActionRotaSP } from 'store/modules/types';
import { IRequestEnviarSolicitacaoMP, Types } from './types';
import {
	enviarSolicitacaoMPSuccess,
	enviarSolicitacaoMPFailure,
} from './actions';

function* enviarSolicitacaoMPRequest(request: ReducerActionRotaSP) {
	const {
		token,
		payload,
	}: { token?: string; payload: IRequestEnviarSolicitacaoMP } = request;

	const response: ApiResponse = yield call(
		postApi,
		token || '',
		PATH_MP_PATERNIDADE_API,
		`v2/solicitacoes`,
		payload,
	);
	if (response.status === 200 || response.status === 201) {
		yield put(enviarSolicitacaoMPSuccess(response));
	} else if (response.error) {
		yield put(enviarSolicitacaoMPFailure(response.message));
	}
}

export default all([
	takeLatest(Types.ENVIAR_SOLICITACAO_MP_REQUEST, enviarSolicitacaoMPRequest),
]);
