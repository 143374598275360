export enum Types {
	OBTER_TOKEN_CDHU_REQUEST = '@cdhu/OBTER_TOKEN_CDHU_REQUEST',
	OBTER_TOKEN_CDHU_SUCCESS = '@cdhu/OBTER_TOKEN_CDHU_SUCCESS',
	OBTER_TOKEN_CDHU_FAILURE = '@cdhu/OBTER_TOKEN_CDHU_FAILURE',
	OBTER_TOKEN_CDHU_CLEAR = '@cdhu/OBTER_TOKEN_CDHU_CLEAR',
}

export interface ObterTokenCdhu {
	status: number;
	data: ObterTokenCdhuData | null;
}

export interface ObterTokenCdhuData {
	token: string;
	baseUrl: string;
}

export interface ObterTokenCdhuRequest {
	usuario: string;
	senha: string;
}