import { RecuperarAtendimento } from './recuperar/types';
import { ListaMotivoJustificativa } from './listaMotivoJustificativa/types';
import { SalvarAtendimento } from './salvar/types';
import { DadosAgendamentoState } from './dadosAgendamento/types';
import { RecuperarAtendimento as RecuperarAtendimentoIntegrador } from './recuperarAtendimentoIntegrador/types';

export enum Types {
	ATENDIMENTO_CLEAR = '@atendimento/ATENDIMENTO_CLEAR',
}

export interface AtendimentoState {
	recuperarAtendimento: RecuperarAtendimento;
	recuperarAtendimentoIntegrador: RecuperarAtendimentoIntegrador;
	listaMotivoJustificativa: ListaMotivoJustificativa;
	salvarAtendimento: SalvarAtendimento;
	dadosAgendamento: DadosAgendamentoState;
}
