import { all } from 'redux-saga/effects';

import recuperarSaga from './recuperar/saga';

import recuperarAtendimentoIntegrador from './recuperarAtendimentoIntegrador/saga';

import salvarSaga from './salvar/saga';
import listaMotivoJustificativaSaga from './listaMotivoJustificativa/saga';

export default all([
	recuperarAtendimentoIntegrador,
	listaMotivoJustificativaSaga,
	salvarSaga,
	recuperarSaga,
]);
