import * as Yup from 'yup';
import {
	ConsultarResponsaveisRequest,
	Responsavel,
	TelefoneResponsavel,
} from 'store/modules/api/educacaoUpdate/responsavel/consultaResponsaveis/types';
import { Responsavel as ResponsavelCadastro } from 'store/modules/api/educacaoUpdate/responsavel/cadastroResponsavel/types';

export const initialValues: ConsultarResponsaveisRequest = {
	numeroCpf: '',
	numeroRg: '',
	numeroRA: '',
	digitoRA: '',
	siglaUFRa: '',
	siglaUfRg: '',
};

export const schema = Yup.object<ConsultarResponsaveisRequest>().shape({
	numeroRA: Yup.string().required('Necessário preencher o número do RA.'),
	digitoRA: Yup.string().required('Necessário preencher o dígito do RA.'),
	siglaUFRa: Yup.string().required('Necessário preencher a UF do RA.'),
});

const parseListaTelefone = (list: TelefoneResponsavel[]) => {
	if (list.length <= 0) return [];
	const parsedList = list.map((telefone: TelefoneResponsavel) => {
		return {
			dddNumero: telefone.dddNumero,
			numero: telefone.numero,
			tipoTelefone: telefone.tipoTelefone,
			complemento: telefone.complemento,
			// sms: telefone.numero,
		};
	});
	return parsedList;
};

export const formatSubmitValues = (
	values: ConsultarResponsaveisRequest,
): ConsultarResponsaveisRequest => {
	return {
		numeroRA: values.numeroRA.replace(/\D/g, ''),
		digitoRA: values.digitoRA,
		siglaUFRa: values.siglaUFRa,
	};
};

export const formatValuesUpdate = (
	values: Responsavel,
): ResponsavelCadastro => {
	const updatedValues = {
		cpf: values.cpf || '',
		nome: values.nome || '',
		tipoOrigem: values.tipoOrigem || '',
		nrRG: values.nrRG || '',
		digitoRG: values.digitoRG || '',
		ufRG: values.ufRG || '',
		codResponsabilidade: values.codResponsabilidade || '',
		codSexo: values.codSexo || '',
		codEstadoCivil: values.codEstadoCivil || '',
		emailResponsavel: values.emailResponsavel || '',
		dataNascimento: values.dataNascimento || '',
		cidadeNascimento: values.cidadeNascimento || '',
		ufNascimento: values.ufNascimento || '',
		nomePaisNascimento: values.nomePaisNascimento || '',
		codPaisNascimento: values.codPaisNascimento || '',
		rnm: values.rnm || '',
		enderecoResidencial: {
			logradouro: values.enderecoResidencial?.logradouro || '',
			numero: values.enderecoResidencial?.numero || '',
			areaLogradouro: values.enderecoResidencial?.areaLogradouro || '',
			complemento: values.enderecoResidencial?.complemento || '',
			bairro: values.enderecoResidencial?.bairro || '',
			cep: values.enderecoResidencial?.cep || '',
			nomeCidade: values.enderecoResidencial?.nomeCidade || '',
			ufCidade: values.enderecoResidencial?.ufCidade || '',
			// codigoMunicipioDNE: values.enderecoResidencial?.codigoMunicipioDNE || '',
			latitude: values.enderecoResidencial?.latitude || '',
			longitude: values.enderecoResidencial?.longitude || '',
			// codLocalizacaoDiferenciada: '',
			// values.enderecoResidencial?.?.toString() || '',
		},
		telefones: parseListaTelefone(values.telefones),
	};

	return updatedValues;
};
