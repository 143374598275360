// TYPES
import { ReducerActionRotaSP } from 'store/modules/types';
import { ApiResponse } from 'services/_api';
import { Types, ConsultarBCadastro, IRequestWithHeader } from './types';

export function consultarBCadastroRequest(
	token: string,
	payload: IRequestWithHeader,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_BCADASTRO_REQUEST,
		token,
		payload,
	};
}
export function consultarBCadastroSuccess(
	payload: ConsultarBCadastro,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_BCADASTRO_SUCCESS,
		payload,
	};
}
export function consultarBCadastroFailure(
	payload: ApiResponse,
): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_BCADASTRO_FAILURE,
		payload,
	};
}
export function consultarBCadastroClear(): ReducerActionRotaSP {
	return {
		type: Types.CONSULTAR_BCADASTRO_CLEAR,
		payload: null,
	};
}
