import React, { useCallback, useContext } from 'react';

import { Row, Col } from 'antd';
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// REDUX
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import {
	simularEfetuarAcordoRequest,
	// simularEfetuarAcordoClear,
} from 'store/modules/api/cdhu/simularEfetuarAcordo/actions';

// COMPONENTS
import FormBox from 'components/Common/Form/FormBox';
import ButtonImage from 'components/Common/ButtonImage';

// UTILS
import { formatCurrency, formatStringNumber } from 'utils/numberFunctions';
import { formatDate, unformatDate } from 'utils/genericFunctions';

const Resultado: React.FC = () => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const { consultarMutuario, gerarOcorrencia, simularEfetuarAcordo } =
		useSelector((state: ApplicationState) => state.api.cdhu);

	// useEffect(() => {
	// 	dispatch(simularEfetuarAcordoClear());
	// }, [dispatch]);

	const handleEfetivar = useCallback(() => {
		if (
			gerarOcorrencia.data &&
			consultarMutuario.data &&
			simularEfetuarAcordo.request &&
			simularEfetuarAcordo.data
		) {
			const { ocorrenciaId } = gerarOcorrencia.data;
			const { numeroContrato } = consultarMutuario.data;

			const { tipoSimulacao } = simularEfetuarAcordo.request;

			const {
				acordoId,
				dataPrestacaoInicial,
				qtdParcelasAcordo,
				valorParcelaInicial,
			} = simularEfetuarAcordo.data;

			const formattedDataPrestacaoInicial = unformatDate(dataPrestacaoInicial);

			const amortizacaoInicial = formatStringNumber(valorParcelaInicial);

			if (tipoSimulacao === 2) {
				dispatch(
					simularEfetuarAcordoRequest(token, {
						servicoEnum: 'EFETIVAR_ACORDO',
						acordoId,
						ocorrenciaId,
						contratoCDHU: Number(numeroContrato),
						novoPrazo: Number(qtdParcelasAcordo),
						tipoSimulacao,
					}),
				);
			} else if (tipoSimulacao === 3) {
				dispatch(
					simularEfetuarAcordoRequest(token, {
						servicoEnum: 'EFETIVAR_ACORDO',
						acordoId,
						ocorrenciaId,
						amortizacaoInicial,
						contratoCDHU: Number(numeroContrato),
						dataVencimentoBoleto: formattedDataPrestacaoInicial,
						novoPrazo: Number(qtdParcelasAcordo),
						tipoSimulacao,
					}),
				);
			} else if (tipoSimulacao === 4) {
				dispatch(
					simularEfetuarAcordoRequest(token, {
						servicoEnum: 'EFETIVAR_ACORDO',
						acordoId,
						ocorrenciaId,
						amortizacaoInicial,
						contratoCDHU: Number(numeroContrato),
						dataVencimentoBoleto: formattedDataPrestacaoInicial,
						tipoSimulacao,
					}),
				);
			}
		}
	}, [
		gerarOcorrencia.data,
		consultarMutuario.data,
		simularEfetuarAcordo.request,
		simularEfetuarAcordo.data,
		dispatch,
		token,
	]);

	return (
		<>
			<FormBox title="Simulação da proposta (Calculado com os dados da proposta)">
				<Row gutter={[0, 5]}>
					<Col span={16}>Quantidade de parcelas do acordo:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>{simularEfetuarAcordo.data.qtdParcelasAcordo}</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={16}>Valor da parcela de entrada do acordo:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>
								{formatCurrency(simularEfetuarAcordo.data.valorParcelaInicial)}
							</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={16}>Valor da parcela do acordo:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>
								{formatCurrency(simularEfetuarAcordo.data.valorParcelaAcordo)}
							</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={16}>Valor da prestação com a parcela do acordo:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>
								{formatCurrency(simularEfetuarAcordo.data.novaPrestacaoLiquida)}
							</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={16}>Data de vencimento da 1ª parcela:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>
								{formatDate(simularEfetuarAcordo.data.dataPrestacaoInicial)}
							</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 5]}>
					<Col span={16}>Data de vencimento da última parcela:</Col>

					<Col span={6} offset={2}>
						{simularEfetuarAcordo.data && (
							<b>{formatDate(simularEfetuarAcordo.data.dataPrestacaoFinal)}</b>
						)}
					</Col>
				</Row>

				<Row gutter={[0, 15]}>
					<Col span={24} />
				</Row>

				<Row justify="center">
					<Col>
						<ButtonImage
							src="efetivar"
							onClick={handleEfetivar}
							disabled={
								!simularEfetuarAcordo.data ||
								!!simularEfetuarAcordo.data?.mensagem
							}
						/>
					</Col>
				</Row>
			</FormBox>
		</>
	);
};

export default Resultado;
