export enum Types {
	CONSULTAR_RESTRICAO_VEICULO = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO',
	CONSULTAR_RESTRICAO_VEICULO_SUCCESS = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_SUCCESS',
	CONSULTAR_RESTRICAO_VEICULO_FAILURE = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_FAILURE',
	CONSULTAR_RESTRICAO_VEICULO_CLEAR = '@DETRAN-CRV/CONSULTAR_RESTRICAO_VEICULO_CLEAR',
}

export interface consultarRestricaoVeiculo {
	status: number;
	data: IDadosConsultaRestricaoVeiculo | null;
	dataError: ConsultarRestricaoVeiculoDataError | null;
}

export interface ConsultarRestricaoVeiculoDataError {
	transacao: string;
	codigo: number;
	mensagem: string;
}

export interface IDadosConsultaRestricaoVeiculo {
	placa: string;
	renavam: number;
	cpf_cnpj: number;
	uf_origem: string;
	municipio: number;
	cpf_cnpj_proprietario: number;
	restricao_financeira: string;
	cnpj_agente: number;
	nome_agente: string;
	cpf_cnpj_agente: number;
	nome_financiado: string;
	comunicacao_vendas: string;
	cpf_cnpj_novo_comprador: number;
	municipio_comprador: number;
	municipio_proprietario: number;
	cep_proprietario: number;
	bloqueio_renajud: string;
	placa_mercosul: string;
	ano_licenciamento: number;
}

export interface IRequestConsultaRestricaoVeiculo {
	placa: string;
	documento: string;
	espelho: string;
	renavam?: string;
	segunda_via_crv?: string;
	uf?: string;
}
