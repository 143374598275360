export enum Types {
	GERAR_BOLETO_AGRUPADO_REQUEST = '@cdhu/GERAR_BOLETO_AGRUPADO_REQUEST',
	GERAR_BOLETO_AGRUPADO_SUCCESS = '@cdhu/GERAR_BOLETO_AGRUPADO_SUCCESS',
	GERAR_BOLETO_AGRUPADO_FAILURE = '@cdhu/GERAR_BOLETO_AGRUPADO_FAILURE',
	GERAR_BOLETO_AGRUPADO_CLEAR = '@cdhu/GERAR_BOLETO_AGRUPADO_CLEAR',
}

export interface GerarBoletoAgrupado {
	status: number;
	data: GerarBoletoAgrupadoData | null;
	form: GerarBoletoAgrupadoRequest | null;
}

export interface GerarBoletoAgrupadoData {
	aceite: string;
	carteira: string;
	cedente: string;
	codigoBarras: string;
	codigoDigitavel: string;
	contaCedente: string; // aaaa.ttt.nnnnnnnn-d,
	dadosContrato: string;
	dadosContrato2: string;
	dadosContrato3: string;
	dadosContrato4: string;
	dadosContrato5: string;
	dadosContrato6: string;
	dadosContrato7: string;
	dadosContrato8: string;
	dadosPrestacao: string;
	dadosPrestacao2: string;
	dadosPrestacao3: string;
	dadosPrestacao4: string;
	dadosPrestacao5: string;
	dadosPrestacao6: string;
	dadosPrestacao7: string;
	dadosPrestacao8: string;
	dataDocumento: string; // yyyy-MM-dd,
	dataInicioPrestacaoAtrasada: string; // MMAAAA,
	dataLimiteBoleto: string; // yyyy-MM-dd,
	dataProcessamentoDocumento: string; // yyyy-MM-dd,
	dataRecebimento: string; // yyyy-MM-dd,
	dataUltimaPrestacaoBoleto: string; // yyyy-MM-dd,
	dataVencimento: string; // yyyy-MM-dd,
	dtVencimento: string; // 2024-03-27T12:01:16.005Z,
	efetivacaoProdesp: string;
	especieDocumento: string;
	especieMoeda: string;
	instrucoes: string;
	instrucoes2: string;
	instrucoes3: string;
	instrucoes4: string;
	instrucoes5: string;
	instrucoes6: string;
	instrucoes7: string;
	instrucoes8: string;
	localPagamento: string;
	mensagemSacado: string;
	mensagemSacado2: string;
	mensagemSacado3: string;
	mensagemSacado4: string;
	mensagemSacado5: string;
	nossoNumero: string;
	numeroDocumento: string;
	quantidadeMoeda: string;
	remetente: string;
	sacado: string;
	sacado2: string;
	sacado3: string;
	sacado4: string;
	sacado5: string;
	sacado6: string;
	sacado7: string;
	tipoBoleto: string;
	tipoBoletoGerado: string;
	tipoReajuste: string;
	urlBoletoGerado: string;
	usoBanco: string;
	valor: string; // XXXXX.XX,
	valorDocumento: string; // XXXXX.XX,
	valorParcial: string; // XXXXX.XX,
	valorRecebido: string; // XXXXX.XX,
	boletoId: string;
	ocorrenciaId: string;
	processarWs: string;
	statusField: 0;
	mensagemField: string;
}
// export interface IRequestGerarBoletoAgrupado {
// 	tipoEnum: string;
// 	ocorrenciaId: string;
// 	numeroContrato: number;
// 	dataInicio: string;
// 	dataFim: string;
// 	dataLimite: string;
// }

export interface GerarBoletoAgrupadoRequest {
	servicoEnum: string; // BOLETO_AGRUPADO
	numeroContrato: number;
	ocorrenciaId: string; // string
	dataInicio: string; // MMyyyy
	dataFim: string; // MMyyyy
	dataLimite: string; // yyyy-MM-dd
	selectedFormAllInstallments?: boolean;
	selectedFormParcialInstallments?: boolean;
}
