import { takeLatest, call, put, all } from 'redux-saga/effects';

// SERVICES
import { ApiResponse, postApi } from 'services/_api';
import { PATH_DETRAN_CRV, CHANNEL } from 'services/_path';

// TYPES
import { ReducerAction } from 'store/modules/types';
import { Types, IRequestConsultaRestricaoVeiculo } from './types';

// ACTIONS
import {
	consultarRestricaoVeiculoSuccess,
	consultarRestricaoVeiculoFailure,
} from './actions';

function* consultarRestricaoVeiculo(request: ReducerAction) {
	const { payload }: { payload: IRequestConsultaRestricaoVeiculo } = request;

	const response: ApiResponse = yield call(
		postApi,
		PATH_DETRAN_CRV,
		`transferencia/${CHANNEL}/consulta-restricao-veiculo`,
		payload,
	);

	if (response.status === 200) {
		yield put(consultarRestricaoVeiculoSuccess(response));
	} else {
		yield put(consultarRestricaoVeiculoFailure(response));
	}
}

export default all([
	takeLatest(Types.CONSULTAR_RESTRICAO_VEICULO, consultarRestricaoVeiculo),
]);
