import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FormikProps } from 'formik';
import { Col, Row } from 'antd';

// COMPONENTS
import Input from 'components/Common/Form/Input/Input';
import Select from 'components/Common/Form/Select';
import CheckBox from 'components/Common/Form/Checkbox';
import ValidDataInput from 'components/Common/Form/Input/ValidData';
import FormBox from 'components/Common/Form/FormBox';
import InputMask from 'components/Common/Form/Input/InputMask';

// ENUM
import dataEnum from 'store/modules/api/educacaoUpdate/enum';
import data from 'store/modules/enum';

// REDUX
import { ApplicationState } from 'store';
import { comboMunicipiosRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboMunicipios/actions';
import { comboPaisesRequest } from 'store/modules/api/educacaoUpdate/fichaAluno/comboPaises/actions';

// UTILS
import { onlyLetters } from 'utils/genericFunctions';
import hasError from 'utils/getFormErrors';

// CONTEXT
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';

// FORM
import { IFichaAluno, IDadosPessoais } from '../form';
import { ContainerFichaCadastral } from '../styled';

interface Props {
	formik: FormikProps<IFichaAluno>;
	renderData?: IDadosPessoais;
}

const DadosPessoais: React.FC<Props> = ({ formik }) => {
	const dispatch = useDispatch();
	const { token } = useContext<IAuthContext>(AuthContext);

	const [dataPaises, setDataPaises] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [dataMunicipios, setDataMunicipios] = useState<
		Array<{ value: string; label: string }>
	>([]);

	const { paises } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboPaises.data,
	);

	const { municipios } = useSelector(
		(state: ApplicationState) =>
			state.api.educacaoUpdate.fichaAluno.comboMunicipios.data,
	);

	useEffect(() => {
		const listaMunicipios: Array<{ value: string; label: string }> = [];
		municipios?.map(item => {
			return listaMunicipios.push({
				value: item.descricao,
				label: item.descricao,
			});
		});
		setDataMunicipios(listaMunicipios);
	}, [municipios]);

	useEffect(() => {
		const listaPaises: Array<{ value: string; label: string }> = [];
		paises?.map((item: { codigo: string; descricao: string }) => {
			return listaPaises.push({
				value: item.codigo,
				label: item.descricao,
			});
		});
		setDataPaises(listaPaises);
	}, [paises]);

	const handleChangeMunicipio = (v: string) => {
		const [result] = municipios.filter(
			(nome: { descricao: string; codigo: any }) => {
				if (nome.descricao === v) {
					return nome.codigo;
				}
				return null;
			},
		);

		formik.setFieldValue(
			'dadosPessoais.nomeMunicipioNascimento',
			result?.descricao,
		);
		formik.setFieldValue(
			'dadosPessoais.codigoMunicipioNascimentoDNE',
			result?.codigo,
		);
	};

	const handleChangePais = (v: any) => {
		const [result] = paises.filter((nome: { codigo: any; descricao: any }) => {
			if (nome.codigo === v) {
				return nome.descricao;
			}
			return null;
		});
		formik.setFieldValue(
			'dadosPessoais.nomePaisOrigem',
			result?.descricao || 0,
		);
		formik.setFieldValue('dadosPessoais.codigoPaisOrigem', result?.codigo || 0);
	};

	const handleChangeSexo = (v: any) => {
		const [result] = dataEnum.codigoSexo.filter(codigo => {
			if (codigo.value === v) {
				return codigo.label;
			}
			return null;
		});

		formik.setFieldValue('dadosPessoais.sexo', result?.label || '');
		formik.setFieldValue('dadosPessoais.codigoSexo', result?.value || '');
	};

	const handleChangeCorRaca = (v: any) => {
		const [result] = dataEnum.corRaca.filter(nome => {
			if (nome.value === v) {
				return nome.label;
			}
			return null;
		});
		formik.setFieldValue('dadosPessoais.corRaca', result?.value);
		formik.setFieldValue('dadosPessoais.descricaoCorRaca', result?.label);
	};

	const handleChangeNacionalidade = (v: any) => {
		const [result] = dataEnum.nacionalidade.filter(nome => {
			if (nome.value === v) {
				return nome.label;
			}
			return null;
		});
		formik.setFieldValue('dadosPessoais.nacionalidade', result?.value);
		formik.setFieldValue('dadosPessoais.descricaoNacionalidade', result?.label);
		formik.setFieldValue('dadosPessoais.dataEntradaPais', '');
	};

	useEffect(() => {
		if (paises.length < 1) {
			dispatch(comboPaisesRequest(token));
		}
	}, [dispatch, paises, token]);

	useEffect(() => {
		if (
			formik.values.dadosPessoais.ufMunicipioNascimento &&
			formik.values.dadosPessoais.ufMunicipioNascimento !== ''
		) {
			dispatch(
				comboMunicipiosRequest(token, {
					uf: formik.values.dadosPessoais.ufMunicipioNascimento,
				}),
			);
		}
	}, [dispatch, formik.values.dadosPessoais.ufMunicipioNascimento, token]);

	return (
		<ContainerFichaCadastral>
			<FormBox title="Dados Pessoais">
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome"
							name="dadosPessoais.nomeAluno"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'dadosPessoais.nomeAluno',
									onlyLetters(e.target.value),
								)
							}
							error={hasError(formik.errors, 'dadosPessoais.nomeAluno')}
							maxLength={100}
							required
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Social (DECR.55588/10)"
							name="dadosPessoais.nomeSocial"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'dadosPessoais.nomeSocial',
									onlyLetters(e.target.value),
								)
							}
							error={hasError(formik.errors, 'dadosPessoais.nomeSocial')}
							maxLength={100}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Afetivo (Lei Nº 16.785/18)"
							name="dadosPessoais.nomeAfetivo"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'dadosPessoais.nomeAfetivo',
									onlyLetters(e.target.value),
								)
							}
							error={hasError(formik.errors, 'dadosPessoais.nomeAfetivo')}
							maxLength={100}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={7}>
						<Field
							as={Select}
							title="Sexo"
							name="dadosPessoais.codigoSexo"
							options={dataEnum.codigoSexo}
							onChange={(v: string) => {
								handleChangeSexo(v);
							}}
							error={hasError(formik.errors, 'dadosPessoais.sexo')}
							required
						/>
					</Col>

					<Col span={8} offset={1}>
						<Field
							as={Select}
							title="Raça/Cor"
							name="dadosPessoais.corRaca"
							options={dataEnum.corRaca}
							onChange={(v: string) => {
								handleChangeCorRaca(v);
							}}
							error={hasError(formik.errors, 'dadosPessoais.corRaca')}
							required
						/>
					</Col>

					<Col span={7} offset={1}>
						<Field
							title="Data de Nascimento"
							name="dadosPessoais.dataNascimento"
							type="text"
							mask="99/99/9999"
							as={ValidDataInput}
							error={hasError(formik.errors, 'dadosPessoais.dataNascimento')}
							formik={formik}
							onChange={(v: string) =>
								formik.setFieldValue('dadosPessoais.dataNascimento', v)
							}
							required
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Email"
							name="dadosPessoais.email"
							error={hasError(formik.errors, 'dadosPessoais.email')}
							maxLength={100}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Mãe"
							name="dadosPessoais.nomeMae"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'dadosPessoais.nomeMae',
									onlyLetters(e.target.value),
								)
							}
							error={hasError(formik.errors, 'dadosPessoais.nomeMae')}
							maxLength={100}
							required
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Input}
							title="Nome Pai"
							name="dadosPessoais.nomePai"
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								formik.setFieldValue(
									'dadosPessoais.nomePai',
									onlyLetters(e.target.value),
								)
							}
							error={hasError(formik.errors, 'dadosPessoais.nomePai')}
							maxLength={100}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Participa do Programa Bolsa Família:"
							name="dadosPessoais.codigoBolsaFamilia"
							order="left"
							checked={formik.values.dadosPessoais.codigoBolsaFamilia}
							error={hasError(
								formik.errors,
								'dadosPessoais.codigoBolsaFamilia',
							)}
						/>
					</Col>
				</Row>
				{formik.values.dadosPessoais.numeroRA !== '' && (
					<Row gutter={[0, 10]}>
						<Col span={8}>
							<Field
								as={Input}
								title="RA"
								name=""
								style={{ fontWeight: 'bold' }}
								value={`${formik.values.dadosPessoais.numeroRA}-${formik.values.dadosPessoais.digitoRA}/${formik.values.dadosPessoais.siglaUFRA}`}
								readOnly
							/>
						</Col>
					</Row>
				)}
				<Row gutter={[0, 10]}>
					<Col span={7}>
						<Field
							as={InputMask}
							title="Código Inep"
							name="dadosPessoais.codigoINEP"
							mask="999999999999"
							error={hasError(formik.errors, 'dadosPessoais.codigoINEP')}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Select}
							title="Nacionalidade"
							name="dadosPessoais.nacionalidade"
							options={dataEnum.nacionalidade}
							onChange={(v: string) => handleChangeNacionalidade(v)}
							error={hasError(formik.errors, 'dadosPessoais.nacionalidade')}
							required
						/>
					</Col>
				</Row>

				<Row gutter={[0, 10]}>
					<Col span={7}>
						<Field
							as={Select}
							title="UF de Nascimento"
							name="dadosPessoais.ufMunicipioNascimento"
							options={data.uf}
							onChange={(v: string) => {
								formik.setFieldValue('dadosPessoais.ufMunicipioNascimento', v);
							}}
							error={hasError(
								formik.errors,
								'dadosPessoais.ufMunicipioNascimento',
							)}
							required
						/>
					</Col>
					<Col span={6} offset={3}>
						<Field
							as={Select}
							title="Município de Nascimento"
							name="dadosPessoais.nomeMunicipioNascimento"
							options={dataMunicipios}
							onChange={(v: string) => {
								handleChangeMunicipio(v);
							}}
							error={hasError(
								formik.errors,
								'dadosPessoais.nomeMunicipioNascimento',
							)}
							titleSize={78}
							required
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={Select}
							title="País de Origem"
							name="dadosPessoais.codigoPaisOrigem"
							options={dataPaises}
							onChange={(v: string) => {
								handleChangePais(v);
								formik.setFieldValue('dadosPessoais.codigoPaisOrigem', v);
							}}
							error={hasError(formik.errors, 'dadosPessoais.codigoPaisOrigem')}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Quilombola:"
							name="dadosPessoais.quilombola"
							checked={formik.values.dadosPessoais.quilombola}
							error={hasError(formik.errors, 'dadosPessoais.quilombola')}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Possui Internet:"
							name="dadosPessoais.possuiInternet"
							checked={formik.values.dadosPessoais.possuiInternet === 'SIM'}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'dadosPessoais.possuiInternet',
									e.target.checked ? 'SIM' : 'NAO',
								);
							}}
							error={hasError(formik.errors, 'dadosPessoais.possuiInternet')}
						/>
					</Col>
				</Row>
				<Row gutter={[0, 10]}>
					<Col span={24}>
						<Field
							as={CheckBox}
							subtitle="Possui Notebook, Smartphone, Tablet:"
							name="dadosPessoais.possuiNotebookSmartphoneTablet"
							checked={
								formik.values.dadosPessoais.possuiNotebookSmartphoneTablet ===
								'SIM'
							}
							onChange={(e: ChangeEvent<HTMLInputElement>) => {
								formik.setFieldValue(
									'dadosPessoais.possuiNotebookSmartphoneTablet',
									e.target.checked ? 'SIM' : 'NAO',
								);
							}}
							error={hasError(
								formik.errors,
								'dadosPessoais.possuiNotebookSmartphoneTablet',
							)}
						/>
					</Col>
				</Row>
			</FormBox>
		</ContainerFichaCadastral>
	);
};

export default DadosPessoais;
