import axios, { AxiosError } from 'axios';

import { setLoader } from 'store/modules/app/loader/actions';
import { clearNotificationsValidate } from 'store/modules/app/notifications/actions';
import handleFailed from 'utils/getValidations';

const { REACT_APP_USUARIO_TOKEN_CDHU, REACT_APP_SENHA_TOKEN_CDHU, REACT_APP_BASE_URL_SERVICO_EXTERNO_CDHU } = process.env;
interface IRequests {
	onSuccess: (token: string) => void;
	onFailure: (err: AxiosError) => void;
}

interface ApiResponse {
	status: number;
	data?: any;
	error?: boolean;
	message?: any;
}

function runSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];

	const incrementStorage = [...storageValueResult, lastCall];
	localStorage.setItem('lastCallApi', JSON.stringify(incrementStorage));
	clearNotificationsValidate();
	setLoader(true);
}

function stopSetLoader(method: string) {
	const methodURL = method.split('/') || [];
	const methodURLLength = methodURL ? methodURL.length - 1 : 0;
	const lastCall = methodURL.length ? methodURL[methodURLLength] : '';

	const storageValue = localStorage.getItem('lastCallApi');
	const storageValueResult: string[] = storageValue
		? JSON.parse(storageValue)
		: [];
	const arrayDiference =
		storageValueResult.filter(value => value !== lastCall) || [];
	if (arrayDiference.length) {
		localStorage.setItem('lastCallApi', JSON.stringify(arrayDiference));
	} else {
		localStorage.setItem('lastCallApi', JSON.stringify([]));
	}

	if (storageValueResult.length <= 1 && arrayDiference.length === 0) {
		localStorage.removeItem('lastCallApi');
		setLoader(false);
	}
}

export async function getApiCDHUFinanceiro(
	project: string,
	token: string,
): Promise<ApiResponse> {
	runSetLoader(project);
	try {
		const response = await axios.get(`${REACT_APP_BASE_URL_SERVICO_EXTERNO_CDHU}/${project}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
			
		});
		
		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(project);
		}, 1000);
	}
}

export async function postApiCDHUToken(
	project: string,
) {
	runSetLoader(project);
	try {
		const response = await axios.post(`${project}`, {
			usuario: REACT_APP_USUARIO_TOKEN_CDHU,
  			senha: REACT_APP_SENHA_TOKEN_CDHU
		});
		
		return response;
	} catch (error) {
		const errorResponse = await handleFailed(error);

		return { status: 0, error: true, message: errorResponse };
	} finally {
		setTimeout(() => {
			stopSetLoader(project);
		}, 1000);
	}
}

